import { createAction } from '@reduxjs/toolkit';

export const addLoadCarrier = createAction('loadOverview/addLoadCarrier');
export const updateLoadCarrier = createAction('loadOverview/updateLoadCarrier');
export const deleteLoadCarrier = createAction('loadOverview/deleteLoadCarrier');

export const addLoadCarrierReducer = (state, action) => {
  state.carriers.data.push(action.payload);
};

export const updateLoadCarrierReducer = (state, action) => {
  const foundExistingCarrierIdx = state.carriers.data?.findIndex(
    carrier => carrier.loadPortionId === action.payload.loadPortionId
  );

  state.carriers.data[foundExistingCarrierIdx] = action.payload;
};

export const deleteLoadCarrierReducer = (state, action) => {
  const filteredCarriers = state.carriers.data.filter(
    carrier => carrier.loadPortionId !== action.payload.loadPortionId
  );

  state.carriers.data = filteredCarriers;
};
