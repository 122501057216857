import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetch = async ({ accessToken, loadId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_LOAD_API}/v1/loads/${loadId}/carriers`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
};

export const shouldFetchData = (_args, { getState }) => {
  const { loadOverview } = getState();
  const carriersState = loadOverview.carriers;
  return !carriersState.isLoading && carriersState.data === null;
};

const fetchLoadCarriers = createAsyncThunk(
  'loadOverview/fetchLoadCarriers',
  fetch,
  {
    condition: shouldFetchData,
  }
);

export const extraReducers = {
  [fetchLoadCarriers.fulfilled]: (state, action) => {
    state.carriers.data = action?.payload;
    state.carriers.isLoading = false;
  },
  [fetchLoadCarriers.pending]: state => {
    state.carriers.isLoading = true;
  },
  [fetchLoadCarriers.rejected]: state => {
    state.carriers.fetchError = true;
    state.carriers.isLoading = false;
  },
};

export default fetchLoadCarriers;
