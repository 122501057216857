import React, { useState, useEffect, useCallback } from 'react';
import { notifications, carrierFileTypes } from '@forager/constants';
import { Button, Checkbox, IconButton } from '@material-ui/core/';
import { Table } from '@forager/bits';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  stringSortCompare,
  numberSortCompare,
  useAuth0,
  useForagerNotification,
  dateSortCompare,
} from '@forager/client-utils';
import moment from 'moment-timezone';
import {
  CloudDownload as CloudDownloadIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import FileUploadModal from './FileUploadModal';
import isMostRecentFile from './isMostRecentFile';
import { useScopes } from '../../../../utils/ValidateScopes';

const CarrierFiles = () => {
  const { accessToken } = useAuth0();
  const { error: errorNotification } = useForagerNotification();
  const { ERROR } = notifications;
  const [isFileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [isFilesLoading, setIsFilesLoading] = useState(false);
  const [filesError, setFilesError] = useState(null);
  const [files, setFiles] = useState([]);
  const { id: carrierCompanyId } = useParams();
  const { hasScopes: editable } = useScopes('carrier:update');
  const { GENERIC_ERROR } = notifications;

  const handleUploadFilesClick = () => {
    setFileUploadModalOpen(true);
  };

  const fetchCarrierFiles = useCallback(async () => {
    setIsFilesLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/carriers/${carrierCompanyId}/files`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setFiles(data);
      setIsFilesLoading(false);
    } catch (error) {
      setFilesError(true);
      setIsFilesLoading(false);
    }
  }, [carrierCompanyId, accessToken]);

  useEffect(() => {
    fetchCarrierFiles();
  }, [fetchCarrierFiles]);

  const handleDownloadCarrierFile = async fileId => {
    try {
      const {
        data: { signedUrl },
      } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/carriers/${carrierCompanyId}/files/${fileId}/downloadUrl`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const a = document.createElement('a');
      a.href = signedUrl;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      errorNotification(ERROR.EN);
    }
  };

  const handleDeleteFile = async fileId => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/carriers/${carrierCompanyId}/files/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchCarrierFiles();
    } catch (error) {
      errorNotification(ERROR.EN);
    }
  };

  const columns = [
    {
      title: 'Description',
      field: 'fileDescription',
    },
    {
      title: 'File Type',
      render: ({ carrierFileTypeId }) => {
        const fileTypeObj = carrierFileTypes.find(
          type => type.ID === carrierFileTypeId
        );
        return fileTypeObj.FILE_TYPE;
      },
      customFilterAndSearch: (term, { carrierFileTypeId }) => {
        const fileTypeObj = carrierFileTypes.find(
          type => type.ID === carrierFileTypeId
        );
        return fileTypeObj.FILE_TYPE.toLowerCase().includes(term.toLowerCase());
      },
      customSort: (a, b) => {
        return numberSortCompare(a.carrierFileTypeId, b.carrierFileTypeId);
      },
    },
    {
      title: 'Expire Date',
      field: 'expireDate',
      type: 'date',
      render: ({ expirationDate }) =>
        expirationDate ? moment(expirationDate).format('L') : '-',
      customSort: (a, b) => {
        return dateSortCompare(a.expirationDate, b.expirationDate);
      },
    },
    {
      field: 'createdAt',
      title: 'Date Uploaded',
      render: ({ createdAt }) => moment(createdAt).format('L'),
      customFilterAndSearch: (term, { createdAt }) => {
        return moment(createdAt).format('L').includes(term.toLowerCase());
      },
      customSort: (a, b) => {
        return dateSortCompare(a.createdAt, b.createdAt);
      },
    },
    {
      defaultSort: 'desc',
      field: 'carrierFileTypeId',
      title: 'Most Recent',
      type: 'boolean',
      // Don't prop check inline table components, applies to all columns here -ET
      // eslint-disable-next-line react/prop-types
      render: ({ fileId, carrierFileTypeId }) => (
        <Checkbox
          style={{ padding: 0 }}
          disabled
          checked={isMostRecentFile(fileId, carrierFileTypeId, files)}
        />
      ),
      customFilterAndSearch: (term, { fileId, carrierFileTypeId }) => {
        if (term === 'checked')
          return isMostRecentFile(fileId, carrierFileTypeId, files);
        if (term === 'unchecked')
          return !isMostRecentFile(fileId, carrierFileTypeId, files);
        return false;
      },
      customSort: (a, b) => {
        const fileA = isMostRecentFile(a.fileId, a.carrierFileTypeId, files);
        const fileB = isMostRecentFile(b.fileId, b.carrierFileTypeId, files);
        return stringSortCompare(fileA, fileB);
      },
    },
    {
      filtering: false,
      width: 75,
      title: '',
      // eslint-disable-next-line react/prop-types
      render: ({ fileId }) => (
        <IconButton
          size="small"
          color="primary"
          onClick={() => handleDownloadCarrierFile(fileId)}
          data-testid="carrier-download-file-btn"
        >
          <CloudDownloadIcon />
        </IconButton>
      ),
    },
    {
      hidden: !editable,
      filtering: false,
      width: 75,
      title: '',
      // eslint-disable-next-line react/prop-types
      render: ({ fileId }) => (
        <IconButton
          size="small"
          onClick={() => handleDeleteFile(fileId)}
          data-testid="carrier-delete-file-btn"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
  return (
    <>
      <Table
        data-testid="carrier-file-table"
        data={files}
        title="Files"
        titleVariant="h5"
        columns={columns}
        isLoading={isFilesLoading}
        errorText={filesError ? GENERIC_ERROR.EN : ''}
        components={
          editable && {
            Actions: () => (
              <Button
                color="primary"
                size="small"
                style={{ marginRight: 5 }}
                variant="contained"
                onClick={handleUploadFilesClick}
                data-testid="upload-files-button"
              >
                UPLOAD FILE
              </Button>
            ),
          }
        }
        options={{
          filterCellStyle: {
            textAlign: 'center',
            padding: '5px',
          },
          pageSize: 5,
          search: false,
          draggable: false,
          rowStyle: {
            height: '48px',
          },
        }}
      />

      <FileUploadModal
        files={files}
        setFiles={setFiles}
        carrierCompanyId={carrierCompanyId}
        fetchCarrierFiles={fetchCarrierFiles}
        setFileUploadModalOpen={setFileUploadModalOpen}
        isFileUploadModalOpen={isFileUploadModalOpen}
      />
    </>
  );
};

export default CarrierFiles;
