import React from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ContactList from '../../../../components/ContactList';
// import CarrierReps from './CarrierReps';

const CarrierContacts = () => {
  const { id: carrierId } = useParams();
  // These entities have been migrated to Arrive
  // and are no longer editable in Scout
  // const hasScopes = false;

  return (
    <Grid container data-testid="profile-info-component">
      <Grid container item spacing={3}>
        {/* <Grid item md={12}>
          <CarrierReps editable={hasScopes} />
        </Grid> */}
        <Grid item md={12}>
          <ContactList
            companyId={carrierId}
            companyTypeId={1}
            editable={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CarrierContacts;
