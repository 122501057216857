import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  Dialog,
  makeStyles,
  DialogTitle,
  Typography,
  IconButton,
  Divider,
  DialogContent,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Box,
  FormHelperText,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import {
  SadButton,
  HappyButton,
  FullCardLoader,
  FormTextField,
} from '@forager/bits';
import { notifications } from '@forager/constants';
import { withFormik } from 'formik';
import axios from 'axios';

const rejectionReasons = [
  {
    id: 1,
    reason: 'Non-Compliant SCT',
  },
  {
    id: 2,
    reason: 'Non-Compliant RFC',
  },
  {
    id: 3,
    reason: 'Insufficient Insurance',
  },
  {
    id: 4,
    reason: 'Contract Not Signed',
  },
  {
    id: 5,
    reason: 'Incomplete References',
  },
  {
    id: 6,
    reason: 'No Proof of Address',
  },
  {
    id: 7,
    reason: 'Other',
  },
];

const RejectCarrier = ({
  handleSubmit,
  isSubmitting,
  setFieldValue,
  values,
  errors,
  status,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const toggleIsOpen = () => setIsOpen(!isOpen);
  const reasonIdError = errors?.reasonId;

  useEffect(() => {
    if (status === 'success') {
      setIsOpen(false);
    }
  }, [status]);

  return (
    <>
      <SadButton data-testid="reject-carrier-valdation" onClick={toggleIsOpen}>
        Reject
      </SadButton>
      <Dialog open={isOpen} onClose={toggleIsOpen}>
        {isSubmitting && <FullCardLoader />}
        <DialogTitle disableTypography>
          <Typography variant="h5">Reject Carrier</Typography>
          <IconButton className={classes.closeButton} onClick={toggleIsOpen}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box padding="15px" minWidth="400px">
            <Grid container direction="column" justify="center" spacing={2}>
              <Grid item>
                <Typography variant="h4" align="center">
                  REJECT CARRIER
                </Typography>
              </Grid>
              <Grid item>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!reasonIdError}
                >
                  <InputLabel>Reason For Rejection</InputLabel>
                  <Select
                    value={values.reasonId}
                    onChange={e => setFieldValue('reasonId', e.target.value)}
                    label="Reason For Rejection"
                    inputProps={{
                      'data-testid': 'reason-select',
                    }}
                  >
                    {rejectionReasons.map(({ id, reason }) => (
                      <MenuItem key={id} value={id} data-testid="option">
                        {reason}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    error={!!reasonIdError}
                    data-testid="reason-error"
                  >
                    {reasonIdError || ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item>
                <FormTextField
                  label="Description"
                  name="description"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item container justify="center">
                <Grid item>
                  <SadButton
                    data-testid="reject-carrier-cancel"
                    onClick={toggleIsOpen}
                  >
                    Cancel
                  </SadButton>
                </Grid>
                <Grid item>
                  <HappyButton
                    data-testid="reject-carrier"
                    onClick={handleSubmit}
                  >
                    Reject
                  </HappyButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const submit = async (values, { props, setStatus }) => {
  const { accessToken, error, removeCarrierById, carrierData, applicationId } =
    props;
  const { tableData, ...restCarrierData } = carrierData;
  const { GENERIC_ERROR } = notifications;
  try {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/carrierApplications/${applicationId}`,
      {
        ...restCarrierData,
        ...values,
        applicationId,
        rejected: true,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    setStatus('success');
    removeCarrierById(applicationId);
  } catch (err) {
    error(GENERIC_ERROR);
  }
};

RejectCarrier.propTypes = {
  values: PropTypes.shape({ reasonId: PropTypes.number }),
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func,
  errors: PropTypes.shape({ reasonId: PropTypes.number }),
  status: PropTypes.string,
};

const validationSchema = Yup.object().shape({
  reasonId: Yup.number()
    .typeError('Must select rejection reason.')
    .required('Must select rejection reason.'),
  description: Yup.string().when('reasonId', {
    is: 7,
    then: Yup.string()
      .typeError('Description is required when Other is selected')
      .required('Description is required when Other is selected')
      .trim(),
    otherwise: Yup.string().nullable(),
  }),
});

export default withFormik({
  mapPropsToValues: () => ({ reasonId: null, description: '' }),
  handleSubmit: submit,
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema,
})(RejectCarrier);
