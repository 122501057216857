import qs from 'qs';

export const getUrl = ({ query, nextPage, prevPage, baseUrl }) => {
  let url = baseUrl;
  if (query?.evt?.type === 'Next') {
    url = nextPage;
  }
  // If paging next or prev, use value from paginationData
  if (query?.evt?.type === 'Previous') {
    url = prevPage;
  }
  return new URL(url);
};

export const parseQueryParams = ({ query, url }) => {
  const params = qs.parse(url.search, { ignoreQueryPrefix: true }); // grab params from url

  query.filters.forEach(({ column: { field }, value }) => {
    let actualValue;
    if (value === 'checked') {
      // checked/unchecked need to be changed to bool for query
      actualValue = true;
    } else if (value === 'unchecked') {
      actualValue = false;
    } else {
      actualValue = value;
    }
    params[field] = actualValue; // if not checkbox, just use value from table query
  });
  if (query.orderBy) {
    params.sort = query.orderBy.field;
    params.sortDirection = query.orderDirection;
  }

  return params;
};
