import * as Yup from 'yup';

const CustomsBrokerValidation = Yup.object().shape({
  customsBrokerPaperworkDeliveryMethodId: Yup.number().required(
    'This field is required'
  ),
  countryServicedId: Yup.number().required('This field is required'),
});

export default CustomsBrokerValidation;
