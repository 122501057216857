import React from 'react';
import { MTablePagination } from 'material-table';
import { makeStyles } from '@material-ui/styles';

const CustomPagination = props => {
  const classes = useStyles();
  const { tableRef } = props;

  const handleChangePage = evt => {
    const buttonName = evt.currentTarget.getAttribute('aria-label');
    if (['Next', 'Previous'].includes(buttonName)) {
      tableRef?.current?.onQueryChange({
        ...tableRef?.current?.state?.query,
        evt: {
          type: buttonName,
        },
      });
    }
  };
  return (
    <MTablePagination
      {...props}
      classes={classes}
      data-testid="pagination-component"
      localization={{
        previousAriaLabel: 'Previous',
        nextAriaLabel: 'Next',
      }}
      showFirstLastPageButtons={false}
      onChangePage={handleChangePage}
    />
  );
};

const useStyles = makeStyles({
  root: {
    justifyContent: 'flex-end',
    '& .MuiTypography-caption': {
      flexGrow: '0 !important',
    },
  },
});

export default CustomPagination;
