import * as Yup from 'yup';
import {
  fileTypes,
  fileTypesDisplay,
} from '../../../../constants/fileUploadTypes';

const polizaError = 'Poliza expiration date is required';

const fileSchema = Yup.object().shape({
  carrierFileTypeId: Yup.number().required('File type is required'),
  file: Yup.mixed()
    .required('Please select a file')
    .test('fileType', `File should be a ${fileTypesDisplay}`, function () {
      const { file } = this.parent;
      const allowableFormats = fileTypes;

      return allowableFormats.some(
        format => file?.type.includes(format) || file?.name.endsWith(format)
      );
    }),
  polizaExpireDate: Yup.mixed().when('carrierFileTypeId', {
    is: 7,
    then: Yup.date().typeError(polizaError).required(polizaError),
    otherwise: Yup.mixed().nullable(),
  }),
  sctNumber: Yup.mixed().when('carrierFileTypeId', {
    is: 5,
    then: Yup.string()
      .max(40, 'Should be no more than 40 characters long')
      .required('SCT Number is required')
      .typeError('SCT Number is required'),
    otherwise: Yup.mixed().nullable(),
  }),
});

export { fileTypes };
export default fileSchema;
