import React from 'react';
import { Grid, TextField, FormControlLabel, Box } from '@material-ui/core';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { FullCardLoader } from '@forager/bits';
import {
  countries,
  paperworkDeliveryMethodOptions,
  notifications,
} from '@forager/constants';
import CompanyProfileHeaderAndButtons from '../../../../components/CompanyProfileHeaderAndButtons';
import EnhancedSwitch from '../../../../components/EnhancedSwitch';
import PaperworkDeliveryMethodDropdown from './PaperworkDeliveryMethodDropdown';
import CountryServicedDropdown from './CountryServicedDropdown';
import CustomsBrokerValidation from './CustomsBrokerValidation';

export const initialValues = {
  hasCTPATCertification: false,
  hasOvertimeAvailable: false,
  countryServicedId: 0,
  customsBrokerPaperworkDeliveryMethodId: 0,
};
export const CustomsBrokerInformationForm = ({
  values: {
    customsBrokerPaperworkDeliveryMethodId,
    countryServicedId,
    hasCTPATCertification,
    hasOvertimeAvailable,
  },
  setFieldValue,
  errors,
  isEditingProfile,
  handleSubmit,
  isLoading,
  isSubmitting,
  fetchError,
  setIsEditingProfile,
}) => {
  const classes = useStyles();
  const handleToggleChange = (event, fieldName) =>
    setFieldValue(fieldName, event.target.checked);

  const currentPaperworkDelMethod = paperworkDeliveryMethodOptions.find(
    ({ ID }) => ID === customsBrokerPaperworkDeliveryMethodId
  );

  const currentCountryServiced = countries.find(
    ({ ID }) => ID === countryServicedId
  );

  return (
    <Grid container spacing={2} className={classes.container}>
      <CompanyProfileHeaderAndButtons
        handleSubmit={handleSubmit}
        isEditingProfile={isEditingProfile}
        setIsEditingProfile={setIsEditingProfile}
        companyInfoTitle="Customs Brokers Specs"
      />
      {(isLoading || isSubmitting) && <FullCardLoader />}
      {fetchError && (
        <Box m={4} textAlign="center">
          {notifications.GENERIC_ERROR.EN}
        </Box>
      )}
      <Grid container item spacing={2} direction="column">
        <Grid item xs={6} data-testid="customs-broker-information-form">
          {!isEditingProfile ? (
            <TextField
              required
              disabled
              data-testid="paperwork-delivery-field"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              label="Paperwork Delivery Method"
              name="customsBrokerPaperworkDeliveryMethod"
              value={currentPaperworkDelMethod?.NAME || '-'}
              fullWidth
            />
          ) : (
            <PaperworkDeliveryMethodDropdown
              errors={errors}
              fieldName="customsBrokerPaperworkDeliveryMethodId"
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {!isEditingProfile ? (
            <TextField
              required
              disabled
              data-testid="country-serviced-field"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              label="Country Serviced"
              name="countryServiced"
              value={currentCountryServiced?.SHORT || '-'}
            />
          ) : (
            <CountryServicedDropdown
              errors={errors}
              fieldName="countryServicedId"
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          data-testid="ctpat-toggle-label"
          labelPlacement="top"
          label="CTPAT"
          className={classes.toggles}
          control={
            <EnhancedSwitch
              data-testid="ctpat-toggle"
              checked={hasCTPATCertification}
              disabled={!isEditingProfile}
              onChange={evt => handleToggleChange(evt, 'hasCTPATCertification')}
            />
          }
        />
        <FormControlLabel
          data-testid="overtime-toggle-label"
          labelPlacement="top"
          label="Overtime Available"
          className={classes.toggles}
          control={
            <EnhancedSwitch
              data-testid="overtime-toggle"
              checked={hasOvertimeAvailable}
              disabled={!isEditingProfile}
              onChange={evt => handleToggleChange(evt, 'hasOvertimeAvailable')}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export const submit = async (values, { props }) => {
  const {
    companyId,
    accessToken,
    setIsLoading,
    setIsEditingProfile,
    setCustomsBrokerData,
    error,
  } = props;
  setIsLoading(true);
  const { GENERIC_ERROR } = notifications;
  const {
    customsBrokerPaperworkDeliveryMethod,
    countryServicedShortName,
    ...payload
  } = values;
  try {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/customs-brokers/${companyId}`,
      payload,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    setCustomsBrokerData(values);
    setIsEditingProfile(false);
  } catch (err) {
    error(GENERIC_ERROR.EN);
  } finally {
    setIsLoading(false);
  }
};

const useStyles = makeStyles({
  container: { position: 'relative' },
  toggles: { marginLeft: '0' },
  dropdowns: { width: '300px' },
});

CustomsBrokerInformationForm.propTypes = {
  values: PropTypes.shape({
    customsBrokerPaperworkDeliveryMethodId: PropTypes.number,
    countryServicedId: PropTypes.number,
    hasCTPATCertification: PropTypes.bool,
    hasOvertimeAvailable: PropTypes.bool,
  }),
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  isEditingProfile: PropTypes.bool,
  handleSubmi: PropTypes.func,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  fetchError: PropTypes.bool,
  setIsEditingProfile: PropTypes.func,
};

export default withFormik({
  mapPropsToValues: ({ customsBrokerData }) => ({
    ...initialValues,
    ...customsBrokerData,
  }),
  handleSubmit: submit,
  enableReinitialize: true,
  validationSchema: CustomsBrokerValidation,
})(CustomsBrokerInformationForm);
