import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useAuth0 } from '@forager/client-utils';
import { dayjs } from '@forager/date-utils';
import { FullCardLoader } from '@forager/bits';
import { notifications } from '@forager/constants';

const Compliance = () => {
  const classes = useStyles();
  const { accessToken } = useAuth0();
  const { id: companyId } = useParams();
  const [complianceInfo, setComplianceInfo] = useState(defaultComplianceInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const { GENERIC_ERROR } = notifications;

  const isAuthorized = val =>
    ({ true: 'Authorized', false: 'Not Authorized', null: '-' }[val]);

  const isCertified = val => ({ true: 'Yes', false: 'No', null: '-' }[val]);

  const formatDate = val => (val ? dayjs(val).utc().format('MM/DD/YYYY') : '-');

  const fetchCarrierCompliance = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/carriers/${companyId}/compliance`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setComplianceInfo(data);
    } catch (error) {
      setFetchError(true);
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, companyId]);

  useEffect(() => {
    fetchCarrierCompliance();
  }, [fetchCarrierCompliance]);

  return (
    <Card className={classes.card} data-testid="compliance-container">
      <CardHeader title="General Info" />
      {isLoading && <FullCardLoader />}
      {fetchError ? (
        <Box className={classes.error}>{GENERIC_ERROR.EN}</Box>
      ) : (
        <CardContent>
          <Grid container item xs={12}>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="MC/MX #"
                InputLabelProps={{ shrink: true }}
                value={complianceInfo.mcOrMxNumber || '-'}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="Safety Rating"
                InputLabelProps={{ shrink: true }}
                value={complianceInfo.carrierSafetyRating || '-'}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                multiline
                label="Operating Status"
                InputLabelProps={{ shrink: true }}
                value={complianceInfo.carrierOperatingStatus || '-'}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="Common Authority"
                InputLabelProps={{ shrink: true }}
                value={isAuthorized(complianceInfo.hasCommonAuthority)}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="Contract Authority"
                InputLabelProps={{ shrink: true }}
                value={isAuthorized(complianceInfo.hasContractAuthority)}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="Broker Authority"
                InputLabelProps={{ shrink: true }}
                value={isAuthorized(complianceInfo.hasBrokerAuthority)}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.complianceRow}>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="DOT"
                InputLabelProps={{ shrink: true }}
                value={complianceInfo.dotNumber || '-'}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="Carrier Setup Date"
                InputLabelProps={{ shrink: true }}
                value={formatDate(complianceInfo.carrierSetupDate)}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="Intra-State"
                InputLabelProps={{ shrink: true }}
                value={complianceInfo.intraStateState || '-'}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="Intra-State #"
                InputLabelProps={{ shrink: true }}
                value={complianceInfo.intraStateNumber || '-'}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="Authority Grant Date"
                InputLabelProps={{ shrink: true }}
                value={formatDate(complianceInfo.authorityIssueDate)}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.complianceRow}>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="SCAC"
                InputLabelProps={{ shrink: true }}
                value={complianceInfo.scacCode || '-'}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="Hazmat Certified"
                InputLabelProps={{ shrink: true }}
                value={isCertified(complianceInfo.hasHazmatCertification)}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="Hazmat Expiration"
                InputLabelProps={{ shrink: true }}
                value={formatDate(
                  complianceInfo.hazmatCertificationExpirationDate
                )}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.complianceRow}>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="CARB"
                InputLabelProps={{ shrink: true }}
                value={isCertified(complianceInfo.hasCarbCertification)}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="C-TPAT Certified"
                InputLabelProps={{ shrink: true }}
                value={isCertified(complianceInfo.hasCTPATCertification)}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="RFC"
                InputLabelProps={{ shrink: true }}
                value={complianceInfo.rfcNumber || '-'}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                disabled
                label="SCT"
                InputLabelProps={{ shrink: true }}
                value={complianceInfo.sctNumber || '-'}
              />
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

const defaultComplianceInfo = {
  hasBrokerAuthority: null,
  hasCarbCertification: null,
  hasCommonAuthority: null,
  hasContractAuthority: null,
  hasCTPATCertification: null,
  hasHazmatCertification: null,
};

const useStyles = makeStyles(() => ({
  card: { position: 'relative' },
  error: {
    height: '240px',
    textAlign: 'center',
    lineHeight: '240px',
  },
}));

export default Compliance;
