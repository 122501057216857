/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

const { NODE_ENV, REACT_APP_GA_MEASUREMENT_ID: GA_MEASUREMENT_ID } =
  process.env;

const Page = ({ title, className, children, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return;
    }

    if (window.gtag) {
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: location.pathname,
        page_name: title,
      });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div {...rest} className={clsx(classes.page, className)}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  page: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Page;
