export const initialValues = {
  address1: '',
  address2: '',
  city: '',
  country: '',
  countryServicedId: '',
  customsBrokerPaperworkDeliveryMethodId: '',
  dbaName: '',
  email: '',
  firstName: '',
  hasOvertimeAvailable: false,
  hasCTPATCertification: false,
  lastName: '',
  legalName: '',
  mobile: '',
  phone: '',
  phoneExt: '',
  postalCode: '',
  state: '',
  title: '',
};
