import { createAction } from '@reduxjs/toolkit';

export const addFiles = createAction('loadOverview/addFiles');
export const deleteFile = createAction('loadOverview/deleteFile');

export const addFilesReducer = (state, action) => {
  state.files.data.push(...action.payload);
};

export const deleteFileReducer = (state, action) => {
  state.files.data = state.files.data.filter(
    ({ loadFileId }) => loadFileId !== action.payload.loadFileId
  );
};
