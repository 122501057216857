import { createAction } from '@reduxjs/toolkit';

export const updateBorderCrossingTracking = createAction(
  'loadOverview/updateBorderCrossingTracking'
);

export const updateBorderCrossingTrackingReducer = (state, action) => {
  if (state.borderCrossings.data.mex?.[0] && action.payload.mex?.[0])
    state.borderCrossings.data.mex[0].tracking = action.payload.mex?.[0];
};
