import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetch = async ({ accessToken, loadId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_LOAD_API}/v1/loads/${loadId}/files`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
};

const fetchFiles = createAsyncThunk('loadOverview/fetchFiles', fetch, {
  condition: (_args, { getState }) => {
    const { loadOverview } = getState();
    const fileState = loadOverview.files;
    if (Array.isArray(fileState.data) || fileState.isLoading === true) {
      return false;
    }
  },
});

export const extraReducers = {
  [fetchFiles.fulfilled]: (state, action) => {
    state.files.data = action?.payload;
    state.files.isLoading = false;
  },
  [fetchFiles.pending]: state => {
    state.files.isLoading = true;
  },
  [fetchFiles.rejected]: state => {
    state.files.fetchError = true;
    state.files.isLoading = false;
  },
};

export default fetchFiles;
