import { createAction } from '@reduxjs/toolkit';

export const updateLoadStatus = createAction('loadOverview/updateLoadStatus');

export const updateLoadStatusReducer = (state, action) => {
  state.info.data = {
    ...state.info.data,
    ...action.payload,
  };
};
