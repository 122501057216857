import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const TabPanel = ({ children, value, index, ...restProps }) => {
  return (
    <Typography
      component="div"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      data-testid={`tabpanel-${index}`}
      role="tabpanel"
      {...restProps}
    >
      {value === index && children}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: '',
};

export default TabPanel;
