import * as Yup from 'yup';
import { validatePhone, hasPhoneOrMobile } from '@forager/client-utils';

export const validationSchema = Yup.object().shape({
  legalName: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(80, 'Should be no more than 80 characters')
    .required('This field is required')
    .trim(),
  dbaName: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(80, 'Should be no more than 80 characters')
    .nullable(),
  address1: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(125, 'Should be no more than 125 characters')
    .required('This field is required'),
  city: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(50, 'Should be no more than 50 characters')
    .required('This field is required'),
  state: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(50, 'Should be no more than 50 characters')
    .required('This field is required'),
  postalCode: Yup.string()
    .min(5, 'Should be at least 5 characters long')
    .max(10, 'Should be no more than 10 characters')
    .required('This field is required'),
  firstName: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(50, 'Should be no more than 50 characters')
    .required('This field is required')
    .trim(),
  lastName: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(50, 'Should be no more than 50 characters')
    .trim()
    .nullable(),
  email: Yup.string().email('Invalid email').required('This field is required'),
  title: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(50, 'Should be no more than 50 characters')
    .trim()
    .nullable(),
  phone: Yup.string()
    .test('phone', 'Must be a valid phone number', function () {
      const { phone } = this.parent;
      return phone ? validatePhone(phone) : true;
    })
    .test(
      'combination',
      'Either an office or mobile phone must be provided',
      hasPhoneOrMobile
    ),
  mobile: Yup.string()
    .test('phone', 'Must be a valid phone number', function () {
      const { mobile } = this.parent;
      return mobile ? validatePhone(mobile) : true;
    })
    .test(
      'combination',
      'Either an office or mobile phone must be provided',
      hasPhoneOrMobile
    ),
  customsBrokerPaperworkDeliveryMethodId: Yup.number().required(
    'This field is required'
  ),
  countryServicedId: Yup.number().required('This field is required'),
});
