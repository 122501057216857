export const paymentMethodOptions = [
  {
    id: 1,
    label: 'ACH',
  },
  {
    id: 2,
    label: 'Check',
  },
  {
    id: 3,
    label: 'Wire',
  },
  {
    id: 4,
    label: 'EFS Code',
  },
  {
    id: 5,
    label: 'Credit Card',
  },
];

export const paymentTermsOptions = [
  {
    id: 1,
    label: 'QuickPay',
  },
  {
    id: 2,
    label: 'Standard Pay',
  },
];
