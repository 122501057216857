import { Box, Button, CardContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import debounce from 'debounce';

import { SelectDropdown, FormTextField } from '@forager/bits';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { labels } from '@forager/constants';
import { useAuth0 } from '@forager/client-utils';
import CityOption from './CityOption';

const NewAddressForm = ({
  values,
  setValues,
  setTouched,
  initialValues,
  onAddressComplete,
  close,
  countries,
}) => {
  // eslint-disable-next-line no-use-before-define
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useAuth0();
  const [options, setOptions] = useState(undefined);

  useEffect(() => {
    if (values.selectedAddress) {
      setValues({
        ...values,
        city: values.selectedAddress.city || '',
        state: values.selectedAddress.state || '',
        country: values.selectedAddress.country || '',
      });
      setTouched(
        Object.keys(values.selectedAddress).map(val => ({ [val]: true }))
      );
    }
    // eslint-disable-next-line
  }, [values.selectedAddress]); // i purposefully only want this to run if a new address is selected

  const handleAddressInputChange = async input => {
    if (input === '') return setOptions([]);

    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_ADDRESS_API}/v1/cities`,
        {
          params: {
            query: input,
            countries,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setOptions(data);
    } catch (err) {
      setOptions([]);
    } finally {
      setIsLoading(false);
    }

    return true;
  };

  const handleCancelClick = () => {
    const emptyAddressValues = {
      ...initialValues,
      fullMatch: '',
      lat: '',
      lng: '',
      mapboxId: '',
    };
    setValues(emptyAddressValues);
    onAddressComplete({ ...emptyAddressValues, cityId: undefined });
    close();
  };

  return (
    <CardContent>
      <div>
        <Box marginBottom="15px" width="100%">
          <FormTextField
            className={classes.fill}
            data-testid="new-address1"
            variant="outlined"
            name="address1"
            required
            label={labels.STREET_ADDRESS.EN}
          />
        </Box>
        <Box marginBottom="15px" width="100%">
          <FormTextField
            className={classes.fill}
            data-testid="new-address2"
            variant="outlined"
            name="address2"
            label={labels.APT_OR_SUITE.EN}
          />
        </Box>
        <SelectDropdown
          data-testid="new-city-select"
          label={labels.CITY.EN}
          name="selectedAddress"
          isAsync
          options={options}
          optionTemplate={CityOption}
          filterOptions={addressOptions => addressOptions}
          onInputChange={debounce(
            input => handleAddressInputChange(input),
            300
          )}
          isLoading={isLoading}
          variant="outlined"
          optionLabelKey="city"
          noOptionsText="No Records Found!"
          style={{ marginBottom: '15px' }}
        />
        <div>
          <Box display="inline" marginRight="15px" width="92px">
            <FormTextField
              data-testid="new-state"
              variant="outlined"
              disabled
              required
              name="state"
              label={labels.STATE_OR_PROVINCE.EN}
            />
          </Box>
          <Box marginRight="10px" display="inline">
            <FormTextField
              data-testid="new-country"
              variant="outlined"
              disabled
              required
              name="country"
              label={labels.COUNTRY.EN}
            />
          </Box>
          <Box display="inline" width="92px">
            <FormTextField
              data-testid="new-postalCode"
              variant="outlined"
              name="postalCode"
              required
              label={labels.POSTAL_CODE.EN}
            />
          </Box>
        </div>
      </div>
      <Box marginTop="15px" display="flex" justifyContent="flex-end">
        <Button
          data-testid="new-cancel-button"
          color="secondary"
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button
          data-testid="new-done-button"
          color="primary"
          disabled={
            !values.address1 ||
            !values.city ||
            !values.state ||
            !values.country ||
            !values.postalCode
          }
          onClick={close}
        >
          Done
        </Button>
      </Box>
    </CardContent>
  );
};

const useStyles = makeStyles({
  fill: {
    width: '100%',
  },
});

NewAddressForm.propTypes = {
  values: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])
  ),
  setValues: PropTypes.func,
  setTouched: PropTypes.func,
  initialValues: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    selectedAddress: PropTypes.oneOf([null]),
  }),
  onAddressComplete: PropTypes.func,
  close: PropTypes.func,
  countries: PropTypes.string,
};

NewAddressForm.defaultProps = {
  values: {},
  setValues: () => {},
  setTouched: () => {},
  onAddressComplete: () => {},
  close: () => {},
};

export default NewAddressForm;
