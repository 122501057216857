import {
  Avatar,
  Divider,
  Drawer,
  Grid,
  Hidden,
  List,
  ListSubheader,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useAuth0, useScopes } from '@forager/client-utils';

import NavItem from '../../components/NavItem';
import navConfig from './navConfig';
import PrivateComponent from '../../components/PrivateComponent';

// renderNavItems calls itself recursively when a menu item has sub-items,
// creating the sub-items and automatically increasing the padding to
// give a nested appearance (i.e. depth), it also adds
// the open/close functionality for these sub-item dropdowns..
const renderNavItems = ({ items, subheader, key, ...rest }) => {
  return (
    <List key={JSON.stringify(items)}>
      {subheader && (
        <ListSubheader disableSticky>{subheader.toUpperCase()}</ListSubheader>
      )}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
};

const reduceChildRoutes = ({ acc, pathname, item, depth = 0 }) => {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        label={item.label}
        key={item.title}
        open={Boolean(open)}
        title={item.title}
        externalLink={item.externalLink}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
    /* this is checking if a nav item is something that
    should be protected with auth0 scopes, and then conditionally rendered */
  } else if (item.reqScope) {
    acc.push(
      <PrivateComponent reqScope={item.reqScope} key={item.href}>
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={item.href}
          label={item.label}
          title={item.title}
          externalLink={item.externalLink}
        />
      </PrivateComponent>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href || item.title}
        label={item.label}
        title={item.title}
        externalLink={item.externalLink}
        badge={item?.badge}
      />
    );
  }

  return acc;
};

const NavBar = ({ openMobile, onMobileClose, className, ...rest }) => {
  const { user } = useAuth0();
  const classes = useStyles();
  const location = useLocation();
  const [latestVersion, setLatestVersion] = useState(null);
  const { hasScopes: userRequiresProductionWarning } =
    useScopes('warning:production');
  const renderProductionWarning =
    process.env.REACT_APP_ENVIRONMENT === 'production' &&
    userRequiresProductionWarning;

  useEffect(() => {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
      .then(response => response.json())
      .then(meta => setLatestVersion(meta.version))
      .catch(err => console.log(err));
  }, []);

  const isMobile = useMediaQuery('(max-width:1366px)');

  useEffect(() => {
    if (isMobile) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname, isMobile]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {navConfig.map(list =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader,
          })
        )}
      </nav>
      <Typography variant="caption" className={classes.version}>
        {latestVersion ? `v${latestVersion}` : null}
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.profile}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <Avatar
              alt="Person"
              className={classes.avatar}
              src={user.picture}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5">{user.name}</Typography>
          </Grid>
          {/* TODO: Add company name of user to their auth token */}
          {/* <Typography variant="body2">{user.company}</Typography> */}
        </Grid>
      </div>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: clsx(
              classes.desktopDrawer,
              renderProductionWarning && classes.productionWarning
            ),
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  productionWarning: { marginTop: '32px' },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 40,
    height: 40,
  },
  version: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
}));

NavBar.propTypes = {
  className: PropTypes.string,
  subheader: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

renderNavItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    })
  ),
  subheader: PropTypes.string,
  key: PropTypes.string,
};

export default NavBar;
