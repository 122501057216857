import React from 'react';
import PropTypes from 'prop-types';

const CityOption = ({ city, state, country }) => {
  return (
    <div data-testid="city-option">
      {city}, {state} | {country}
    </div>
  );
};

CityOption.propTypes = {
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
};

CityOption.defaultProps = {
  city: '',
  state: '',
  country: '',
};

export default CityOption;
