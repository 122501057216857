import { configureStore } from '@reduxjs/toolkit';
import { capacitySlice } from '@forager/carrier-profile-components';
import { loadOverview } from '../views/LoadOverview/redux';

export default configureStore({
  reducer: {
    capacities: capacitySlice.reducer,
    loadOverview: loadOverview.reducer,
  },
});
