import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Grid,
  TextField,
  CardContent,
  Typography,
  Box,
} from '@material-ui/core';
import { FullCardLoader, Phone } from '@forager/bits';
import { notifications } from '@forager/constants';
import { useTheme } from '@material-ui/core/styles';

const CarrierStatus = ({ companyId, accessToken }) => {
  const theme = useTheme();
  const [fetchError, setFetchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carrierData, setCarrierData] = useState({});

  const getCarrierData = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/carriers/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setCarrierData(data);
    } catch (err) {
      setFetchError(true);
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, companyId]);

  useEffect(() => {
    getCarrierData();
  }, [getCarrierData]);

  const isCertified = carrierData?.carrierStatusWithForagerId === 1;
  const inputStyles = {
    backgroundColor: isCertified
      ? theme.palette.success.main
      : theme.palette.error.main,
    borderRadius: 3,
    textAlign: 'center',
    color: 'white',
  };

  return (
    <Grid item container spacing={2} style={{ position: 'relative' }}>
      <CardContent style={{ width: '100%' }}>
        <Grid item container xs={12} alignItems="center" style={{ height: 46 }}>
          <Typography variant="h5">Carrier Status</Typography>
        </Grid>
        {isLoading && <FullCardLoader />}
        {fetchError ? (
          <Box m={4} textAlign="center">
            {notifications.GENERIC_ERROR.EN}
          </Box>
        ) : (
          <Grid item container alignItems="center">
            <Grid item xs={5} sm={4}>
              <TextField
                data-testid="certification-status"
                name="carrierStatusWithForager"
                disabled
                label="Certification Status"
                value={carrierData?.carrierStatusWithForager || '-'}
                // Prevents background color blur when data is fetching
                inputProps={isLoading ? undefined : { style: inputStyles }}
              />
            </Grid>
            <Grid item xs={5} sm={6}>
              <Phone
                data-testid="company-phone"
                disabled
                label="Company Phone"
                value={carrierData?.companyPhone}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Grid>
  );
};

CarrierStatus.propTypes = {
  isLoading: PropTypes.bool,
};

export default CarrierStatus;
