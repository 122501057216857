const initialValues = {
  legalName: '',
  dbaName: '',
  address1: '',
  address2: '',
  cityName: '',
  stateShortName: '',
  postalCode: '',
};

export default initialValues;
