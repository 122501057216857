import { createAction } from '@reduxjs/toolkit';

export const addCarrierCharge = createAction('loadOverview/addCarrierCharge');
export const editCarrierCharge = createAction('loadOverview/editCarrierCharge');
export const deleteCarrierCharge = createAction(
  'loadOverview/deleteCarrierCharge'
);

const findCarrierIndex = (carriers, loadPortionId) => {
  return carriers.findIndex(carrier => carrier.loadPortionId === loadPortionId);
};

export const addCarrierChargeReducer = (state, action) => {
  const { newLoadCarrierCharge, loadPortionId } = action.payload;

  const foundCarrierIndex = findCarrierIndex(
    state.carriers.data,
    loadPortionId
  );

  state.carriers.data[foundCarrierIndex].rates.push(newLoadCarrierCharge);
};

export const editCarrierChargeReducer = (state, action) => {
  const {
    updatedCarrierCharge: { tableData, ...restCharge }, // remove material-table tableData
    loadPortionId,
  } = action.payload;

  const foundCarrierIndex = findCarrierIndex(
    state.carriers.data,
    loadPortionId
  );

  const foundRateIndex = state.carriers.data[foundCarrierIndex].rates.findIndex(
    rate => rate.loadExpenseItemId === restCharge.loadExpenseItemId
  );

  state.carriers.data[foundCarrierIndex].rates[foundRateIndex] = restCharge;
};

export const deleteCarrierChargeReducer = (state, action) => {
  const foundCarrierIndex = findCarrierIndex(
    state.carriers.data,
    action.payload.loadPortionId
  );

  state.carriers.data[foundCarrierIndex].rates = state.carriers.data[
    foundCarrierIndex
  ].rates.filter(
    charge => charge.loadExpenseItemId !== action.payload.loadExpenseItemId
  );
};
