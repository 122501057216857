import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetch = async ({ accessToken, loadId }, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_LOAD_API}/v1/loads/${loadId}/requirements`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
};

export const condition = (_args, { getState }) => {
  const { loadOverview } = getState();
  const requirements = loadOverview.requirements.data;
  const isLoading = loadOverview.requirements.isLoading;

  if (Array.isArray(requirements) || isLoading === true) {
    return false;
  }
  return true;
};

const fetchRequirements = createAsyncThunk(
  'loadOverview/fetchRequirements',
  fetch,
  { condition }
);

export const extraReducers = {
  [fetchRequirements.fulfilled]: (state, action) => {
    state.requirements.data = action?.payload;
    state.requirements.isLoading = false;
  },
  [fetchRequirements.pending]: state => {
    state.requirements.isLoading = true;
  },
  [fetchRequirements.rejected]: state => {
    state.requirements.fetchError = true;
    state.requirements.isLoading = false;
  },
};

export default fetchRequirements;
