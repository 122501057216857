import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import RemitToInfo from './RemitToInfo';

const BackOffice = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.card}>
        <RemitToInfo />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  card: {
    position: 'relative',
  },
});

export default BackOffice;
