export const initialState = {
  info: { data: null, isLoading: false, fetchError: false },
  borderCrossings: { data: null, isLoading: false, fetchError: false },
  stops: { data: null, isLoading: false, fetchError: false },
  charges: { data: null, isLoading: false, fetchError: false },
  incidents: { data: null, isLoading: false, fetchError: false },
  requirements: { data: null, isLoading: false, fetchError: false },
  files: { data: null, isLoading: false, fetchError: false },
  vendors: { data: null, isLoading: false, fetchError: false },
  products: { data: null, isLoading: false, fetchError: false },
  locationUpdates: { data: null, isLoading: false, fetchError: false },
  carriers: { data: null, isLoading: false, fetchError: false },
};
