import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  makeStyles,
  Typography,
  DialogTitle,
  Divider,
  IconButton,
  Grid,
  DialogContent,
  Hidden,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Phone, Email, Url } from '@forager/bits';
import OverflowInput from '../../../components/OverflowInput';

const Reference = ({
  companyName,
  firstName,
  lastName,
  title,
  phone,
  phoneExt,
  email,
  number,
  website,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        md={4}
        xs={12}
        item
        container
        spacing={2}
        style={{ minWidth: '250px', height: '100%' }}
        alignContent="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="h5">{`Reference ${number}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <OverflowInput title="Company Name" value={companyName} />
        </Grid>
        <Grid item xs={12} wrap="nowrap">
          <OverflowInput title="First Name" value={firstName} />
        </Grid>
        <Grid item xs={12}>
          <OverflowInput title="Last Name" value={lastName} />
        </Grid>
        <Grid item xs={12} wrap="nowrap">
          <OverflowInput title="Title" value={title} />
        </Grid>
        <Grid item xs={6} wrap="nowrap">
          <Phone
            data-testid="reference-office-phone"
            shrink
            extLabel="Ext."
            label="Office Phone"
            value={phone}
            extValue={phoneExt}
          />
        </Grid>
        <Grid item xs={12} wrap="nowrap">
          <Email
            data-testid="reference-email"
            label="Email"
            value={email}
            className={classes.email}
          />
        </Grid>
        <Grid item xs={12} wrap="nowrap">
          <Url label="Website" value={website || '-'} withCopy={!!website} />
        </Grid>
        <Hidden mdUp>
          {number !== 3 && (
            <Divider flexItem style={{ height: '1px', width: '100%' }} />
          )}
        </Hidden>
      </Grid>
    </>
  );
};

const References = ({ references }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);
  const classes = useStyles();
  return (
    <>
      <Typography
        data-testid="ac-link"
        color="secondary"
        className={classes.link}
        onClick={toggleIsOpen}
      >
        View References
      </Typography>
      <Dialog
        open={isOpen}
        onClose={toggleIsOpen}
        maxWidth="lg"
        data-testid="modal"
      >
        <DialogTitle disableTypography>
          <Typography variant="h5">References</Typography>
          <IconButton className={classes.closeButton} onClick={toggleIsOpen}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ minHeight: '485px' }}>
          <Grid container justify="space-between" spacing={2}>
            {references?.map((reference, index) => (
              <Reference {...reference} key={index + 1} number={index + 1} />
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  email: {
    justifyContent: 'flex-start',
  },
}));

const referenceProps = {
  companyName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  phone: PropTypes.string,
  phoneExt: PropTypes.string,
  email: PropTypes.string,
  website: PropTypes.string,
};

Reference.propTypes = {
  ...referenceProps,
  number: PropTypes.number,
};

References.propTypes = {
  references: PropTypes.arrayOf(
    PropTypes.shape(PropTypes.shape(referenceProps))
  ),
};

export default References;
