import React from 'react';
import { Formik } from 'formik';
import {
  Grid,
  Button,
  makeStyles,
  Box,
  FormControlLabel,
  Chip,
  Switch,
  Typography,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import { PhoneInput, EmailInput, FormTextField } from '@forager/bits';
import contactValidation from './contactValidation';
import ContactCheckbox from './ContactCheckbox';

export const ContactForm = ({
  contact = {},
  formAction,
  setIsContactModalOpen,
  hasMain,
  isBillingShown,
  isCarrier,
  isFacility,
  isCustomsBroker,
  hasGeneralContact,
}) => {
  const classes = useStyles();
  const defaultValues = {
    firstName: '',
    lastName: '',
    title: '',
    phone: '',
    phoneExt: '',
    mobile: '',
    email: '',
    isEmailOptional: false,
    isBilling: false,
  };

  const shouldRenderGeneralContact =
    (isFacility || isCustomsBroker) && !hasGeneralContact && !contact;
  if (hasMain) {
    defaultValues.isMain = false;
  }

  if (isCarrier) {
    defaultValues.isLoadOfferContact = false;
  }
  const noEmail = contact?.email?.toUpperCase() === 'N/A';

  const initialValues = {
    ...defaultValues,
    ...contact,
    email: noEmail ? '' : contact?.email ?? '',
  };

  const handleFormSubmit = values => {
    // Material Table sets a key called tableData when data runs thru it.
    // It must be deleted or the api call fails.
    // eslint-disable-next-line no-param-reassign
    delete values.tableData;
    const { email, isEmailOptional, ...restValues } = values;
    const emailValue = isEmailOptional ? 'N/A' : email;
    formAction({ ...restValues, email: emailValue });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={contactValidation}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, setFieldValue, values, setValues }) => {
        const handleCheckboxClick = (e, name) => {
          setFieldValue(name, e.target.checked);
        };

        const contactIsGeneralContact =
          values.firstName.toUpperCase() === 'GENERAL' &&
          values.lastName?.toUpperCase() === 'CONTACT';
        const { isEmailOptional } = values;

        const handleOptionalEmail = () => {
          if (!isEmailOptional) {
            setValues({ ...values, isEmailOptional: true, email: 'N/A' });
          } else {
            setFieldValue('isEmailOptional', false);
          }
        };

        const handleIsGeneralContact = () =>
          contactIsGeneralContact
            ? setValues({ ...values, firstName: '', lastName: '' })
            : setValues({
                ...values,
                firstName: 'General',
                lastName: 'Contact',
              });

        return (
          <>
            <Grid container spacing={2} data-testid="contact-form">
              <Grid item xs={12} md={shouldRenderGeneralContact ? 5 : 6}>
                <FormTextField
                  required
                  label="First Name"
                  name="firstName"
                  data-testid="firstName"
                  disabled={contactIsGeneralContact}
                  className={classes.textInputs}
                />
              </Grid>
              <Grid
                item
                xs={shouldRenderGeneralContact ? 9 : 12}
                md={shouldRenderGeneralContact ? 5 : 6}
              >
                <FormTextField
                  label="Last Name"
                  name="lastName"
                  data-testid="lastName"
                  disabled={contactIsGeneralContact}
                  className={classes.textInputs}
                />
              </Grid>
              {shouldRenderGeneralContact && (
                <Grid item xs={3} md={2}>
                  <FormControlLabel
                    control={<Switch size="small" />}
                    data-testid="general-contact"
                    label={
                      <Typography variant="caption">
                        General Contact?
                      </Typography>
                    }
                    labelPlacement="top"
                    onChange={handleIsGeneralContact}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <FormTextField
                  label="Title"
                  name="title"
                  data-testid="title"
                  className={classes.textInputs}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PhoneInput
                  required
                  label="Office Phone"
                  name="phone"
                  data-testid="phone"
                  extName="phoneExt"
                  extLabel="Ext."
                  labelWidth="75px"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PhoneInput
                  label="Cell Phone"
                  name="mobile"
                  hideExt
                  className={classes.textInputs}
                  labelWidth="75px"
                />
              </Grid>
              <Grid item xs={isFacility ? 9 : 12} md={isFacility ? 5 : 6}>
                <EmailInput
                  disabled={isEmailOptional}
                  label="Email"
                  name="email"
                  className={classes.textInputs}
                  data-testid="email"
                  required={!isEmailOptional}
                />
              </Grid>
              {isFacility && (
                <Grid item xs={3} md={1}>
                  <FormControlLabel
                    control={<Switch checked={isEmailOptional} />}
                    data-testid="email-optional"
                    label={<Typography variant="caption">No Email?</Typography>}
                    labelPlacement="top"
                    name="isEmailOptional"
                    onChange={handleOptionalEmail}
                  />
                </Grid>
              )}

              <Grid container item xs={12} md={6} spacing={2}>
                {hasMain && !values.isMain && (
                  <Grid item>
                    <ContactCheckbox
                      values={values}
                      handleClick={handleCheckboxClick}
                      label="Main Contact"
                      name="isMain"
                      testId="is-main-select"
                    />
                  </Grid>
                )}
                {!!values.isMain && (
                  <Grid item>
                    <Chip
                      data-testid="is-main-chip"
                      icon={<DoneIcon fontSize="small" />}
                      color="primary"
                      label="Main Contact"
                      className={classes.chip}
                    />
                  </Grid>
                )}
                {isCarrier && !values.isLoadOfferContact && (
                  <Grid item>
                    <ContactCheckbox
                      values={values}
                      handleClick={handleCheckboxClick}
                      label="Load Offer Contact"
                      name="isLoadOfferContact"
                      testId="is-load-offer-select"
                    />
                  </Grid>
                )}
                {!!values.isLoadOfferContact && (
                  <Grid item>
                    <Chip
                      data-testid="is-load-offer-chip"
                      icon={<DoneIcon fontSize="small" />}
                      color="primary"
                      label="Load Offer Contact"
                      className={classes.chip}
                    />
                  </Grid>
                )}

                {isBillingShown && (
                  <Grid item>
                    <ContactCheckbox
                      testId="is-billing-checkbox"
                      handleClick={handleCheckboxClick}
                      values={values}
                      name="isBilling"
                      label="Billing"
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} className={classes.submitButton}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  data-testid="contact-modal-submit-button"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <Box component="span" ml={1}>
                  <Button
                    variant="contained"
                    type="button"
                    onClick={() => setIsContactModalOpen(false)}
                    data-testid="contact-modal-cancel-button"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

const useStyles = makeStyles({
  textInputs: {
    width: '100%',
  },
  submitButton: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  container: {
    marginTop: '20px',
  },
  chip: {
    marginTop: '5px',
  },
});

ContactForm.propTypes = {
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    officePhone: PropTypes.string,
    officeExt: PropTypes.string,
    cellPhone: PropTypes.string,
    email: PropTypes.string,
  }),
  formAction: PropTypes.func,
  setIsContactModalOpen: PropTypes.func,
  hasMain: PropTypes.bool,
  isBillingShown: PropTypes.bool,
  isFacility: PropTypes.bool,
  isCustomsBroker: PropTypes.bool,
  hasGeneralContact: PropTypes.bool,
};

export default ContactForm;
