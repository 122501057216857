import { Search as SearchIcon } from '@material-ui/icons';
import {
  Input,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  Typography,
} from '@material-ui/core';
import { FullCardLoader } from '@forager/bits';
import React, { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'debounce';
import { makeStyles } from '@material-ui/styles';
import { notifications } from '@forager/constants';
import { useAuth0 } from '@forager/client-utils';

const TopBarSearch = () => {
  const classes = useStyles();
  const { accessToken } = useAuth0();
  const searchRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchQueryResults, setSearchQueryResults] = useState([]);
  const [searchError, setSearchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { GENERIC_ERROR } = notifications;

  const noResultsMessage = () => (
    <Typography align="center">
      {searchError ? GENERIC_ERROR.EN : 'No Loads Found Matching Your Query'}
    </Typography>
  );

  const handleSearchResultClick = () => {
    handleSearchPopoverClose();
    setSearchValue('');
    setSearchQueryResults([]);
  };

  const handleSearchPopoverClose = () => {
    setOpenSearchPopover(false);
  };

  const fetchSearchResults = searchString => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        searchString,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/search/loads`, config)
      .then(res => {
        setSearchQueryResults(res.data);
      })
      .catch(() => {
        setSearchError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendInputChange = useRef(
    debounce(input => fetchSearchResults(input), 300)
  ).current;

  const handleSearchChange = event => {
    setSearchValue(event.target.value);
    setSearchError(false);
    if (event.target.value) {
      setIsLoading(true);
      sendInputChange(event.target.value);
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  return (
    <>
      <div className={classes.search} ref={searchRef}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          className={classes.searchInput}
          disableUnderline
          onChange={handleSearchChange}
          placeholder="Load # or Reference #"
          value={searchValue}
          data-testid="topbar-search-input"
        />
      </div>
      <Popper
        anchorEl={searchRef.current}
        className={classes.searchPopper}
        open={openSearchPopover}
        transition
      >
        <ClickAwayListener onClickAway={handleSearchPopoverClose}>
          <Paper className={classes.searchPopperContent} elevation={3}>
            {isLoading && <FullCardLoader />}
            <List>
              {searchQueryResults.length > 0
                ? searchQueryResults.map(result => (
                    <RouterLink
                      key={result.loadId}
                      to={`/loads/${result.loadId}`}
                    >
                      <ListItem
                        button
                        key={result.loadId}
                        onClick={handleSearchResultClick}
                      >
                        <ListItemIcon>
                          <SearchIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${result.loadNumber} ${
                            result.customerStopReferenceNumber
                              ? `| ${result.customerStopReferenceNumber}`
                              : ''
                          }`}
                        />
                      </ListItem>
                    </RouterLink>
                  ))
                : noResultsMessage()}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
const useStyles = makeStyles(theme => ({
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    '& input': {
      color: '#fff',
    },
    '& input::placeholder': {
      opacity: 1,
      color: '#fff',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
    width: 300,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    position: 'relative',
  },
  searchLoading: { width: '100%', display: 'flex', justifyContent: 'center' },
}));

export default TopBarSearch;
