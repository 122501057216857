import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import debounce from 'debounce';

import { SelectDropdown, FormTextField } from '@forager/bits';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { labels } from '@forager/constants';
import { useAuth0 } from '@forager/client-utils';
import AddressOption from './AddressOption';

const FullAddressForm = ({
  values,
  setValues,
  setTouched,
  notFoundOption,
  // Tracks validation errors (passed by parent Formik form)
  parentErrors,
  addressDisabled,
  inputVariant,
  onAddressComplete,
  initialValues,
  countries,
}) => {
  // eslint-disable-next-line no-use-before-define
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useAuth0();
  const [options, setOptions] = useState(undefined);
  const [postalCodeDisabled, setPostalCodeDisabled] = useState(true);

  useEffect(() => {
    if (values.selectedAddress?.city) {
      // make postal code input editable in cases where the
      // address sent by mapbox does not include a postal code
      if (!values.selectedAddress.postalCode) {
        setPostalCodeDisabled(false);
      }
      setValues({
        ...values,
        postalCode: '',
        ...values.selectedAddress,
      });
      setTouched(
        Object.keys(values.selectedAddress).map(val => ({ [val]: true }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedAddress]); // purposely only want this to run when a new address is selected

  const handleAddressInputChange = async input => {
    if (values.address1) {
      const emptyAddressValues = {
        ...initialValues,
        fullMatch: '',
        lat: '',
        lng: '',
        mapboxId: '',
      };

      // reset values in FullAddressInput (city, state, postal code, etc.) whenever address1 changes, and display the current input in the address1 field
      setValues({
        ...emptyAddressValues,
        selectedAddress: input ? { address1: input } : null,
      });

      // reset address values in the parent form whenever address1 changes
      delete emptyAddressValues.selectedAddress;
      onAddressComplete({ ...emptyAddressValues, cityId: undefined });
    }

    if (input === '') return setOptions([]);

    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_ADDRESS_API}/v1/addresses`,
        {
          params: {
            query: input,
            countries,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setOptions(data);
    } catch (err) {
      setOptions([]);
    } finally {
      setIsLoading(false);
    }

    return true;
  };

  return (
    <Grid container spacing={2}>
      <Grid item className={classes.full}>
        <SelectDropdown
          data-testid="full-address-select"
          label={`${labels.STREET_ADDRESS.EN}`}
          name="selectedAddress"
          isAsync
          filterOptions={addressOptions => addressOptions}
          options={options}
          optionTemplate={AddressOption}
          onInputChange={debounce(
            input => handleAddressInputChange(input),
            300
          )}
          isLoading={isLoading}
          variant={inputVariant}
          optionLabelKey="address1"
          noOptionsText={notFoundOption}
          error={!!parentErrors.address1}
          helperText={parentErrors.address1}
          disabled={addressDisabled}
        />
      </Grid>
      <Grid item className={classes.full}>
        <FormTextField
          className={classes.full}
          data-testid="full-address-2"
          variant={inputVariant}
          name="address2"
          label={labels.APT_OR_SUITE.EN}
          error={!!parentErrors.address2}
          helperText={parentErrors.address2}
          disabled={addressDisabled}
        />
      </Grid>
      <Grid item className={classes.full}>
        <FormTextField
          data-testid="full-city"
          variant={inputVariant}
          disabled
          required
          name="city"
          label={labels.CITY.EN}
          className={classes.full}
          error={!!parentErrors.city}
          helperText={parentErrors.city}
        />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <FormTextField
            className={classes.full}
            data-testid="full-state"
            variant={inputVariant}
            disabled
            required
            name="state"
            label={labels.STATE_OR_PROVINCE.EN}
            error={!!parentErrors.state}
            helperText={parentErrors.state}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            className={classes.full}
            data-testid="full-postalCode"
            disabled={addressDisabled || postalCodeDisabled}
            variant={inputVariant}
            name="postalCode"
            label={labels.POSTAL_CODE.EN}
            inputProps={{ maxLength: 10 }}
            error={!!parentErrors.postalCode}
            helperText={parentErrors.postalCode}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  full: {
    width: '100%',
  },
});

FullAddressForm.propTypes = {
  values: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    selectedAddress: PropTypes.shape({
      postalCode: PropTypes.string,
      city: PropTypes.string,
    }),
  }),
  setValues: PropTypes.func,
  setTouched: PropTypes.func,
  notFoundOption: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.node,
  ]),
  parentErrors: PropTypes.objectOf(PropTypes.string),
  addressDisabled: PropTypes.bool,
  inputVariant: PropTypes.string,
  required: PropTypes.bool,
  onAddressComplete: PropTypes.func,
  initialValues: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    selectedAddress: PropTypes.oneOf([null]),
  }),
  countries: PropTypes.string,
};

FullAddressForm.defaultProps = {
  values: {},
  setValues: () => {},
  setTouched: () => {},
  notFoundOption: undefined,
  // parentErrors must be the Formik error object from the parent Formik form
  parentErrors: {},
  addressDisabled: false,
  inputVariant: 'outlined',
  required: false,
  onAddressComplete: () => {},
};

export default FullAddressForm;
