import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { HappyButton } from '@forager/bits';

const CompanyProfileHeaderAndButtons = ({
  isEditingProfile,
  setIsEditingProfile,
  handleSubmit,
  companyInfoTitle,
  editable,
}) => {
  const editSaveButtonProps = {
    variant: 'contained',
    size: 'small',
    'data-testid': 'edit-button',
    style: { margin: '0' },
  };

  return (
    <Grid container item xs={12} justify="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h5">{companyInfoTitle}</Typography>
      </Grid>
      {editable && (
        <Grid item>
          {!isEditingProfile ? (
            <Button
              {...editSaveButtonProps}
              color="primary"
              onClick={() => setIsEditingProfile(!isEditingProfile)}
            >
              Edit
            </Button>
          ) : (
            <HappyButton {...editSaveButtonProps} onClick={handleSubmit}>
              Save
            </HappyButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};

CompanyProfileHeaderAndButtons.propTypes = {
  handleSubmit: PropTypes.func,
  isEditingProfile: PropTypes.bool,
  setIsEditingProfile: PropTypes.func,
  companyInfoTitle: PropTypes.string,
  editable: PropTypes.bool,
};

CompanyProfileHeaderAndButtons.defaultProps = {
  editable: true,
};

export default CompanyProfileHeaderAndButtons;
