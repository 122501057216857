import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box } from '@material-ui/core';

const NotFoundButton = ({ toggleOpen }) => {
  return (
    // the mouse down event needs to be here to prevent the dropdown from closing before the button action happens
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div role="button" onMouseDown={evt => evt.preventDefault()}>
      Don&apos;t see your address?
      <Box marginLeft="15px" display="inline">
        <Button
          data-testid="not-found-button"
          color="primary"
          onClick={toggleOpen}
        >
          Add Manually
        </Button>
      </Box>
    </div>
  );
};

NotFoundButton.propTypes = {
  toggleOpen: PropTypes.func,
};

NotFoundButton.defaultProps = {
  toggleOpen: () => {},
};

export default NotFoundButton;
