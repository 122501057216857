import { createSlice } from '@reduxjs/toolkit';
import { initialState as loadOverviewInitialState } from './initialState';
import { extraReducers } from './reducers';
import {
  addProductReducer,
  editProductReducer,
  deleteProductReducer,
} from './reducers/productDetailsReducers';
import { updateLoadChargesReducer } from './reducers/loadChargesReducers';
import { updateLoadStatusReducer } from './reducers/loadStatusReducer';
import { addLocationUpdateReducer } from './reducers/locationUpdatesReducer';
import { updateBorderCrossingDetailsReducer } from './reducers/updateBorderCrossingDetails';
import { updateBorderCrossingTrackingReducer } from './reducers/updateBorderCrossingTracking';
import {
  updateStopDetailsReducer,
  updateStopTrackingReducer,
} from './reducers/updateStops';
import {
  addRequirementReducer,
  editRequirementReducer,
  deleteRequirementReducer,
} from './reducers/requirementsReducers';
import { resolveIncidentReducer } from './reducers/resolveIncident';
import { createIncidentReducer } from './reducers/createIncident';
import {
  addVendorReducer,
  deleteVendorReducer,
  editVendorReducer,
} from './reducers/vendorsReducer';
import { addFilesReducer, deleteFileReducer } from './reducers/filesReducers';
import { updateCustomsBrokersReducer } from './reducers/customsBrokersReducers';
import { updateTransferCarrierReducer } from './reducers/updateTransferCarrier';
import { updateLoadInfoReducer } from './reducers/loadInfoReducer';
import {
  addCarrierChargeReducer,
  deleteCarrierChargeReducer,
  editCarrierChargeReducer,
} from './reducers/carrierChargesReducers';
import { completeRoutingGuideReducer } from './reducers/routingGuideReducers';
import {
  addLoadCarrierReducer,
  updateLoadCarrierReducer,
  deleteLoadCarrierReducer,
} from './reducers/loadCarrierReducers';

export const loadOverview = createSlice({
  name: 'loadOverview',
  initialState: loadOverviewInitialState,
  reducers: {
    reset: () => loadOverviewInitialState,
    addProduct: addProductReducer,
    editProduct: editProductReducer,
    deleteProduct: deleteProductReducer,
    updateLoadStatus: updateLoadStatusReducer,
    addLocationUpdate: addLocationUpdateReducer,
    updateBorderCrossingDetails: updateBorderCrossingDetailsReducer,
    updateBorderCrossingTracking: updateBorderCrossingTrackingReducer,
    updateStopDetails: updateStopDetailsReducer,
    updateStopTracking: updateStopTrackingReducer,
    addRequirement: addRequirementReducer,
    editRequirement: editRequirementReducer,
    deleteRequirement: deleteRequirementReducer,
    resolveIncident: resolveIncidentReducer,
    createIncident: createIncidentReducer,
    addVendor: addVendorReducer,
    deleteVendor: deleteVendorReducer,
    editVendor: editVendorReducer,
    addFiles: addFilesReducer,
    deleteFile: deleteFileReducer,
    updateLoadCharges: updateLoadChargesReducer,
    updateCustomsBrokers: updateCustomsBrokersReducer,
    updateTransferCarrier: updateTransferCarrierReducer,
    updateLoadInfo: updateLoadInfoReducer,
    addCarrierCharge: addCarrierChargeReducer,
    editCarrierCharge: editCarrierChargeReducer,
    deleteCarrierCharge: deleteCarrierChargeReducer,
    completeRoutingGuide: completeRoutingGuideReducer,
    addLoadCarrier: addLoadCarrierReducer,
    updateLoadCarrier: updateLoadCarrierReducer,
    deleteLoadCarrier: deleteLoadCarrierReducer,
  },
  extraReducers,
});

export const reducer = loadOverview.reducer;
export const actions = loadOverview.actions;

export default loadOverview;
