import React from 'react';
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Image from '../../assets/img/error-background.svg';

const ErrorPage = ({ handleSetErrorState }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.backgroundStyle} data-testid="error-container">
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
        spacing={5}
      >
        <Grid item data-testid="main-header">
          <Typography
            align="center"
            className={isMobileDevice ? null : classes.header}
            variant={isMobileDevice ? 'h3' : null}
          >
            Something went wrong
          </Typography>
        </Grid>
        <Grid item mt={3} data-testid="sub-header">
          <Typography
            align="center"
            variant={isMobileDevice ? 'h5' : 'h2'}
            color="textSecondary"
          >
            Don&#39;t worry though. Our engineers are on the case. Click back to
            return to the previous page.
          </Typography>
        </Grid>
        <Grid item mt={2}>
          <Button
            data-testid="go-back-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              handleSetErrorState();
              history.goBack();
            }}
          >
            GO BACK
          </Button>
        </Grid>
      </Grid>
      <div className={classes.mediaContainer} data-testid="chi-town">
        <img alt="error-background" src={Image} className={classes.media} />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  backgroundStyle: {
    paddingTop: '19vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    backgroundColor: 'white',
  },
  container: {
    backgroundColor: '#FFF',
  },
  media: {
    maxWidth: '100%',
    width: '100%',
    maxHeight: 420,
    height: 'auto',
  },
  mediaContainer: {
    marginTop: 'calc(5% + 60px)',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    fontSize: '54px',
    fontWeight: 'bold',
  },
});

ErrorPage.propTypes = {
  handleSetErrorState: PropTypes.func,
};

export default ErrorPage;
