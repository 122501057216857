import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useAuth0, useForagerNotification } from '@forager/client-utils';
import { FullCardLoader } from '@forager/bits';
import { notifications } from '@forager/constants';

import RemitToInfoForm from './RemitToInfoForm';
import PrivateComponent from '../../../../../components/PrivateComponent';
import {
  paymentMethodOptions,
  paymentTermsOptions,
} from '../../../../../constants/carrierRemitTo';

const RemitToInfo = () => {
  const classes = useStyles();
  const { accessToken } = useAuth0();
  const { id: companyId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [remitToInfo, setRemitToInfo] = useState(initialRemitToInfoState);
  const [factoringCompanies, setFactoringCompanies] = useState([]);
  const [billingContacts, setBillingContacts] = useState([]);
  const { success, error } = useForagerNotification();

  const { GENERIC_ERROR } = notifications;

  const fetchRemitToInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/carriers/${companyId}/remit-to`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setRemitToInfo(data);
    } catch (e) {
      setFetchError(true);
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, companyId]);

  const fetchAllFactoringCompanies = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/factoring-companies`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setFactoringCompanies(data);
    } catch (e) {
      setFetchError(true);
    }
  }, [accessToken]);

  const fetchBillingContacts = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setBillingContacts(data);
    } catch (e) {
      setFetchError(true);
    }
  }, [accessToken, companyId]);

  useEffect(() => {
    fetchRemitToInfo();
    fetchAllFactoringCompanies();
    fetchBillingContacts();
  }, [fetchRemitToInfo, fetchAllFactoringCompanies, fetchBillingContacts]);

  const editSaveButtonProps = {
    variant: 'contained',
    size: 'small',
    'data-testid': 'edit-button',
    style: { margin: '0' },
  };

  return isEditing ? (
    <RemitToInfoForm
      remitToInfo={remitToInfo}
      setIsEditing={setIsEditing}
      setRemitToInfo={setRemitToInfo}
      accessToken={accessToken}
      carrierCompanyId={companyId}
      factoringCompanies={factoringCompanies}
      billingContacts={billingContacts}
      success={success}
      error={error}
    />
  ) : (
    <Card>
      <CardHeader
        title="Remit To Info"
        action={
          <PrivateComponent reqScope="carrier:update">
            <Button
              {...editSaveButtonProps}
              color="primary"
              onClick={() => setIsEditing(true)}
            >
              EDIT
            </Button>
          </PrivateComponent>
        }
      />
      {isLoading && <FullCardLoader />}
      {fetchError ? (
        <Box className={classes.error}>{GENERIC_ERROR.EN}</Box>
      ) : (
        <CardContent>
          <Grid container spacing={2}>
            <Grid container item xs={4} direction="column">
              <TextField
                disabled
                label="Billing Contact"
                name="billingContactName"
                InputLabelProps={{ shrink: true }}
                value={remitToInfo.billingContactName || '-'}
              />
              <TextField
                disabled
                label="Email"
                InputLabelProps={{ shrink: true }}
                value={remitToInfo.billingContactEmail || '-'}
              />
              <Grid container item>
                <Grid item xs={9}>
                  <TextField
                    disabled
                    label="Office Phone"
                    InputLabelProps={{ shrink: true }}
                    value={remitToInfo.billingContactPhone || '-'}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    label="Ext."
                    InputLabelProps={{ shrink: true }}
                    value={remitToInfo.billingContactPhoneExt || '-'}
                  />
                </Grid>
              </Grid>
              <TextField
                disabled
                label="Cell Phone"
                InputLabelProps={{ shrink: true }}
                value={remitToInfo.billingContactMobile || '-'}
              />
            </Grid>
            <Grid container item xs={4} spacing={2} direction="column">
              <FormControlLabel
                control={
                  <Switch disableRipple checked={remitToInfo.payToFactor} />
                }
                label={
                  <Typography className={classes.formLabelTypography}>
                    Pay To Factor
                  </Typography>
                }
                labelPlacement="top"
                className={classes.formLabel}
              />
              <TextField
                disabled
                label="Payment Method"
                value={
                  remitToInfo.paymentMethodId
                    ? paymentMethodOptions[remitToInfo.paymentMethodId - 1]
                        .label
                    : ''
                }
              />
              <TextField
                disabled
                label="Payment Terms"
                value={
                  remitToInfo.paymentTermsId
                    ? paymentTermsOptions[remitToInfo.paymentTermsId - 1].label
                    : ''
                }
              />
            </Grid>
            {remitToInfo.payToFactor && (
              <Grid container item xs={4} direction="column">
                <TextField
                  disabled
                  label="Pay to Name"
                  InputLabelProps={{ shrink: true }}
                  value={remitToInfo.factoringCompanyName || '-'}
                />
                <Grid container item>
                  <Grid item xs={9}>
                    <TextField
                      disabled
                      label="Office Phone"
                      InputLabelProps={{ shrink: true }}
                      value={remitToInfo.factoringCompanyPhone || '-'}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled
                      label="Ext."
                      InputLabelProps={{ shrink: true }}
                      value={remitToInfo.factoringCompanyPhoneExt || '-'}
                    />
                  </Grid>
                </Grid>
                <TextField
                  disabled
                  label="Email"
                  InputLabelProps={{ shrink: true }}
                  value={remitToInfo.factoringCompanyEmail || '-'}
                />
                <TextField
                  disabled
                  label="Address"
                  InputLabelProps={{ shrink: true }}
                  value={remitToInfo.factoringCompanyAddressOne || '-'}
                />
                <TextField
                  disabled
                  label="Suite/Ind. Park/Col."
                  InputLabelProps={{ shrink: true }}
                  value={remitToInfo.factoringCompanyAddressTwo || '-'}
                />
                <Grid container item>
                  <Grid item xs={4}>
                    <TextField
                      disabled
                      label="City"
                      InputLabelProps={{ shrink: true }}
                      value={remitToInfo.factoringCompanyCity || '-'}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      disabled
                      label="State/Province"
                      InputLabelProps={{ shrink: true }}
                      value={remitToInfo.factoringCompanyState || '-'}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled
                      label="Zip"
                      InputLabelProps={{ shrink: true }}
                      value={remitToInfo.factoringCompanyPostalCode || '-'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

const initialRemitToInfoState = {
  billingContactUserId: 0,
  billingContactName: '-',
  billingContactEmail: '-',
  billingContactPhone: '-',
  billingContactPhoneExt: '-',
  billingContactMobile: '-',
  payToFactor: false,
  factoringCompanyId: 0,
  factoringCompanyName: '-',
  factoringCompanyAddressOne: '-',
  factoringCompanyAddressTwo: '-',
  factoringCompanyCity: '-',
  factoringCompanyState: '-',
  factoringCompanyPostalCode: '-',
  factoringCompanyEmail: '-',
  factoringCompanyPhone: '-',
  factoringCompanyPhoneExt: '-',
};

const useStyles = makeStyles(() => ({
  error: {
    height: '300px',
    textAlign: 'center',
    lineHeight: '300px',
  },
  formLabel: {
    margin: 0,
    alignItems: 'flex-start',
  },
  formLabelTypography: {
    display: 'flex',
    fontSize: '10.5px',
    color: 'rgba(0, 0, 0, 0.38)',
  },
}));

export default RemitToInfo;
