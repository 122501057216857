import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetch = async ({ accessToken, loadId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_LOAD_API}/v1/loads/${loadId}/products`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
};

const fetchProducts = createAsyncThunk('loadOverview/fetchProducts', fetch, {
  condition: ({ accessToken, loadId }, { getState }) => {
    const { loadOverview } = getState();
    const productData = loadOverview.products.data;
    const productIsLoading = loadOverview.products.isLoading;
    if (productData !== null || productIsLoading === true) {
      return false;
    }
  },
});

export const extraReducers = {
  [fetchProducts.fulfilled]: (state, action) => {
    state.products.data = action?.payload;
    state.products.isLoading = false;
  },
  [fetchProducts.pending]: state => {
    state.products.isLoading = true;
  },
  [fetchProducts.rejected]: state => {
    state.products.fetchError = true;
    state.products.isLoading = false;
  },
};

export default fetchProducts;
