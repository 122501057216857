import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetch = async ({ accessToken, loadId }, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_LOAD_API}/v1/loads/${loadId}/border-crossings`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
};

export const shouldDataBeFetched = (_args, { getState }) => {
  const { loadOverview } = getState();
  const { data: borderCrossingData, isLoading } = loadOverview.borderCrossings;
  return !isLoading && borderCrossingData === null;
};

const fetchLoadBorderCrossings = createAsyncThunk(
  'loadOverview/fetchLoadBorderCrossings',
  fetch,
  {
    condition: shouldDataBeFetched,
  }
);

export const extraReducers = {
  [fetchLoadBorderCrossings.fulfilled]: (state, action) => {
    state.borderCrossings.data = action?.payload;
    state.borderCrossings.isLoading = false;
  },
  [fetchLoadBorderCrossings.pending]: state => {
    state.borderCrossings.isLoading = true;
  },
  [fetchLoadBorderCrossings.rejected]: state => {
    state.borderCrossings.fetchError = true;
    state.borderCrossings.isLoading = false;
  },
};

export default fetchLoadBorderCrossings;
