import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NumberFormat from 'react-number-format';

import { useAuth0 } from '@forager/client-utils';
import { DateTime, Table, OverflowTooltip } from '@forager/bits';
import { notifications } from '@forager/constants';

const InsuranceCoverage = () => {
  const { accessToken } = useAuth0();
  const { id: companyId } = useParams();
  const [insuranceCoverageInfo, setInsuranceCoverageInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const { GENERIC_ERROR } = notifications;

  const formatDate = val =>
    val ? (
      <DateTime timestamp={val} isStaticTimestamp customFormat="MM/DD/YYYY" />
    ) : null;

  const fetchCarrierInsuranceCoverage = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/carriers/${companyId}/insurance-coverage`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setInsuranceCoverageInfo(data);
    } catch (error) {
      setFetchError(true);
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, companyId]);

  useEffect(() => {
    fetchCarrierInsuranceCoverage();
  }, [fetchCarrierInsuranceCoverage]);

  const columns = [
    {
      title: 'Type',
      field: 'type',
    },
    {
      title: 'Amount',
      field: 'amount',
      emptyValue: '-',
      // Don't prop check inline table components, applies to all columns here -ET
      // eslint-disable-next-line react/prop-types
      render: ({ amount }) => (
        <NumberFormat
          displayType="text"
          value={amount}
          prefix="$"
          thousandSeparator=","
          decimalScale={0}
          fixedDecimalScale
        />
      ),
    },
    {
      title: 'Policy',
      field: 'policy',
      emptyValue: '-',
    },
    {
      title: 'Underwriter',
      field: 'underwriter',
      emptyValue: '-',
      // eslint-disable-next-line react/prop-types
      render: ({ underwriter }) => (
        <OverflowTooltip
          data-testid="underwriter-tooltip"
          tooltipValue={underwriter}
          displayValue={underwriter}
        />
      ),
    },
    {
      title: 'Start Date',
      field: 'startDate',
      emptyValue: '-',
      render: ({ startDate }) => formatDate(startDate),
    },
    {
      title: 'End Date',
      field: 'endDate',
      emptyValue: '-',
      render: ({ endDate }) => formatDate(endDate),
    },
  ];

  return (
    <Table
      data-testid="insurance-coverage-table"
      title="Insurance Coverage"
      titleVariant="h5"
      data={insuranceCoverageInfo}
      columns={columns}
      isLoading={isLoading}
      errorText={fetchError ? GENERIC_ERROR.EN : ''}
      options={{
        draggable: false,
        filtering: false,
        paging: false,
        search: false,
      }}
    />
  );
};

export default InsuranceCoverage;
