import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { FullCardLoader } from '@forager/bits';

const CreateAccountModal = ({
  isCreateAccountModalOpen,
  handleClose,
  isCreateAccountModalLoading,
  newUserInfo,
  selectedEmail,
  createUserFromContact,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      {...{ open: isCreateAccountModalOpen }}
      data-testid="create-account-modal"
    >
      {isCreateAccountModalLoading && <FullCardLoader />}
      {/*
       * isCreateAccountModalOpen is here so there aren't issues with rendering
       * flashes from the updated state being faster than a rerender
       */}
      {!newUserInfo && isCreateAccountModalOpen ? (
        <Grid container className={classes.modalContent}>
          <Typography variant="h5">
            Are you sure you want to create this user?
          </Typography>
          <Grid className={classes.actionButtons} justify="space-between">
            <Button
              variant="contained"
              color="primary"
              data-testid="create-account-modal-create-button"
              onClick={() => createUserFromContact(selectedEmail)}
            >
              Create
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              data-testid="create-account-modal-cancel-button"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      ) : (
        isCreateAccountModalOpen && (
          <Grid
            container
            className={classes.modalContent}
            justify="center"
            direction="column"
            spacing={2}
          >
            <Grid container item justify="center" alignItems="center">
              <Typography variant="h5">{`The login info has been emailed to the user at ${newUserInfo.toEmail}`}</Typography>
            </Grid>

            <Grid container item justify="center" alignItems="center">
              <Typography>{`They should check for an email from ${newUserInfo.fromEmail}`}</Typography>
            </Grid>
            <Grid className={classes.actionButtons} justify="flex-end">
              <Button variant="contained" color="primary" onClick={handleClose}>
                Close
              </Button>
            </Grid>
          </Grid>
        )
      )}
    </Dialog>
  );
};

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    padding: '20px',
    overflow: 'auto',
  },
  actionButtons: {
    display: 'flex',
    width: '100%',
    marginTop: '20px',
  },
  icon: {
    height: '1em',
    width: '1em',
    margin: 3,
  },
});
CreateAccountModal.propTypes = {
  isCreateAccountModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  isCreateAccountModalLoading: PropTypes.bool,
  newUserInfo: PropTypes.oneOfType([
    PropTypes.shape({
      email: PropTypes.string,
      password: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  selectedEmail: PropTypes.string,
  createUserFromContact: PropTypes.func,
};
export default CreateAccountModal;
