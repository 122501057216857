import * as Yup from 'yup';

const CompanyInformationValidation = additionalSchema => {
  const baseSchema = Yup.object().shape({
    legalName: Yup.string()
      .min(2, 'Should be at least 2 characters long')
      .max(80, 'Should be no more than 80 characters')
      .required('This field is required')
      .trim(),
    dbaName: Yup.string()
      .min(2, 'Should be at least 2 characters long')
      .max(80, 'Should be no more than 80 characters')
      .nullable()
      .trim(),
  });

  return additionalSchema ? baseSchema.concat(additionalSchema) : baseSchema;
};

export default CompanyInformationValidation;
