import { Card, Modal } from '@material-ui/core';
import { withFormik } from 'formik';
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useAuth0 } from '@forager/client-utils';
import FullAddressForm from './FullAddressForm';
import NewAddressForm from './NewAddressForm';
import NotFoundButton from './NotFoundButton';

const formInitialValues = {
  selectedAddress: null,
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: '',
  postalCode: '',
};

const FullAddressInput = ({
  onAddressComplete,
  values,
  setFieldValue,
  resetForm,
  forwardedRef,
  noOptionsMsg,
  required,
  countries,
  ...formikBag
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState(1);

  const { accessToken } = useAuth0();

  const classes = useStyles();

  const toggleOpen = () => setIsOpen(!isOpen);

  const validateCity = async addressObj => {
    const { city, state, country } = addressObj;
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_ADDRESS_API}/v1/cities`,
        { city, state, country },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const cityId = data.id;
      setFieldValue('cityId', cityId);
      onAddressComplete({ cityId, ...addressObj });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const { city, state, address1, country } = values;
    if (city && state && address1 && country) {
      const addressObj = { ...values };
      // updates the "selectedAddress" SelectDropdown in FullAddressForm so that it displays an "address1" (for custom addresses added via NewAddressForm)
      setFieldValue('selectedAddress.address1', values.address1);
      // Removes the temporary address values used in the selectors before sending the complete address to the parent
      delete addressObj.selectedAddress;
      if (!values.cityId) {
        validateCity(addressObj);
      } else {
        onAddressComplete(addressObj);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.city, values.state, values.address1, values.country]);

  useEffect(() => {
    if (values.address2 || values.postalCode) {
      const { selectedAddress, ...restValues } = values;
      onAddressComplete({ ...restValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.address2, values.postalCode]); // only want this to run when address2 or postalCode changes

  const updateKey = () => {
    setKey(key + 1);
    resetForm();
  };

  React.useImperativeHandle(forwardedRef, () => ({
    clear: () => updateKey(),
  }));

  return (
    <div>
      <FullAddressForm
        {...formikBag}
        required={required}
        values={values}
        notFoundOption={
          // TODO: Update addressPut in address service to account for custom addresses, currently disabling the ability to add a custom address using noOptionsMsg
          noOptionsMsg || <NotFoundButton toggleOpen={toggleOpen} />
        }
        onAddressComplete={onAddressComplete}
        initialValues={formInitialValues}
        countries={countries}
      />
      <Modal open={isOpen} onClose={toggleOpen} className={classes.modal}>
        <Card>
          <NewAddressForm
            {...formikBag}
            values={values}
            onAddressComplete={onAddressComplete}
            initialValues={formInitialValues}
            close={toggleOpen}
            countries={countries}
          />
        </Card>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

FullAddressInput.propTypes = {
  onAddressComplete: PropTypes.func,
  values: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    cityId: PropTypes.number,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    selectedAddress: PropTypes.object,
  }),
  setFieldValue: PropTypes.func,
  resetForm: PropTypes.func,
  // a ref can be several prop types
  // eslint-disable-next-line react/forbid-prop-types
  forwardedRef: PropTypes.any,
  noOptionsMsg: PropTypes.string,
  required: PropTypes.bool,
  countries: PropTypes.string,
};

FullAddressInput.defaultProps = {
  onAddressComplete: () => {},
  values: {},
  setFieldValue: () => {},
  resetForm: () => {},
  noOptionsMsg: '',
};

const WithFormik = withFormik({
  mapPropsToValues: props => props.initialValues || formInitialValues,
  enableReinitialize: true,
})(FullAddressInput);

export default forwardRef((props, ref) => (
  <WithFormik {...props} forwardedRef={ref} />
));
