import {
  GpsFixed as ActiveLoadsIcon,
  CheckCircleOutlineOutlined as PastLoadsIcon,
  BarChart as RatesIcon,
  CompareArrows as ContractedLanesIcon,
  PeopleOutline as CustomersIcon,
  Apartment as FacilitiesIcon,
  InsertDriveFileOutlined as CustomsBrokersIcon,
  LocalShipping as CarrierIcon,
  AccountBalance as FactoringCompaniesIcon,
  Work as VendorIcon,
  BugReport as BugReportIcon,
  StoreMallDirectory as MarketplaceIcon,
  CreditCard as ChargesIcon,
  NewReleases,
} from '@material-ui/icons';
import { ChartPieIcon } from '../../components/Icons/ChartPieIcon';

export default [
  {
    items: [
      // {
      //   title: 'Active Loads',
      //   href: '/active-loads',
      //   icon: ActiveLoadsIcon,
      // },
      // {
      //   title: 'Past Loads',
      //   href: '/past-loads',
      //   icon: PastLoadsIcon,
      // },
      // {
      //   title: 'Marketplace',
      //   icon: MarketplaceIcon,
      //   items: [
      //     {
      //       title: 'Marketplace Loads',
      //       href: '/marketplace-loads',
      //     },
      //     {
      //       title: 'Lane Bids',
      //       href: '/lane-bids',
      //     },
      //   ],
      // },
      // {
      //   title: 'Rates',
      //   icon: RatesIcon,
      //   items: [
      //     {
      //       title: 'Bulk Rates',
      //       href: '/bulk-uploader',
      //       reqScope: 'bulk-rates:read',
      //     },
      //     {
      //       title: 'Export Rates',
      //       href: '/export-rates',
      //       reqScope: 'bulk-rates:read',
      //     },
      //   ],
      // },
      // {
      //   title: 'Contracted Lanes',
      //   href: '/contracted-lanes',
      //   icon: ContractedLanesIcon,
      // },
      // {
      //   title: 'Customers',
      //   icon: CustomersIcon,
      //   items: [
      //     {
      //       title: 'All Customers',
      //       href: '/customers',
      //     },
      //     {
      //       title: 'Add New',
      //       href: '/customers/create',
      //       reqScope: 'customer:create',
      //     },
      //   ],
      // },
      // {
      //   title: 'Facilities',
      //   icon: FacilitiesIcon,
      //   items: [
      //     {
      //       title: 'All Facilities',
      //       href: '/facilities',
      //     },
      //     {
      //       title: 'Add New',
      //       href: '/facilities/create',
      //       reqScope: 'facility:create',
      //     },
      //   ],
      // },
      {
        title: 'Customs Brokers',
        icon: CustomsBrokersIcon,
        items: [
          {
            title: 'All Customs Brokers',
            href: '/customs-brokers',
          },
          {
            title: 'Add New',
            href: '/customs-brokers/create',
            reqScope: 'chb:create',
          },
        ],
      },
      {
        title: 'Carriers',
        icon: CarrierIcon,
        items: [
          {
            title: 'All Carriers',
            href: '/carriers',
          },
          // {
          //   title: 'Add USA/CAN Carrier',
          //   href: '/carriers/create/usa-can',
          // },
          {
            title: 'Add MEX Carrier',
            href: '/carriers/create/mex',
          },
          {
            title: 'Compliance',
            href: '/carriers/compliance',
            reqScope: 'carrier-compliance:update',
          },
        ],
      },
      // {
      //   title: 'Factoring Companies',
      //   icon: FactoringCompaniesIcon,
      //   items: [
      //     {
      //       title: 'All Factoring Companies',
      //       href: '/factoring-companies',
      //     },
      //     {
      //       title: 'Add New',
      //       href: '/factoring-companies/create',
      //     },
      //   ],
      // },
      // {
      //   title: 'Vendors',
      //   icon: VendorIcon,
      //   items: [
      //     {
      //       title: 'All Vendors',
      //       href: '/vendors',
      //     },
      //     {
      //       title: 'Add New',
      //       href: '/vendors/create',
      //     },
      //   ],
      // },
      // {
      //   title: 'Report an Issue',
      //   icon: BugReportIcon,
      //   href: '/report/bug',
      // },
      // {
      //   title: 'Dashboard',
      //   href: '/dashboard',
      //   icon: ChartPieIcon,
      //   badge: NewReleases,
      // },
    ],
  },
];
