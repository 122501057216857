import React, { useRef, useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useField } from 'formik';
import { paperworkDeliveryMethodOptions } from '@forager/constants';

const PaperworkDeliveryMethodDropdown = ({ fieldName, errors }) => {
  const classes = useStyles();
  const paperworkInputRef = useRef(null);
  const [paperworkLabelWidth, setPaperworkLabelWidth] = useState(0);
  const [field] = useField({ name: fieldName });

  useEffect(() => {
    setPaperworkLabelWidth(paperworkInputRef.current.offsetWidth);
  }, []);

  return (
    <FormControl className={classes.text} variant="outlined" required>
      <InputLabel ref={paperworkInputRef} id="paperwork-delivery-method-label">
        Paperwork Delivery Method
      </InputLabel>
      <Select
        labelWidth={paperworkLabelWidth}
        data-testid="paperwork-delivery-select"
        labelId="paperwork-delivery-method-label"
        {...field}
      >
        {paperworkDeliveryMethodOptions.map(({ ID, TEST_ID, NAME }) => (
          <MenuItem key={ID} data-testid={TEST_ID} value={ID}>
            {NAME}
          </MenuItem>
        ))}
      </Select>
      {errors.customsBrokerPaperworkDeliveryMethodId && (
        <FormHelperText data-testid="paperwork-delivery-error" error>
          {errors.customsBrokerPaperworkDeliveryMethodId}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles({
  text: {
    width: '100%',
  },
});

PaperworkDeliveryMethodDropdown.propTypes = {
  fieldName: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    customsBrokerPaperworkDeliveryMethodId: PropTypes.string,
  }),
};

export default PaperworkDeliveryMethodDropdown;
