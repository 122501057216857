import axios from 'axios';
import { convertEmptyStringsToNull } from '@forager/client-utils';
import { notifications } from '@forager/constants';

const { ERROR, SUCCESS } = notifications;

const updateContact = async ({
  existingContact,
  companyId,
  setContacts,
  setIsContactModalOpen,
  setContactModalLoading,
  error,
  success,
  accessToken,
  contacts,
}) => {
  setContactModalLoading(true);
  try {
    const payload = convertEmptyStringsToNull(existingContact);
    const { userId } = existingContact;
    existingContact.companyId = companyId;
    const { data: updatedUser } = await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/users/${userId}`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    // set table ui with newly updated contact
    // if hasMain then if in updated user main is true then set everyone to false
    const {
      isMain: isUpdatedUserMain = false,
      isLoadOfferContact: isUpdatedUserLoadOfferContact = false,
    } = updatedUser;

    // Check if a new main has been set and set all users to not be main as well as if user is load offer contact
    const updatedContactList = contacts.reduce(
      (acc, contact) => {
        if (contact.userId === updatedUser.userId) return acc;
        let tempContact = { ...contact };
        if (isUpdatedUserMain) tempContact = { ...tempContact, isMain: false };
        if (isUpdatedUserLoadOfferContact)
          tempContact = { ...tempContact, isLoadOfferContact: false };

        acc.push(tempContact);
        return acc;
      },
      [updatedUser]
    );

    setContacts(updatedContactList);
    success(SUCCESS.EN);
  } catch (err) {
    error(ERROR.EN);
  } finally {
    setIsContactModalOpen(false);
    setContactModalLoading(false);
  }
};

export default updateContact;
