import axios from 'axios';
import { notifications } from '@forager/constants';

const { ERROR, SUCCESS } = notifications;

const handleDeleteContact = async ({
  contactToBeDeleted,
  setReplacementContactUserId,
  setContactModalLoading,
  success,
  error,
  setIsContactModalOpen,
  setContacts,
  contacts,
  companyId,
  companyTypeId,
  replacementContactUserId,
  accessToken,
}) => {
  setReplacementContactUserId(0);
  setContactModalLoading(true);

  try {
    const { userId, isLoadOfferContact = false } = contactToBeDeleted;

    await axios.delete(`${process.env.REACT_APP_API_URL}/v1/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        replacementContactUserId,
        companyTypeId,
        isLoadOfferContact,
        companyId,
      },
    });

    const newContacts = contacts.filter(
      tempContact => tempContact.userId !== contactToBeDeleted.userId
    );
    setContacts(newContacts);
    success(SUCCESS.EN);
  } catch (err) {
    error(ERROR.EN);
  } finally {
    setIsContactModalOpen(false);
    setContactModalLoading(false);
  }
};

export default handleDeleteContact;
