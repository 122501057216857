import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import { FormTextField, FullCardLoader } from '@forager/bits';
import { labels } from '@forager/constants';
import submit from './formik/submitHandler';
import CompanyProfileHeaderAndButtons from '../../../CompanyProfileHeaderAndButtons';
import CompanyInformationValidation from './formik/validations';
import initialValues from './formik/initialValues';

export const CompanyInformationForm = ({
  isEditingProfile,
  values,
  setIsEditingProfile,
  handleSubmit,
  isSubmitting,
  isLoading,
  editable,
  additionalFields,
  companyTypeId,
}) => {
  const classes = useStyles();
  const sharedFieldProps = {
    disabled: !isEditingProfile,
    InputProps: { disableUnderline: true },
    InputLabelProps: { shrink: true },
    fullWidth: true,
  };

  return (
    <Grid
      container
      className={classes.cardContainer}
      spacing={2}
      data-testid="company-information-form-container"
    >
      <CompanyProfileHeaderAndButtons
        isEditingProfile={isEditingProfile}
        setIsEditingProfile={setIsEditingProfile}
        handleSubmit={handleSubmit}
        companyInfoTitle="Company Info"
        editable={editable}
      />
      <Grid item container spacing={2}>
        <Grid item container spacing={2} direction="column" xs={12} sm={6}>
          {(isLoading || isSubmitting) && <FullCardLoader />}
          <Grid item>
            {!isEditingProfile ? (
              <TextField
                required
                data-testid="legal-name"
                label="Legal Name"
                name="legalName"
                value={values.legalName}
                className={classes.input}
                {...sharedFieldProps}
              />
            ) : (
              <FormTextField
                required
                data-testid="legal-name-input"
                label="Legal Name"
                name="legalName"
                {...sharedFieldProps}
              />
            )}
          </Grid>

          <Grid item>
            {!isEditingProfile ? (
              <TextField
                {...sharedFieldProps}
                data-testid="dba-name"
                label="DBA Name"
                name="dbaName"
                value={values.dbaName || '-'}
              />
            ) : (
              <FormTextField
                data-testid="dba-name-input"
                label="DBA Name"
                name="dbaName"
                {...sharedFieldProps}
              />
            )}
          </Grid>
          <>
            <Grid item>
              <TextField
                {...sharedFieldProps}
                disabled
                data-testid="address1"
                label={labels.STREET_ADDRESS.EN}
                name="address1"
                value={values.address1}
              />
            </Grid>
            <Grid item>
              <TextField
                {...sharedFieldProps}
                disabled
                data-testid="address2"
                label={labels.APT_OR_SUITE.EN}
                name="address2"
                value={values.address2 || '-'}
              />
            </Grid>
            <Grid item>
              <TextField
                {...sharedFieldProps}
                disabled
                data-testid="cityName"
                label={labels.CITY.EN}
                name="cityName"
                value={values.cityName}
              />
            </Grid>
            <Grid container item>
              <Grid item xs={6}>
                <TextField
                  {...sharedFieldProps}
                  disabled
                  data-testid="stateShortName"
                  label={labels.STATE_OR_PROVINCE.EN}
                  name="stateShortName"
                  value={values.stateShortName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...sharedFieldProps}
                  disabled
                  data-testid="postalCode"
                  label={labels.POSTAL_CODE.EN}
                  name="postalCode"
                  value={values.postalCode}
                />
              </Grid>
            </Grid>
          </>
        </Grid>
        {!!additionalFields && (
          <Grid container item xs={12} sm={6} direction="column" spacing={2}>
            {additionalFields({ isEditingProfile, companyTypeId })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    paddingBottom: '15px',
    position: 'relative',
  },
  input: {
    width: '100%',
  },
});

CompanyInformationForm.propTypes = {
  values: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    addressId: PropTypes.number,
    cityName: PropTypes.string,
    dbaName: PropTypes.string,
    legalName: PropTypes.string,
    postalCode: PropTypes.string,
    stateId: PropTypes.number,
    stateLongName: PropTypes.string,
    stateShortName: PropTypes.string,
  }),
  isEditingProfile: PropTypes.bool,
  setIsEditingProfile: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  editable: PropTypes.bool,
  additionalFields: PropTypes.func,
  companyTypeId: PropTypes.number,
};

export default withFormik({
  mapPropsToValues: ({ companyMeta }) => ({ ...initialValues, ...companyMeta }),
  handleSubmit: submit,
  enableReinitialize: true,
  validationSchema: ({ additionalSchema = null }) =>
    CompanyInformationValidation(additionalSchema),
  validateOnBlur: false,
  validateOnChange: false,
})(CompanyInformationForm);
