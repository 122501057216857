import React, { useState, useEffect, useCallback } from 'react';
import { CardContent } from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useForagerNotification } from '@forager/client-utils';
import CustomsBrokerInformationForm from './CustomsBrokerInformationForm';

export const CustomsBrokerInformation = ({ accessToken, companyId }) => {
  const [fetchError, setFetchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [customsBrokerData, setCustomsBrokerData] = useState({});
  const { error } = useForagerNotification();

  const getCustomsBrokerData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/customs-brokers/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setCustomsBrokerData(data);
    } catch (err) {
      setFetchError(true);
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, companyId]);
  useEffect(() => {
    getCustomsBrokerData();
  }, [getCustomsBrokerData]);

  return (
    <CardContent data-testid="container">
      <CustomsBrokerInformationForm
        isEditingProfile={isEditingProfile}
        setIsEditingProfile={setIsEditingProfile}
        customsBrokerData={customsBrokerData}
        setCustomsBrokerData={setCustomsBrokerData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        fetchError={fetchError}
        accessToken={accessToken}
        companyId={companyId}
        error={error}
      />
    </CardContent>
  );
};

CustomsBrokerInformation.propTypes = {
  accessToken: PropTypes.string,
  companyId: PropTypes.string,
};

export default CustomsBrokerInformation;
