import axios from 'axios';
import { notifications } from '@forager/constants';

export const submit = async (values, { props }) => {
  const { setIsLoading, success, error, accessToken, history } = props;

  const { SUCCESS, ERROR } = notifications;

  setIsLoading(true);

  try {
    const addressPayload = {
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      country: values.country,
      postalCode: values.postalCode,
    };

    const {
      data: { id: addressId },
    } = await axios.post(
      `${process.env.REACT_APP_ADDRESS_API}/v1/addresses`,
      addressPayload,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    const payload = {
      addressId,
      email: values.email,
      countryServicedId: values.countryServicedId,
      customsBrokerPaperworkDeliveryMethodId:
        values.customsBrokerPaperworkDeliveryMethodId,
      dbaName: values.dbaName || null,
      firstName: values.firstName,
      hasOvertimeAvailable: values.hasOvertimeAvailable,
      hasCTPATCertification: values.hasCTPATCertification,
      lastName: values.lastName || null,
      legalName: values.legalName,
      mobile: values.mobile || null,
      phone: values.phone || null,
      phoneExt: values.phoneExt || null,
      title: values.title || null,
    };

    await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/customs-brokers`,
      payload,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    setIsLoading(false);
    success(SUCCESS.EN);
    history.push('/customs-brokers');
  } catch (err) {
    setIsLoading(false);
    error(ERROR.EN);
  }
};
