import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/main.scss';
import * as Sentry from '@sentry/browser';
import { renderRoutes } from 'react-router-config';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from '@forager/mui-theme';
import { LinearProgress } from '@material-ui/core';
import {
  history,
  useAuth0,
  ForagerNotificationProvider,
  Heap,
} from '@forager/client-utils';
import ScrollReset from './components/ScrollReset';
import GoogleAnalytics from './components/GoogleAnalytics';

import GlobalErrorCatcher from './utils/GlobalErrorCatcher';
import RoutesConfig from './utils/RoutesConfig';
import CacheBuster from './utils/CacheBuster';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  environment: `${process.env.REACT_APP_ENVIRONMENT}-client`,
});

const App = () => {
  const { loading, user } = useAuth0();

  if (loading) {
    return <LinearProgress />;
  }

  return (
    // Material UI Theme Wrapper - Matt Weber
    <ThemeProvider theme={theme}>
      {/* Provides MomentJS to any Material Date/time pickers - Matt Weber */}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {/* Set Up Browser History - Matt Weber */}
        <Router history={history}>
          <GlobalErrorCatcher user={user}>
            <ForagerNotificationProvider>
              {/* Scroll User top of page on path change - Matt Weber */}
              <ScrollReset />
              {/* Add Google Analytics - Matt Weber */}
              <GoogleAnalytics />
              {/* Add Heap Analytics - Chris Fotso*/}
              {(process.env.REACT_APP_ENVIRONMENT === 'staging' ||
                process.env.REACT_APP_ENVIRONMENT === 'production') &&
                user && (
                  <Heap
                    appId={process.env.REACT_APP_HEAP_ID}
                    userId={user.email}
                    userData={{ name: user.name, email: user.email }}
                  />
                )}
              {/* Render Routes - See Below Link - Matt Weber
            https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
            */}
              {/* Checks the meta.json file to see if it matches, if not then hard refresh */}
              <CacheBuster>
                {({ cacheLoading, isLatestVersion, refreshCacheAndReload }) => {
                  if (cacheLoading) return null;
                  if (!cacheLoading && !isLatestVersion) {
                    refreshCacheAndReload();
                  }

                  return loading ? (
                    <LinearProgress />
                  ) : (
                    // Render Routes - https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
                    renderRoutes(RoutesConfig)
                  );
                }}
              </CacheBuster>
            </ForagerNotificationProvider>
          </GlobalErrorCatcher>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
