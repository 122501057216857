import { Input as InputIcon, Menu as MenuIcon } from '@material-ui/icons';
import { AppBar, Button, IconButton, Toolbar, Hidden } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useAuth0, useScopes } from '@forager/client-utils';
import { ProductionWarning } from '@forager/bits';

import logo from '../../assets/img/scout-white.svg';
import TopBarSearch from './TopBarSearch';

const TopBar = ({ onOpenNavBarMobile, className, ...rest }) => {
  const classes = useStyles();
  const { logout } = useAuth0();
  const { hasScopes: userRequiresProductionWarning } =
    useScopes('warning:production');
  const renderProductionWarning =
    process.env.REACT_APP_ENVIRONMENT === 'production' &&
    userRequiresProductionWarning;

  const handleLogout = () => {
    logout();
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      {renderProductionWarning && <ProductionWarning />}
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          onClick={onOpenNavBarMobile}
          data-testid="open-nav-button"
        >
          <MenuIcon />
        </IconButton>
        <RouterLink to="/">
          <img alt="Logo" src={logo} height={60} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden smDown>
          <TopBarSearch />
        </Hidden>
        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon} />
          Sign out
        </Button>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#101A43',
  },
  flexGrow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  announceButton: {
    marginTop: '2px',
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
}));

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
