// file types for uploads in LoadFile and Carrier File upload forms
export const fileTypes = [
  'jpeg',
  'jpg',
  'pdf',
  'png',
  'xls',
  'xlsx',
  'csv',
  'msg',
  'eml',
];

export const fileTypesDisplay = fileTypes
  // removing all long form names, redundant for display purposes
  .filter(file => file.length === 3)
  .reduce((string, file, i, arr) => {
    let newString = string;
    if (i === arr.length - 1) {
      newString += `or ${file.toUpperCase()}`;
    } else {
      newString += `${file.toUpperCase()}, `;
    }
    return newString;
  }, '');
export default fileTypes;
