import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useAuth0, useForagerNotification } from '@forager/client-utils';
import { notifications } from '@forager/constants';
import { Typography, makeStyles } from '@material-ui/core';

const ComplianceFileLink = ({ applicationId, fileId }) => {
  const { accessToken } = useAuth0();
  const { error } = useForagerNotification();
  const { ERROR } = notifications;
  const classes = useStyles();

  const handleFileActionClick = async () => {
    try {
      const {
        data: { signedUrl },
      } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/carrierApplications/${applicationId}/files/${fileId}/downloadUrl?usage=view`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const a = document.createElement('a');
      a.href = signedUrl;
      // If we're viewing the file we'd want for it to open in a new window
      a.setAttribute('target', '_blank');
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      error(ERROR.EN);
    }
  };

  return (
    <Typography
      data-testid="link"
      color="secondary"
      className={classes.link}
      onClick={handleFileActionClick}
    >
      View Document
    </Typography>
  );
};

const useStyles = makeStyles({
  link: {
    paddingLeft: '33px',
    marginTop: '-10px', // used to override the position since the checkbox has built in bottom margin
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

ComplianceFileLink.propTypes = {
  applicationId: PropTypes.number,
  fileId: PropTypes.number,
};

export default ComplianceFileLink;
