import React from 'react';
import ReactDOM from 'react-dom';
import { history, Auth0Provider } from '@forager/client-utils';
import { Provider as ReduxProvider } from 'react-redux';
import * as serviceWorker from './utils/serviceWorker';
import store from './redux/store';

import App from './App';
import 'react-widgets/styles.css';

require('dotenv').config();

// A function that routes the user to the right place
// after login
const onRedirectCallback = () => {
  const loginRedirectUrl = window.localStorage.getItem('loginRedirectUrl');
  history.push(loginRedirectUrl ? loginRedirectUrl : window.location.pathname);
  window.localStorage.removeItem('loginRedirectUrl');
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
    audience={process.env.REACT_APP_AUTH_AUD}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <ReduxProvider store={store}>
      <App history={history} />
    </ReduxProvider>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
