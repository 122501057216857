import React from 'react';
import { withFormik } from 'formik';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { carrierFileTypes, notifications } from '@forager/constants';
import PropTypes from 'prop-types';

import {
  makeStyles,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import {
  HappyButton,
  SadButton,
  FormTextField,
  DateTimePicker,
} from '@forager/bits';
import { useScopes } from '@forager/client-utils';
import { Backup as BackupIcon, Cancel as CancelIcon } from '@material-ui/icons';
import fileSchema, { fileTypes } from './fileSchema';

const FileUploadForm = ({
  setFieldValue,
  resetForm,
  setFileUploadModalOpen,
  errors,
  values,
  handleSubmit,
}) => {
  const { dropZone, icon } = useStyles();
  const { hasScopes: isLowAdminOrSuperAdmin } = useScopes([
    'customer:create',
    'forager-portal:read',
  ]);

  const handleFileTypeSelect = e => {
    setFieldValue('carrierFileTypeId', e.target.value);
    setFieldValue('sctNumber', null);
  };
  const handleFileSelect = selectedFile => {
    setFieldValue('file', selectedFile[0]);
  };

  const handleCancelClick = () => {
    resetForm();
    setFileUploadModalOpen(false);
  };

  const handleCancelFileClick = () => {
    setFieldValue('file', null);
  };

  const availableCarrierFileTypes = carrierFileTypes.filter(({ ID }) =>
    // Only want to show SCT in the dropdown if they're a low admin or super admin
    !isLowAdminOrSuperAdmin ? ID !== 5 : true
  );

  return (
    <Grid container spacing={2} data-testid="carrier-file-form">
      <Grid item xs={12}>
        <Dropzone
          accept={`.${fileTypes.join(', .')}`}
          onDrop={selectedFile => handleFileSelect(selectedFile)}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={dropZone}
              data-testid="carrier-file-dropzone"
              style={{ minHeight: 150 }}
            >
              <input data-testid="carrier-file-input" {...getInputProps()} />
              <Typography align="center" variant="h4">
                <BackupIcon className={icon} fontSize="large" />
              </Typography>
              <Typography align="center">
                Drop a file ({fileTypes.join(', ')}) here or browse to upload
              </Typography>
            </div>
          )}
        </Dropzone>
        <FormHelperText style={{ marginLeft: '14px' }} error>
          {errors.file}
        </FormHelperText>
      </Grid>
      <Grid item xs={12} container>
        {values.file && (
          <Grid item xs={12} container justify="space-between">
            <Typography variant="h5" data-testid="carrier-file-name">
              {values.file?.name}
            </Typography>

            <IconButton
              data-testid="carrier-file-delete"
              size="small"
              onClick={handleCancelFileClick}
            >
              <CancelIcon className={icon} />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel id="file-type-label" variant="outlined">
            Select File Type
          </InputLabel>
          <Select
            data-testid="carrier-file-type"
            labelId="file-type-label"
            value={values.carrierFileTypeId || ''}
            onChange={handleFileTypeSelect}
            label="Select File Type"
          >
            {availableCarrierFileTypes.map(({ ID, FILE_TYPE }) => (
              <MenuItem data-testid={`file-type-${ID}`} key={ID} value={ID}>
                {FILE_TYPE}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>{errors.carrierFileTypeId}</FormHelperText>
        </FormControl>
      </Grid>
      {values.carrierFileTypeId === 7 && (
        <Grid item>
          <DateTimePicker
            fullWidth
            required
            type="date"
            name="polizaExpireDate"
            data-testid="poliza-expire-date"
            label="Poliza Expire Date"
            disablePast
            isStaticTimestamp
          />
        </Grid>
      )}
      {values.carrierFileTypeId === 5 && (
        <Grid item>
          <FormTextField
            label="SCT Number"
            required
            name="sctNumber"
            inputProps={{
              'data-testid': 'carrier-sct-number',
              maxLength: 40,
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormTextField
          fullWidth
          label="Description"
          multiline
          rows={4}
          rowsMax={4}
          name="fileDescription"
          inputProps={{
            'data-testid': 'carrier-file-description',
          }}
        />
      </Grid>
      <Grid item xs={12} container justify="flex-end">
        <SadButton data-testid="cancel-button" onClick={handleCancelClick}>
          CANCEL
        </SadButton>
        <HappyButton
          data-testid="carrier-file-modal-submit-button"
          type="submit"
          onClick={handleSubmit}
        >
          UPLOAD
        </HappyButton>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
  },
  dropZone: {
    background: theme.palette.background.default,
    height: '100%',
    borderRadius: 5,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: '.3s',
    '&:hover': {
      background: theme.palette.divider,
      transition: '.3s',
    },
  },
}));

export const submit = async (values, { props }) => {
  const {
    carrierCompanyId,
    accessToken,
    setIsLoading,
    setFileUploadModalOpen,
    errorNotifaction,
    setFiles,
    files,
  } = props;
  const {
    file,
    carrierFileTypeId,
    fileDescription,
    polizaExpireDate,
    sctNumber,
  } = values;

  const { ERROR } = notifications;
  try {
    setIsLoading(true);
    // Need to submit as multipart/form-data for api middleware to access the file
    const formData = new FormData();
    formData.append('file', file);
    formData.append('carrierFileTypeId', carrierFileTypeId);
    formData.append('fileDescription', fileDescription);

    if (polizaExpireDate) {
      formData.append('expirationDate', polizaExpireDate);
    }

    if (sctNumber) {
      formData.append('sctNumber', sctNumber);
    }

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/carriers/${carrierCompanyId}/files`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const newFiles = [data, ...files];
    setFiles(newFiles);
    setIsLoading(false);
    setFileUploadModalOpen(false);
  } catch (err) {
    errorNotifaction(ERROR.EN);
    setIsLoading(false);
  }
};

FileUploadForm.propTypes = {
  setFieldValue: PropTypes.func,
  resetForm: PropTypes.func,
  setFileUploadModalOpen: PropTypes.func,
  errors: PropTypes.shape({
    carrierFileTypeId: PropTypes.string,
    polizaExpireDate: PropTypes.string,
  }),
  values: PropTypes.shape({
    carrierFileTypeId: PropTypes.number,
    fileDescription: PropTypes.string,
    file: PropTypes.string,
    polizaExpireDate: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
};

const initialValues = {
  carrierFileTypeId: null,
  fileDescription: '',
  file: null,
  polizaExpireDate: null,
  sctNumber: null,
};

export default withFormik({
  mapPropsToValues: () => initialValues,
  handleSubmit: submit,
  validationSchema: fileSchema,
  validateOnBlur: false,
  validateOnChange: false,
})(FileUploadForm);
