import React from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import {
  EmailInput,
  FormTextField,
  PhoneInput,
  FullCardLoader,
  // SelectDropdown,
} from '@forager/bits';
import {
  makeStyles,
  Grid,
  Button,
  CardHeader,
  Divider,
} from '@material-ui/core';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import {
  labels,
  //  carrierReps,
  notifications,
} from '@forager/constants';
import { validatePhone, hasPhoneOrMobile } from '@forager/client-utils';
import { Link as RouterLink } from 'react-router-dom';

import FullAddressInput from '../../../components/FullAddressInput';

export const AddMexCarrierForm = ({
  errors,
  handleSubmit,
  isSubmitting,
  setValues,
  values,
}) => {
  const classes = useStyles();

  const handleAddress = fullAddress => {
    setValues({ ...values, ...fullAddress });
  };

  return (
    <Grid container xs={12} spacing={2} className={classes.container}>
      {isSubmitting && <FullCardLoader data-testid="loader" />}
      <Grid container xs={12} md={6} item spacing={2} alignContent="flex-start">
        <CardHeader
          title="General Info"
          className={classes.formSectionHeader}
        />
        <Grid item xs={12}>
          <FormTextField
            name="legalName"
            label="Legal Name"
            required
            className={classes.text}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="dbaName"
            label="DBA Name"
            className={classes.text}
          />
        </Grid>
        <Grid item xs={12}>
          <FullAddressInput
            required
            onAddressComplete={handleAddress}
            parentErrors={errors}
            countries="mx"
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="sctNumber"
            label="SCT"
            required
            className={classes.text}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="rfcNumber"
            label="RFC"
            required
            className={classes.text}
          />
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <Grid container xs={12} md={6} item spacing={2} alignContent="flex-start">
        <CardHeader title="Contact" className={classes.formSectionHeader} />
        <Grid item xs={12}>
          <FormTextField
            name="firstName"
            label="First Name"
            required
            className={classes.text}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="lastName"
            label="Last Name"
            required
            className={classes.text}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="title"
            label="Title"
            required
            className={classes.text}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailInput
            name="email"
            label="Email"
            className={classes.text}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            name="phone"
            label={labels.OFFICE_PHONE.EN}
            labelWidth="75px"
            extName="phoneExt"
            extLabel={labels.PHONE_EXT.EN}
            className={classes.text}
            error={errors.phone}
            extInputWidth="100%"
            defaultCountry="MX"
            countries={['MX']}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            className={classes.text}
            error={errors.mobile}
            hideExt
            label={labels.CELL_PHONE.EN}
            labelWidth="65px"
            name="mobile"
            defaultCountry="MX"
            countries={['MX']}
          />
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      {/*
      <Grid container xs={12} md={4} item spacing={2} alignContent="flex-start">
        <CardHeader title="Forager Rep" className={classes.formSectionHeader} />
        <Grid item xs={12} data-testid="carrier-rep-select">
          <SelectDropdown
            required
            label="Carrier Rep"
            name="carrierRepId"
            options={carrierReps}
            optionTemplate={({ NAME }) => NAME}
            optionLabelKey="NAME"
            optionValueKey="USER_ID"
            helperText={errors?.carrierRepId}
            error={errors?.carrierRepId}
          />
        </Grid>
      </Grid>
      */}
      <Grid container xs={12} item justify="flex-end">
        <Grid item>
          <Button
            data-testid="new-cb-btn"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddMexCarrierForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  // values: PropTypes.shape({
  //   carrierRepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // }),
  errors: PropTypes.shape({
    // carrierRepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    phone: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    mobile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

export const initialValues = {
  address1: '',
  address2: '',
  city: '',
  dbaName: '',
  email: '',
  firstName: '',
  lastName: '',
  legalName: '',
  mobile: '',
  carrierRepId: null,
  phone: '',
  phoneExt: '',
  postalCode: '',
  state: '',
  title: '',
  sctNumber: '',
  rfcNumber: '',
};

AddMexCarrierForm.defaultProps = {
  errors: {},
};

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  text: {
    width: '100%',
  },
  formSectionHeader: {
    padding: 'inherit',
    width: '100%',
  },
  divider: {
    margin: theme.spacing(1),
  },
}));

const validationSchema = Yup.object().shape({
  legalName: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(80, 'Should be no more than 80 characters')
    .required('This field is required')
    .trim(),
  dbaName: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(80, 'Should be no more than 80 characters')
    .nullable(),
  address1: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(125, 'Should be no more than 125 characters')
    .required('This field is required'),
  city: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(50, 'Should be no more than 50 characters')
    .required('This field is required'),
  state: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(50, 'Should be no more than 50 characters')
    .required('This field is required'),
  postalCode: Yup.string()
    .min(5, 'Should be at least 5 characters long')
    .max(10, 'Should be no more than 10 characters')
    .required('This field is required'),
  firstName: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(50, 'Should be no more than 50 characters')
    .required('This field is required')
    .trim(),
  lastName: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(50, 'Should be no more than 50 characters')
    .required('This field is required')
    .trim(),
  title: Yup.string()
    .min(2, 'Should be at least 2 characters long')
    .max(50, 'Should be no more than 50 characters')
    .required('This field is required')
    .trim(),
  email: Yup.string().email('Invalid email').required('This field is required'),
  phone: Yup.string()
    .test('phone', 'Must be a valid phone number', function () {
      const { phone } = this.parent;
      return phone ? validatePhone(phone) : true;
    })
    .test(
      'combination',
      'Either an office or mobile phone must be provided',
      hasPhoneOrMobile
    ),
  mobile: Yup.string()
    .test('phone', 'Must be a valid phone number', function () {
      const { mobile } = this.parent;
      return mobile ? validatePhone(mobile) : true;
    })
    .test(
      'combination',
      'Either an office or mobile phone must be provided',
      hasPhoneOrMobile
    ),
  // carrierRepId: Yup.number().required('This field is required'),
  rfcNumber: Yup.string()
    .min(12, 'RFC must be between 12 and 13 characters')
    .max(13, 'RFC must be between 12 and 13 characters')
    .required('RFC is required')
    .trim(),
  sctNumber: Yup.string()
    .max(40, 'SCT cannot exceed 40 characters')
    .required('SCT is required')
    .trim(),
});

export const submit = async (values, { setSubmitting, props }) => {
  const { ERROR } = notifications;
  const { error, accessToken, history } = props;
  const {
    address1,
    address2,
    city,
    cityId,
    country,
    postalCode,
    state,
    fullMatch,
    lat,
    lng,
    mapboxId,
    ...payload
  } = values;

  try {
    const {
      data: { id: addressId },
    } = await axios.post(
      `${process.env.REACT_APP_ADDRESS_API}/v1/addresses`,
      { address1, address2, city, state, country, postalCode },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/carriers/createMexicoCarrierApplication`,
      { addressId, ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    history.push('/carriers/compliance');
  } catch (err) {
    if (err.response.data?.carrierCompanyId) {
      error({
        mainMessage:
          'Sorry, we already have a carrier in the system with that RFC or SCT number',
        subMessage: (
          <Button
            color="primary"
            size="small"
            component={RouterLink}
            to={`/carriers/${err.response.data.carrierCompanyId}`}
            variant="text"
            style={{ margin: 0, padding: 0 }}
          >
            Click here to view it
          </Button>
        ),
      });
    } else {
      error(ERROR.EN);
    }
  } finally {
    setSubmitting(false);
  }
};

export default withFormik({
  mapPropsToValues: () => initialValues,
  handleSubmit: submit,
  validationSchema,
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
})(AddMexCarrierForm);
