import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetch = async ({ accessToken, loadId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_LOAD_API}/v1/loads/${loadId}/location-updates`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
};

const fetchLoadLocationUpdates = createAsyncThunk(
  'loadOverview/fetchLoadLocationUpdates',
  fetch,
  {
    condition: (_args, { getState }) => {
      const { loadOverview } = getState();
      const locationUpdatesState = loadOverview.locationUpdates;
      if (
        locationUpdatesState.isLoading ||
        locationUpdatesState.data !== null
      ) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const extraReducers = {
  [fetchLoadLocationUpdates.fulfilled]: (state, action) => {
    state.locationUpdates.data = action?.payload;
    state.locationUpdates.isLoading = false;
  },
  [fetchLoadLocationUpdates.pending]: state => {
    state.locationUpdates.isLoading = true;
  },
  [fetchLoadLocationUpdates.rejected]: state => {
    state.locationUpdates.fetchError = true;
    state.locationUpdates.isLoading = false;
  },
};

export default fetchLoadLocationUpdates;
