import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetch = async ({ accessToken, loadId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_LOAD_API}/v1/loads/${loadId}/stops`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
};

const fetchLoadStops = createAsyncThunk('loadOverview/fetchLoadStops', fetch);

export const extraReducers = {
  [fetchLoadStops.fulfilled]: (state, action) => {
    state.stops.data = action?.payload;
    state.stops.isLoading = false;
  },
  [fetchLoadStops.pending]: state => {
    state.stops.isLoading = true;
  },
  [fetchLoadStops.rejected]: state => {
    state.stops.fetchError = true;
    state.stops.isLoading = false;
  },
};

export default fetchLoadStops;
