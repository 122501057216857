import React, { useEffect, useState } from 'react';
import { Container, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Table } from '@forager/bits';
import axios from 'axios';
import { stringSortCompare, useAuth0 } from '@forager/client-utils';
import { notifications } from '@forager/constants';

import Page from '../../components/Page';

const CustomsBrokers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [customsBrokersList, setCustomsBrokersList] = useState([]);
  const [fetchError, setFetchError] = useState(false);

  const { accessToken } = useAuth0();

  const { GENERIC_ERROR } = notifications;

  const columns = [
    {
      title: '',
      filtering: false,
      width: 100,
      // Don't prop check inline table components -ET
      // eslint-disable-next-line react/prop-types
      render: ({ customsBrokerCompanyId }) => (
        <Button
          color="primary"
          component={Link}
          size="small"
          style={{ marginRight: 5 }}
          to={`/customs-brokers/${customsBrokerCompanyId}`}
          data-testid="customs-broker-view"
          variant="contained"
        >
          View
        </Button>
      ),
    },
    {
      title: 'Customs Broker',
      field: 'chb',
      render: ({ legalName, dbaName }) => `${dbaName || legalName}`,
      customSort: (a, b) => {
        const aName = a.dbaName || a.legalName || '';
        const bName = b.dbaName || b.legalName || '';

        return stringSortCompare(aName.toLowerCase(), bName.toLowerCase());
      },
      customFilterAndSearch: (term, rowData) =>
        (rowData.dbaName || rowData.legalName)
          .toLowerCase()
          .includes(term.toLowerCase()),
    },
    {
      title: 'Location',
      field: 'location',
      render: ({ cityName, stateShortName }) =>
        `${cityName}, ${stateShortName}`,
      customSort: (a, b) => {
        const aLocation = `${a.cityName}, ${a.stateShortName}`;
        const bLocation = `${b.cityName}, ${b.stateShortName}`;

        return stringSortCompare(aLocation, bLocation);
      },
      customFilterAndSearch: (term, rowData) =>
        `${rowData.cityName}, ${rowData.stateShortName}`
          .toLowerCase()
          .includes(term.toLowerCase()),
    },
    {
      title: 'CTPAT',
      field: 'hasCTPATCertification',
      type: 'boolean',
    },
    {
      title: 'Country Serviced',
      field: 'countryServicedShortName',
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/customs-brokers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(data => setCustomsBrokersList(data.data))
      .catch(() => setFetchError(true))
      .finally(() => setIsLoading(false));
  }, [accessToken]);

  return (
    <Page title="Customs Brokers">
      <Container maxWidth={false}>
        <Table
          data-testid="customs-brokers-table"
          filtering
          title="Customs Brokers"
          titleVariant="h5"
          isLoading={isLoading}
          columns={columns}
          data={customsBrokersList}
          errorText={fetchError ? GENERIC_ERROR.EN : ''}
          options={{
            // Max height - top bar, page padding, top bars on table
            maxBodyHeight:
              'calc(100vh - 64px - 48px - 70px - 24px - 41px - 41px)',
            filterCellStyle: { padding: 0, textAlign: 'center' },
          }}
        />
      </Container>
    </Page>
  );
};

export default CustomsBrokers;
