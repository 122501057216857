import { createAction } from '@reduxjs/toolkit';
import { dayjs } from '@forager/date-utils';

export const resolveIncident = createAction('loadOverview/resolveIncident');

export const resolveIncidentReducer = (state, action) => {
  const incidentIdToResolve = action.payload;
  state.incidents.data = state.incidents.data.map(incident =>
    incident.loadIncidentId === incidentIdToResolve
      ? { ...incident, resolvedAt: dayjs().format() }
      : incident
  );
};
