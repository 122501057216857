import { useAuth0 } from '@forager/client-utils';
import PropTypes from 'prop-types';

export const useScopes = requiredScopes => {
  const { scopes } = useAuth0();

  if (!requiredScopes) return { hasScopes: false };

  if (typeof requiredScopes === 'string')
    return { hasScopes: scopes.split(' ').includes(requiredScopes) };

  if (Array.isArray(requiredScopes)) {
    return {
      hasScopes: requiredScopes.every(reqScope =>
        scopes.split(' ').includes(reqScope)
      ),
    };
  }

  console.warn(
    'Invalid type recieved. Must pass individual scope as a string or multiple scopes as an array of strings'
  );

  return { hasScopes: false };
};

useScopes.propTypes = {
  requiredScopes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

useScopes.defaultProps = {
  requiredScopes: '',
};

export default useScopes;
