import { createAction } from '@reduxjs/toolkit';

export const updateLoadInfo = createAction('loadOverview/updateLoadInfo');

export const updateLoadInfoReducer = (state, action) => {
  const {
    ascendReferenceNumber,
    bolNumber,
    customerReferenceNumber,
    freightVerifyShipmentId,
    accelerateOriginId,
    accelerateChildId,
  } = action.payload;

  state.info.data.ascendReferenceNumber = ascendReferenceNumber;
  state.info.data.bolNumber = bolNumber;
  state.info.data.customerReferenceNumber = customerReferenceNumber;
  state.info.data.freightVerifyShipmentId = freightVerifyShipmentId;
  state.info.data.accelerateOriginId = accelerateOriginId;
  state.info.data.accelerateChildId = accelerateChildId;
};
