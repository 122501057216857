import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from '@material-ui/core/';
import { FullCardLoader } from '@forager/bits';
import { useAuth0, useForagerNotification } from '@forager/client-utils';
import FileUploadForm from './FileUploadForm';

const FileUploadModal = ({
  isFileUploadModalOpen,
  setFileUploadModalOpen,
  fetchCarrierFiles,
  carrierCompanyId,
  files,
  setFiles,
}) => {
  const { accessToken } = useAuth0();
  const { error: errorNotifaction } = useForagerNotification();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog
      open={isFileUploadModalOpen}
      onBackdropClick={() => setFileUploadModalOpen(false)}
    >
      {isLoading && <FullCardLoader data-testid="loader" />}
      <DialogTitle>Upload a new file</DialogTitle>
      <Divider />
      <DialogContent>
        <FileUploadForm
          files={files}
          setFiles={setFiles}
          accessToken={accessToken}
          setIsLoading={setIsLoading}
          setFileUploadModalOpen={setFileUploadModalOpen}
          errorNotifaction={errorNotifaction}
          carrierCompanyId={carrierCompanyId}
          fetchCarrierFiles={fetchCarrierFiles}
        />
      </DialogContent>
    </Dialog>
  );
};

FileUploadModal.propTypes = {
  isFileUploadModalOpen: PropTypes.bool,
  setFileUploadModalOpen: PropTypes.func,
  fetchCarrierFiles: PropTypes.func,
  carrierCompanyId: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      carrierFileTypeId: PropTypes.number,
      createdAt: PropTypes.string,
      fileDescription: PropTypes.string,
      fileId: PropTypes.number,
      s3Key: PropTypes.string,
    })
  ),
  setFiles: PropTypes.func,
};

export default FileUploadModal;
