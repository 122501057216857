import { createAction } from '@reduxjs/toolkit';

export const updateCustomsBrokers = createAction(
  'loadOverview/updateCustomsBrokers'
);

const formatCustomsBroker = broker => {
  if (!broker || broker.location) return broker;

  return {
    dbaName: broker.dbaName,
    legalName: broker.legalName,
    companyId: broker.customsBrokerCompanyId,
    location: {
      address1: broker.address1,
      address2: broker.address2,
      addressId: broker.addressId,
      cityId: broker.cityId,
      cityName: broker.cityName,
      countryId: broker.countryId,
      postalCode: broker.postalCode,
      stateId: broker.stateId,
      stateShortName: broker.stateShortName,
    },
  };
};

export const updateCustomsBrokersReducer = (state, action) => {
  const { usaCustomsBroker, mexCustomsBroker, canCustomsBroker } =
    action.payload;

  const [
    formattedUsaCustomsBroker,
    formattedMexCustomsBroker,
    formattedCanCustomsBroker,
  ] = [usaCustomsBroker, mexCustomsBroker, canCustomsBroker].map(
    formatCustomsBroker
  );

  if (
    state.borderCrossings?.data?.mex?.length &&
    state.borderCrossings.data.mex[0].customsBrokers
  ) {
    state.borderCrossings.data.mex[0].customsBrokers.mex =
      formattedMexCustomsBroker;
    state.borderCrossings.data.mex[0].customsBrokers.usa =
      formattedUsaCustomsBroker;
  }

  if (
    state.borderCrossings?.data?.can?.length &&
    state.borderCrossings.data.can[0].customsBrokers
  ) {
    state.borderCrossings.data.can[0].customsBrokers.can =
      formattedCanCustomsBroker;
    state.borderCrossings.data.can[0].customsBrokers.usa =
      formattedUsaCustomsBroker;
  }
};
