import { createAction } from '@reduxjs/toolkit';
import { getFirstPickup, getLastDelivery } from '@forager/client-utils';

export const updateStopDetails = createAction('loadOverview/updateStopDetails');
export const updateStopTracking = createAction(
  'loadOverview/updateStopTracking'
);

export const updateStopDetailsReducer = (state, action) => {
  state.stops.data = state.stops.data.reduce((acc, stop) => {
    const { loadStopId } = stop;

    const foundStop = action.payload.find(
      stop => stop.loadStopId === loadStopId
    );

    if (foundStop) {
      return [
        ...acc,
        {
          ...stop,
          ...foundStop,
        },
      ];
    }

    return [...acc, stop];
  }, []);

  const firstPickup = getFirstPickup(state.stops.data);
  const lastDelivery = getLastDelivery(state.stops.data);

  state.info.data.firstPickupAppointmentOpen = firstPickup?.appointmentOpen;
  state.info.data.lastDeliveryAppointmentOpen = lastDelivery.appointmentOpen;
};

export const updateStopTrackingReducer = (state, action) => {
  state.stops.data = state.stops.data.reduce((acc, stop) => {
    const { loadStopId } = stop;

    const foundStopTracking = action.payload.find(
      stop => stop.loadStopId === loadStopId
    );

    if (foundStopTracking) {
      const { loadStopId, ...restTracking } = foundStopTracking;
      return [
        ...acc,
        {
          ...stop,
          tracking: {
            ...stop.tracking,
            ...restTracking,
          },
        },
      ];
    }

    return [...acc, stop];
  }, []);
};
