import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, makeStyles } from '@material-ui/core';
import { OverflowTooltip } from '@forager/bits';

const OverflowInput = ({ title, value }) => {
  const classes = useStyles();
  return (
    <>
      <InputLabel className={classes.inputLabel} shrink>
        {title}
      </InputLabel>
      <OverflowTooltip
        data-testid={`tooltip-${value}`}
        tooltipValue={value || '—'}
        displayValue={value || '—'}
      />
    </>
  );
};

OverflowInput.propTypes = {
  title: PropTypes.string,
  value: PropTypes.node,
};

const useStyles = makeStyles({
  inputLabel: {
    marginBottom: '5px',
    color: 'rgba(0, 0, 0, 0.38)',
  },
});

export default OverflowInput;
