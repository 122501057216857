import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Container, Box, Typography, Card, Grid } from '@material-ui/core';
import { Table } from '@forager/bits';
import { useAuth0 } from '@forager/client-utils';
import { notifications } from '@forager/constants';
import Page from '../../components/Page';
import CustomPagination from './CustomPagination';
import columns from './carrierTableColumns';
import { getUrl, parseQueryParams } from './queryUtils';
import LocationFilters from './LocationFilters';

export const isServicingCountry = (country, operatingStates) =>
  operatingStates.some(state => state.countryShortName === country);

const Carriers = () => {
  const { accessToken } = useAuth0();
  const [fetchError, setFetchError] = useState(false);
  const tableRef = useRef(null);
  const { GENERIC_ERROR } = notifications;
  const [isLoading, setIsLoading] = useState(false);

  const handlePaginateData = async query => {
    setIsLoading(true);
    const result = await new Promise(resolve => {
      const baseUrl = `${process.env.REACT_APP_API_URL}/v1/carriers?`;
      const { state, dataManager } = tableRef.current;
      const { nextPageUrl, prevPageUrl } =
        tableRef.current.paginationData || {};

      const url = getUrl({
        query,
        baseUrl,
        nextPage: nextPageUrl,
        prevPage: prevPageUrl,
      });
      const params = parseQueryParams({ query, url });

      if (query.stateId) {
        params.stateId = query?.stateId;
      }

      if (query.mexicoBorderCrossingId) {
        params.mexicoBorderCrossingId = query?.mexicoBorderCrossingId;
      }

      axios
        .get(`${url.origin}${url.pathname}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params,
        })
        .then(result => {
          tableRef.current.paginationData = result.data;
          resolve({
            data: result.data.records,
            page: result.data.pageNumber - 1,
            totalCount: result.data.totalRecords,
          });
          if (result.data.records.length === 0) {
            dataManager.changePageSize(15); // ensures none found message is visible
          } else if (state.pageSize !== 25) {
            dataManager.changePageSize(25);
          }
        })
        .catch(() => {
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
          dataManager.changePageSize(15); // ensures error message is visible
          setFetchError(true);
        })
        .finally(() => {
          if (query.evt?.type) query.evt.type = null;
        });
    });

    setIsLoading(false);
    return result;
  };

  const applyFilter = filterObject => {
    tableRef.current.onQueryChange({
      ...tableRef?.current?.state?.query,
      ...filterObject,
    });
  };

  return (
    <Page title="Carriers">
      <Container maxWidth={false}>
        <Card raised>
          <Box m={2}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h5">Carriers</Typography>
              </Grid>
              <Grid item xs={12} md={11}>
                <LocationFilters
                  applyFilter={applyFilter}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
          </Box>
          <Table
            data-testid="carrier-table"
            tableRef={tableRef}
            errorText={fetchError ? GENERIC_ERROR.EN : 'No Carriers Found'}
            columns={columns}
            data={handlePaginateData}
            components={{
              Pagination: props => (
                <CustomPagination {...props} tableRef={tableRef} />
              ),
            }}
            options={{
              search: false,
              toolbar: false,
              draggable: false,
              filtering: true,
              pageSize: 25,
              sorting: true,
              debounceInterval: 400,
              filterCellStyle: {
                textAlign: 'center',
                padding: '5px',
              },
              headerStyle: {
                textAlign: 'center',
              },
              // Max height - top bar, page padding, top bars on table
              maxBodyHeight:
                'calc(100vh - 64px - 48px - 70px - 24px - 41px - 41px)',
            }}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default Carriers;
