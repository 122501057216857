import axios from 'axios';
import { convertEmptyStringsToNull } from '@forager/client-utils';

const submit = async (values, { props }) => {
  const {
    companyId,
    error,
    accessToken,
    setIsLoading,
    setIsEditingProfile,
    companyTypeId,
  } = props;

  const { legalName, dbaName, ...restValues } =
    convertEmptyStringsToNull(values);

  const rateTemplateId = companyTypeId ? restValues.rateTemplateId : null;

  setIsLoading(true);
  try {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/companies/${companyId}`,
      {
        dbaName,
        legalName,
        ...(companyTypeId && rateTemplateId
          ? {
              rateTemplateId,
            }
          : {}),
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    setIsEditingProfile(false);
  } catch (err) {
    error();
  } finally {
    setIsLoading(false);
  }
};

export default submit;
