import React, { useState } from 'react';
import {
  Dialog,
  makeStyles,
  DialogTitle,
  Typography,
  IconButton,
  Divider,
  DialogContent,
  Grid,
  Box,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { SadButton, HappyButton, FullCardLoader } from '@forager/bits';
import { useFormikContext } from 'formik';
import { useForagerNotification } from '@forager/client-utils';

const ApproveCarrier = () => {
  const { validateForm, submitForm, isSubmitting } = useFormikContext();
  const { error } = useForagerNotification();
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const checkForm = async () => {
    const errors = await validateForm();
    if (!errors || Object.keys(errors)?.length === 0) {
      toggleIsOpen();
    }
  };
  const approveCarrier = async () => {
    submitForm()
      .then(() => toggleIsOpen())
      .catch(() => error());
  };
  return (
    <>
      <HappyButton data-testid="approve-carrier-validation" onClick={checkForm}>
        Approve
      </HappyButton>
      <Dialog open={isOpen} onClose={toggleIsOpen}>
        {isSubmitting && <FullCardLoader />}
        <DialogTitle disableTypography>
          <Typography variant="h5">Approve Carrier</Typography>
          <IconButton className={classes.closeButton} onClick={toggleIsOpen}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box padding="15px" minWidth="400px">
            <Grid container direction="column" justify="center" spacing={2}>
              <Grid item>
                <Typography variant="h4" align="center">
                  APPROVE CARRIER
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="center">
                  Are you sure you want to approve this carrier?
                </Typography>
              </Grid>
              <Grid item container justify="center">
                <Grid item>
                  <SadButton
                    data-testid="cancel-carrier-button"
                    onClick={toggleIsOpen}
                  >
                    Cancel
                  </SadButton>
                </Grid>
                <Grid item>
                  <HappyButton
                    data-testid="approve-carrier-submit"
                    onClick={approveCarrier}
                  >
                    Approve
                  </HappyButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export default ApproveCarrier;
