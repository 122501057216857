import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

// This allows switch to retain color when in disabled state.

const ColoredSwitch = withStyles(theme => ({
  switchBase: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const EnhancedSwitch = props => <ColoredSwitch {...props} />;

export default EnhancedSwitch;
