import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  Box,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { SadButton, HappyButton, FullCardLoader } from '@forager/bits';
import { useAuth0, useForagerNotification } from '@forager/client-utils';
import { ContactForm } from './ContactForm';
import { updateContact, createNewContact, handleDeleteContact } from './utils';

const ContactModal = ({
  contact,
  contacts,
  isContactModalOpen,
  contactModalContent,
  setIsContactModalOpen,
  companyId,
  setContacts,
  hasMain,
  companyTypeId,
  isBillingShown,
}) => {
  const { error, success } = useForagerNotification();
  const { accessToken } = useAuth0();
  const [isContactModalLoading, setContactModalLoading] = useState(false);
  const [replacementContactUserId, setReplacementContactUserId] =
    useState(null);
  const [availableReplacements, setAvailableReplacements] = useState([]);
  const isFacility = companyTypeId === 4;
  const isCustomsBroker = companyTypeId === 5;
  const isCarrier = companyTypeId === 1;
  const hasGeneralContact = contacts?.some(
    tempContact =>
      tempContact.firstName.toUpperCase() === 'GENERAL' &&
      tempContact.lastName?.toUpperCase() === 'CONTACT'
  );

  /**
   * Replacements are only required for entities that could affect the contacts
   * of customers
   */
  const requiresReplacement = isFacility || isCustomsBroker;

  const handleCreateOrUpdateNewContact = newOrExistingContact => {
    // Determine if we're updating or creating a contact
    const sharedUtilFunctionArguments = {
      companyId,
      setContacts,
      setIsContactModalOpen,
      setContactModalLoading,
      error,
      success,
      accessToken,
      contacts,
    };
    if (contact) {
      updateContact({
        ...sharedUtilFunctionArguments,
        existingContact: newOrExistingContact,
      });
    } else {
      createNewContact({
        newContact: newOrExistingContact,
        setAvailableReplacements,
        ...sharedUtilFunctionArguments,
      });
    }
  };

  useEffect(() => {
    if (
      contacts.length &&
      contact &&
      (!availableReplacements.length || availableReplacements.includes(contact))
    ) {
      const newAvailableReplacements = contacts.filter(
        user => user.userId !== contact.userId
      );
      if (newAvailableReplacements.length)
        setAvailableReplacements(newAvailableReplacements);
    }
  }, [availableReplacements, contacts, contact]);

  const handleReplacementSelect = evt => {
    setReplacementContactUserId(evt.target.value);
  };

  const replacementModalContent = (
    <Grid item xs={12}>
      <FormControl fullWidth data-testid="replacement-modal-content">
        <InputLabel>Select Alternative Contact</InputLabel>
        <Select
          onChange={evt => handleReplacementSelect(evt)}
          value={replacementContactUserId || ''}
          data-testid="replacement-select"
        >
          {availableReplacements.map(replacement => {
            return (
              <MenuItem key={replacement.userId} value={replacement.userId}>
                {`${replacement.firstName} ${replacement.lastName}`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );

  const deleteModalContent = (
    <Box width={200} data-testid="delete-modal">
      <Grid container>
        {contacts.length > 1 && requiresReplacement && replacementModalContent}

        <Grid item container justify="center" xs={12}>
          {!contact?.isMain ? (
            <>
              <Typography>Are you sure you want to delete?</Typography>
              <HappyButton
                data-testid="confirm-delete-contact"
                size="small"
                onClick={() =>
                  handleDeleteContact({
                    contactToBeDeleted: contact,
                    setReplacementContactUserId,
                    setContactModalLoading,
                    success,
                    error,
                    setIsContactModalOpen,
                    setContacts,
                    contacts,
                    companyId,
                    companyTypeId,
                    replacementContactUserId,
                    accessToken,
                  })
                }
                disabled={!replacementContactUserId && requiresReplacement}
              >
                Confirm
              </HappyButton>
            </>
          ) : (
            <Typography data-testid="delete-main-text">
              Unable to delete main contact. Please re-assign main contact and
              try again.
            </Typography>
          )}
          <SadButton
            data-testid="cancel-delete-contact"
            size="small"
            onClick={() => setIsContactModalOpen(false)}
          >
            Cancel
          </SadButton>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div data-testid="contact-modal">
      <Dialog
        onClose={() => setIsContactModalOpen(false)}
        open={isContactModalOpen}
        maxWidth="md"
      >
        {/* disableTypography allows you to have other typographies as children.
            Otherwise we are nesting h5 inside of h2 */}
        <DialogTitle disableTypography>
          <Typography variant="h5">Contact</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ position: 'relative', overflow: 'hidden' }}>
          {isContactModalLoading && <FullCardLoader />}
          {contactModalContent === 'deleteContent' && deleteModalContent}
          {contactModalContent === 'addEditContent' && (
            <ContactForm
              hasMain={hasMain}
              isBillingShown={isBillingShown}
              contact={contact}
              isFacility={isFacility}
              isCustomsBroker={isCustomsBroker}
              isCarrier={isCarrier}
              hasGeneralContact={hasGeneralContact}
              formAction={handleCreateOrUpdateNewContact}
              setIsContactModalOpen={setIsContactModalOpen}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

ContactModal.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      title: PropTypes.string,
      officePhone: PropTypes.string,
      officeExt: PropTypes.string,
      cellPhone: PropTypes.string,
      email: PropTypes.string,
      isDeleted: PropTypes.bool,
      userId: PropTypes.number,
    })
  ),
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    officePhone: PropTypes.string,
    officeExt: PropTypes.string,
    cellPhone: PropTypes.string,
    email: PropTypes.string,
    isDeleted: PropTypes.bool,
    isMain: PropTypes.bool,
    userId: PropTypes.number,
  }),
  isContactModalOpen: PropTypes.bool,
  setIsContactModalOpen: PropTypes.func,
  companyId: PropTypes.string,
  contactModalContent: PropTypes.string,
  setContacts: PropTypes.func,
  hasMain: PropTypes.bool,
  companyTypeId: PropTypes.number,
  isBillingShown: PropTypes.bool,
};

export default ContactModal;
