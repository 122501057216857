import React from 'react';
import { Grid, Container, Card, CardHeader, Divider } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@forager/client-utils';
import Page from '../../../components/Page';
import CompanyInformation from '../../../components/CompanyInformation';
import ContactList from '../../../components/ContactList';
import CustomsBrokerInformation from './CustomsBrokerInformation';

const CustomsBrokerProfile = () => {
  const { accessToken } = useAuth0();
  const { id: customsBrokerId } = useParams();

  return (
    <Page title="Customs Broker Profile">
      <Container
        maxWidth={false}
        data-testid="customs-broker-profile-container"
      >
        <Grid container spacing={3}>
          <Grid container item xs={12}>
            <Card style={{ width: '100%' }}>
              <CardHeader title="Customs Broker Profile" />
              <Divider />
              <Grid container>
                <Grid item xs={12} md={6}>
                  <CompanyInformation
                    accessToken={accessToken}
                    companyId={customsBrokerId}
                  />
                </Grid>
                <Grid container item xs={12} md={6}>
                  <CustomsBrokerInformation
                    accessToken={accessToken}
                    companyId={customsBrokerId}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <ContactList companyTypeId={5} companyId={customsBrokerId} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CustomsBrokerProfile;
