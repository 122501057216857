import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Email, Phone, Chip } from '@forager/bits';

const center = { justifyContent: 'center' };

export default [
  {
    title: '',
    filtering: false,
    sorting: false,
    width: 100,
    render: ({ carrierCompanyId }) => (
      <Button
        color="primary"
        component={RouterLink}
        size="small"
        to={`/carriers/${carrierCompanyId}`}
        variant="contained"
        data-testid="view-button"
      >
        View
      </Button>
    ),
  },
  {
    title: 'Legal Name',
    field: 'legalName',
    sorting: false,
  },
  {
    title: 'DBA Name',
    field: 'dbaName',
    emptyValue: '-',
    sorting: false,
  },
  {
    title: 'Company Phone',
    field: 'companyPhone',
    emptyValue: '-',
    sorting: false,
    filtering: false,
    render: ({ companyPhone }) => (
      <Phone data-testid="company-phone" styles={center} value={companyPhone} />
    ),
  },
  {
    title: 'DOT #',
    field: 'dotNumber',
    emptyValue: '-',
    sorting: false,
  },
  // {
  //   title: 'Main Rep',
  //   sorting: false,
  //   field: 'foragerRepName',
  //   render: ({ foragerRepFirstName, foragerRepLastName }) => {
  //     if (!foragerRepFirstName && !foragerRepLastName) return '-';

  //     return `${foragerRepFirstName} ${foragerRepLastName}`;
  //   },
  // },
  {
    title: 'Approval Status',
    sorting: false,
    field: 'carrierStatusWithForagerId',
    render: ({ carrierStatusWithForagerId, carrierStatusWithForager }) => {
      const isApproved = carrierStatusWithForagerId === 1;
      return (
        <Chip
          size="small"
          label={carrierStatusWithForager}
          variant="text"
          color={isApproved ? 'success' : 'error'}
        />
      );
    },
    lookup: { 1: 'Certified', 2: 'Not Certified' },
  },
  {
    title: 'CTPAT Certified',
    field: 'hasCTPATCertification',
    type: 'boolean',
    sorting: false,
  },
  {
    title: 'Services Mexico',
    field: 'servicesMexico',
    type: 'boolean',
    sorting: false,
  },
  {
    title: 'Services Canada',
    field: 'servicesCanada',
    type: 'boolean',
    sorting: false,
  },
  {
    title: 'Total Truck Count',
    field: 'totalTruckCount',
    filtering: false,
    cellStyle: {
      paddingRight: '24px',
    },
    render: ({ totalTruckCount }) =>
      totalTruckCount || totalTruckCount === 0 ? totalTruckCount : '-',
  },
  {
    title: 'City, State',
    sorting: false,
    field: 'cityState',
    render: ({ cityName, stateShortName }) => `${cityName}, ${stateShortName}`,
  },
  {
    title: 'Contact',
    filtering: false,
    sorting: false,
    render: ({ firstName, lastName }) => {
      if (!firstName && !lastName) return '-';

      return `${firstName} ${lastName}`;
    },
  },
  {
    title: 'Contact Phone',
    emptyValue: '-',
    field: 'phone',
    filtering: false,
    sorting: false,
    render: ({ phone }) => (
      <Phone data-testid="contact-phone" styles={center} value={phone} />
    ),
  },
  {
    title: 'Ext.',
    field: 'phoneExt',
    emptyValue: '-',
    filtering: false,
    sorting: false,
  },
  {
    title: 'Contact Email',
    emptyValue: '-',
    field: 'email',
    filtering: false,
    sorting: false,
    render: ({ email }) => (
      <Email name="email" data-testid="carrier-email" value={email} withCopy />
    ),
  },
];
