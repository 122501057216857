import React, { Suspense } from 'react';

import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { renderRoutes } from 'react-router-config';

const Error = ({ route }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content} data-testid="error-content">
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
  },
});

Error.propTypes = {
  route: PropTypes.shape({
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        component: PropTypes.elementType.isRequired,
        exact: PropTypes.bool,
        path: PropTypes.string,
      })
    ),
  }),
};

export default Error;
