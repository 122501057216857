import React, { useEffect, useRef, useState } from 'react';
import {
  EmailInput,
  FormTextField,
  PhoneInput,
  FullCardLoader,
} from '@forager/bits';
import {
  makeStyles,
  Grid,
  FormControlLabel,
  Switch,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  CardHeader,
  Divider,
} from '@material-ui/core';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import {
  countries,
  labels,
  paperworkDeliveryMethodOptions,
} from '@forager/constants';
import { initialValues, submit, validationSchema } from './formik';
import FullAddressInput from '../../../components/FullAddressInput';

export const AddNewCustomsBrokerForm = ({
  errors,
  handleChange,
  handleSubmit,
  isLoading,
  setFieldValue,
  setValues,
  values,
}) => {
  const classes = useStyles();
  const countryInputRef = useRef(null);
  const paperworkInputRef = useRef(null);
  const [countryLabelWidth, setCountryLabelWidth] = useState(0);
  const [paperworkLabelWidth, setPaperworkLabelWidth] = useState(0);

  useEffect(() => {
    setCountryLabelWidth(countryInputRef.current.offsetWidth);
    setPaperworkLabelWidth(paperworkInputRef.current.offsetWidth);
  }, []);

  const returnCountriesServiced = selectedCountry => {
    switch (selectedCountry) {
      case 'United States':
        return countries.filter(country => country.LONG !== 'Mexico');
      case 'Mexico':
        return countries.filter(country => country.LONG === 'Mexico');
      case 'Canada':
        return countries.filter(country => country.LONG === 'Canada');
      default:
        return countries;
    }
  };

  const handleSelect = (evt, name) => {
    setFieldValue([name], evt.target.value);
  };
  const handleAddress = fullAddress => {
    setValues({ ...values, ...fullAddress });
  };

  const countriesServiced = returnCountriesServiced(values.country);

  return (
    <div className={classes.container} data-testid="add-new-cb-form">
      {isLoading && <FullCardLoader data-testid="loader" />}
      <Grid container spacing={2}>
        <Grid
          container
          xs={12}
          md={6}
          item
          spacing={2}
          alignContent="flex-start"
        >
          <CardHeader
            title="Customs Broker Info"
            className={classes.formSectionHeader}
          />
          <Grid item xs={12}>
            <FormTextField
              name="legalName"
              label="Legal Name"
              required
              className={classes.text}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name="dbaName"
              label="DBA Name"
              className={classes.text}
            />
          </Grid>

          <Grid item xs={12}>
            <FullAddressInput
              required
              onAddressComplete={handleAddress}
              parentErrors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.text} variant="outlined" required>
              <InputLabel ref={countryInputRef} id="country-serviced-label">
                Country Serviced
              </InputLabel>
              <Select
                labelWidth={countryLabelWidth}
                data-testid="country-serviced-select"
                labelId="country-serviced-label"
                onChange={e => handleSelect(e, 'countryServicedId')}
                value={values.countryServicedId}
              >
                {countriesServiced.map(({ SHORT, ID }) => (
                  <MenuItem
                    key={ID}
                    data-testid={`country-option-${SHORT.toLowerCase()}`}
                    value={ID}
                  >
                    {SHORT}
                  </MenuItem>
                ))}
              </Select>
              {errors.countryServicedId && (
                <FormHelperText data-testid="country-error" error>
                  {errors.countryServicedId}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <Grid
          container
          xs={12}
          md={6}
          item
          spacing={2}
          alignContent="flex-start"
        >
          <CardHeader
            title="Contact Info"
            className={classes.formSectionHeader}
          />
          <Grid item xs={12}>
            <FormTextField
              name="firstName"
              label="First Name"
              required
              className={classes.text}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name="lastName"
              label="Last Name"
              className={classes.text}
            />
          </Grid>
          <Grid item xs={12}>
            <EmailInput
              name="email"
              label="Email"
              className={classes.text}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name="title"
              label="Title"
              className={classes.text}
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneInput
              name="phone"
              label={labels.OFFICE_PHONE.EN}
              labelWidth="75px"
              extName="phoneExt"
              extLabel={labels.PHONE_EXT.EN}
              className={classes.text}
              error={errors.phone}
              extInputWidth="100%"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneInput
              name="mobile"
              label={labels.CELL_PHONE.EN}
              error={errors.mobile}
              labelWidth="65px"
              hideExt
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.text} variant="outlined" required>
              <InputLabel
                ref={paperworkInputRef}
                id="paperwork-delivery-method-label"
              >
                Paperwork Delivery Method
              </InputLabel>
              <Select
                labelWidth={paperworkLabelWidth}
                data-testid="paperwork-delivery-select"
                labelId="paperwork-delivery-method-label"
                onChange={e =>
                  handleSelect(e, 'customsBrokerPaperworkDeliveryMethodId')
                }
                value={values.customsBrokerPaperworkDeliveryMethodId}
              >
                {paperworkDeliveryMethodOptions.map(({ ID, TEST_ID, NAME }) => (
                  <MenuItem key={ID} data-testid={TEST_ID} value={ID}>
                    {NAME}
                  </MenuItem>
                ))}
              </Select>
              {errors.customsBrokerPaperworkDeliveryMethodId && (
                <FormHelperText data-testid="paperwork-delivery-error" error>
                  {errors.customsBrokerPaperworkDeliveryMethodId}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch checked={values.hasCTPATCertification} />}
              label="CTPAT"
              labelPlacement="end"
              name="hasCTPATCertification"
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Switch checked={values.hasOvertimeAvailable} />}
              label="Overtime Available"
              labelPlacement="end"
              name="hasOvertimeAvailable"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12} justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              data-testid="new-cb-btn"
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

AddNewCustomsBrokerForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    country: PropTypes.string,
    hasOvertimeAvailable: false,
    hasCTPATCertification: false,
    customsBrokerPaperworkDeliveryMethodId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    countryServicedId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  errors: PropTypes.shape({
    customsBrokerPaperworkDeliveryMethodId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    countryServicedId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    phone: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    mobile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

AddNewCustomsBrokerForm.defaultProps = {
  errors: {},
};

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  text: {
    width: '100%',
  },
  formSectionHeader: {
    padding: 'inherit',
    width: '100%',
  },
  divider: {
    margin: theme.spacing(1),
  },
}));

export default withFormik({
  mapPropsToValues: () => initialValues,
  handleSubmit: submit,
  validationSchema,
  validateOnChange: false,
  validateOnBlur: false,
})(AddNewCustomsBrokerForm);
