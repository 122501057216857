import React, { useRef, useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useField } from 'formik';
import { countries } from '@forager/constants';

const CountryServicedDropdown = ({ fieldName, errors }) => {
  const classes = useStyles();
  const countryInputRef = useRef(null);
  const [countryLabelWidth, setCountryLabelWidth] = useState(0);
  const [field] = useField({ name: fieldName });

  useEffect(() => {
    setCountryLabelWidth(countryInputRef.current.offsetWidth);
  }, []);

  return (
    <FormControl className={classes.text} variant="outlined" required>
      <InputLabel ref={countryInputRef} id="country-label">
        Country Serviced
      </InputLabel>
      <Select
        labelWidth={countryLabelWidth}
        data-testid="country-select"
        labelId="country-label"
        {...field}
      >
        {countries.map(({ SHORT, ID }) => (
          <MenuItem
            key={ID}
            data-testid={`country-option-${SHORT.toLowerCase()}`}
            value={ID}
          >
            {SHORT}
          </MenuItem>
        ))}
      </Select>
      {errors.countryServicedId && (
        <FormHelperText data-testid="country-error" error>
          {errors.countryServicedId}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles({
  text: {
    width: '100%',
  },
});

CountryServicedDropdown.propTypes = {
  fieldName: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    countryServicedId: PropTypes.string,
  }),
};

export default CountryServicedDropdown;
