const isMostRecentFile = (fileId, carrierFileTypeId, files) => {
  const mostRecentFile = files.reduce(
    (acc, file) => {
      if (file.carrierFileTypeId !== carrierFileTypeId) return acc;

      if (new Date(file.createdAt) > new Date(acc.createdAt)) return file;

      return acc;
    },
    { createdAt: 0 }
  );

  return mostRecentFile.fileId === fileId;
};

export default isMostRecentFile;
