import axios from 'axios';
import { convertEmptyStringsToNull } from '@forager/client-utils';
import { notifications } from '@forager/constants';

const { ERROR, SUCCESS } = notifications;

const createNewContact = async ({
  newContact,
  accessToken,
  contacts,
  setContactModalLoading,
  setContacts,
  setAvailableReplacements,
  success,
  error,
  setIsContactModalOpen,
  companyId,
}) => {
  setContactModalLoading(true);
  try {
    const payload = convertEmptyStringsToNull(newContact);
    const { data: newUser } = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/users`,
      {
        ...payload,
        companyId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const {
      isMain: isNewUserMain = false,
      isLoadOfferContact: isNewUserLoadOfferContact = false,
    } = newUser;

    const newContactsList = contacts.map(contact => {
      let tempContact = {
        ...contact,
      };
      if (isNewUserMain) {
        tempContact = { ...tempContact, isMain: false };
      }

      if (isNewUserLoadOfferContact) {
        tempContact = {
          ...tempContact,
          isLoadOfferContact: false,
        };
      }
      return tempContact;
    });

    isNewUserMain
      ? newContactsList.unshift(newUser)
      : newContactsList.push(newUser);

    setContacts(newContactsList);
    setAvailableReplacements(newContactsList);
    success(SUCCESS.EN);
  } catch (err) {
    error(ERROR.EN);
  } finally {
    setContactModalLoading(false);
    setIsContactModalOpen(false);
  }
};

export default createNewContact;
