import { createAction } from '@reduxjs/toolkit';

export const addProduct = createAction('loadOverview/addProduct');
export const editProduct = createAction('loadOverview/editProduct');
export const deleteProduct = createAction('loadOverview/deleteProduct');

export const addProductReducer = (state, action) => {
  state.products.data.push(action.payload);
};

export const deleteProductReducer = (state, action) => {
  state.products.data = state.products.data.filter(
    ({ loadCargoId }) => loadCargoId !== action.payload.loadCargoId
  );
};

export const editProductReducer = (state, action) => {
  const productIndex = state.products.data.findIndex(
    product => product.loadCargoId === action.payload.loadCargoId
  );
  state.products.data[productIndex] = action.payload;
};
