import React, { Suspense, useState } from 'react';
import clsx from 'clsx';
import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { renderRoutes } from 'react-router-config';
import { useAuth0, useScopes } from '@forager/client-utils';

import TopBar from './TopBar';
import NavBar from './NavBar';

const Dashboard = ({ route }) => {
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(true);
  const { isAuthenticated, loginWithRedirect, user, logout } = useAuth0();
  const { hasScopes: userRequiresProductionWarning } =
    useScopes('warning:production');
  const renderProductionWarning =
    process.env.REACT_APP_ENVIRONMENT === 'production' &&
    userRequiresProductionWarning;

  if (isAuthenticated && user.companyId !== 1) {
    logout();
    return <LinearProgress />;
  }

  if (!isAuthenticated) {
    window.localStorage.setItem('loginRedirectUrl', window.location.pathname);
    loginWithRedirect({});
    return <LinearProgress />;
  }

  return (
    <>
      <TopBar
        onOpenNavBarMobile={() => setOpenNavBarMobile(!openNavBarMobile)}
      />
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
      />

      <div className={classes.container} data-testid="dashboard-content">
        <div
          className={clsx(
            classes.content,
            openNavBarMobile && classes.contentDesktopPadding,
            renderProductionWarning && classes.productionWarning
          )}
        >
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </>
  );
};

const drawerWidth = 256;

const useStyles = makeStyles(theme => ({
  productionWarning: { marginTop: '32px' },
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  contentDesktopPadding: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px - 40px)`,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: drawerWidth,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

Dashboard.propTypes = {
  route: PropTypes.shape({
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        exact: PropTypes.bool,
        component: PropTypes.func,
      })
    ),
  }),
};

export default Dashboard;
