import * as Yup from 'yup';
import { validatePhone, hasPhoneOrMobile } from '@forager/client-utils';

const contactSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required').trim(),
  lastName: Yup.string().trim().nullable(),

  phone: Yup.string()
    .nullable()
    .test('phone', 'Must be a valid phone number', function () {
      const { phone } = this.parent;
      return phone ? validatePhone(phone) : true;
    })
    .test(
      'combination',
      'Either an office or mobile phone must be provided',
      hasPhoneOrMobile
    ),
  mobile: Yup.string()
    .nullable()
    .test('phone', 'Must be a valid phone number', function () {
      const { mobile } = this.parent;
      return mobile ? validatePhone(mobile) : true;
    })
    .test(
      'combination',
      'Either an office or mobile phone must be provided',
      hasPhoneOrMobile
    ),
  phoneExt: Yup.number().nullable(),
  email: Yup.string().when('isEmailOptional', {
    is: false,
    then: Yup.string()
      .email('Must be a valid email')
      .required('Email is required.'),
    otherwise: Yup.string().nullable(),
  }),
  title: Yup.string().trim().nullable(),
});

export default contactSchema;
