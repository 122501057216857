import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetch = async ({ accessToken, loadId }, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_LOAD_API}/v1/loads/${loadId}/vendors`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
};

export const condition = (_args, { getState }) => {
  const { loadOverview } = getState();
  const vendors = loadOverview.vendors.data;
  const isLoading = loadOverview.vendors.isLoading;

  if (Array.isArray(vendors) || isLoading === true) {
    return false;
  }

  return true;
};

const fetchVendors = createAsyncThunk('loadOverview/fetchVendors', fetch, {
  condition,
});

export const extraReducers = {
  [fetchVendors.fulfilled]: (state, action) => {
    state.vendors.data = action?.payload;
    state.vendors.isLoading = false;
  },
  [fetchVendors.pending]: state => {
    state.vendors.isLoading = true;
  },
  [fetchVendors.rejected]: state => {
    state.vendors.fetchError = true;
    state.vendors.isLoading = false;
  },
};

export default fetchVendors;
