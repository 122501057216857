import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withFormik } from 'formik';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import { HappyButton, SelectDropdown } from '@forager/bits';
import { notifications } from '@forager/constants';
import {
  paymentMethodOptions,
  paymentTermsOptions,
} from '../../../../../constants/carrierRemitTo';

const RemitToInfoForm = ({
  billingContacts,
  factoringCompanies,
  handleSubmit,
  setFieldValue,
  values,
}) => {
  const classes = useStyles();
  const [paymentTermsDisabled, setPaymentTermsDisabled] = useState(false);
  const { pathname } = useLocation();

  const editSaveButtonProps = {
    variant: 'contained',
    size: 'small',
    'data-testid': 'edit-button',
    style: { margin: '0' },
  };

  const handlePayToFactorChanged = event => {
    const { checked } = event.target;
    setFieldValue('payToFactor', checked);
    if (checked) {
      setFieldValue('paymentTermsId', 2);
    }
    setPaymentTermsDisabled(checked);
  };

  useEffect(() => {
    if (values.payToFactor) {
      setFieldValue('paymentTermsId', 2);
      setPaymentTermsDisabled(true);
    }
  }, [values]);

  return (
    <Card>
      <CardHeader
        title="Remit To Info"
        action={
          <HappyButton {...editSaveButtonProps} onClick={handleSubmit}>
            SAVE
          </HappyButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SelectDropdown
              label="Billing Contact"
              name="billingContactUserId"
              options={billingContacts}
              optionLabelKeys={['firstName', 'lastName']}
              optionValueKey="userId"
              optionTemplate={({ firstName, lastName }) => (
                <div>
                  {firstName} {lastName}
                </div>
              )}
              noOptionsText={
                // the mouse down event needs to be here to prevent the dropdown from closing before the button action happens
                // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                <div role="button" onMouseDown={e => e.preventDefault()}>
                  {billingContacts?.length === 0 && (
                    <Box marginBottom="10px">No contacts found</Box>
                  )}
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    component={RouterLink}
                    to={`${pathname}#contacts`}
                  >
                    Add a Contact
                  </Button>
                </div>
              }
            />
          </Grid>
          <Grid container item xs={4} spacing={2} direction="column">
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    name="payToFactor"
                    checked={values.payToFactor}
                    onChange={handlePayToFactorChanged}
                  />
                }
                label={
                  <Typography className={classes.formLabelTypography}>
                    Pay To Factor
                  </Typography>
                }
                labelPlacement="top"
                className={classes.formLabel}
              />
            </Grid>
            <Grid item>
              <SelectDropdown
                label="Payment Method"
                name="paymentMethodId"
                options={paymentMethodOptions}
                optionLabelKey="label"
                optionValueKey="id"
                optionTemplate={({ label }) => <div>{label}</div>}
              />
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                fullWidth
                disabled={paymentTermsDisabled}
                required
              >
                <InputLabel id="file-type-label" variant="outlined">
                  Payment Terms
                </InputLabel>
                <Select
                  value={values.paymentTermsId}
                  label="Payment Terms"
                  onChange={({ target: { value } }) =>
                    setFieldValue('paymentTermsId', value)
                  }
                >
                  {paymentTermsOptions.map(({ label, id }) => (
                    <MenuItem key={id} value={id}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {values.payToFactor && (
            <Grid item xs={4}>
              <SelectDropdown
                label="Pay to Name"
                name="factoringCompanyId"
                options={factoringCompanies}
                optionLabelKey="legalName"
                optionValueKey="factoringCompanyId"
                optionTemplate={({ legalName }) => <div>{legalName}</div>}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles(() => ({
  formLabel: {
    margin: 0,
    alignItems: 'flex-start',
  },
  formLabelTypography: {
    display: 'flex',
    fontSize: '10.5px',
  },
}));

export const handleSubmit = async (values, { setSubmitting, props }) => {
  const {
    carrierCompanyId,
    accessToken,
    setRemitToInfo,
    setIsEditing,
    success,
    error,
  } = props;

  const {
    payToFactor,
    factoringCompanyId,
    billingContactUserId,
    paymentMethodId,
    paymentTermsId,
  } = values;

  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/carriers/${carrierCompanyId}/remit-to`,
      {
        payToFactor,
        billingContactUserId,
        paymentMethodId,
        paymentTermsId,
        factoringCompanyId: payToFactor ? factoringCompanyId : null,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    setRemitToInfo(data);
    success(notifications.SUCCESS.EN);
    setIsEditing(false);
  } catch (e) {
    error(notifications.GENERIC_ERROR.EN);
  } finally {
    setSubmitting(false);
  }
};

RemitToInfoForm.propTypes = {
  billingContacts: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  factoringCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      factoringCompanyId: PropTypes.number,
      legalName: PropTypes.string,
    })
  ),
  handleSubmit: PropTypes.func,
  paymentMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  paymentTermsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  setFieldValue: PropTypes.func,
  values: PropTypes.shape({
    billingContactUserId: PropTypes.number,
    factoringCompanyId: PropTypes.number,
    payToFactor: PropTypes.bool,
    paymentMethodId: PropTypes.number,
    paymentTermsId: PropTypes.number,
  }),
};

const billingError = 'Billing Contact is Required.';
const paymentError = 'Payment Method is Required.';
const termsError = 'Payment Terms is Required.';
const factorError = 'Factoring Company is Required.';

const validationSchema = Yup.object().shape({
  billingContactUserId: Yup.number()
    .typeError(billingError)
    .required(billingError),
  payToFactor: Yup.boolean().required(),
  paymentMethodId: Yup.number().typeError(paymentError).required(paymentError),
  paymentTermsId: Yup.number().typeError(termsError).required(termsError),
  factoringCompanyId: Yup.number().when('payToFactor', {
    is: true,
    then: Yup.number().typeError(factorError).required(factorError),
    otherwise: Yup.number().nullable(),
  }),
});

export default withFormik({
  mapPropsToValues: ({ remitToInfo }) => ({
    payToFactor: false,
    paymentTermsId: 2,
    ...remitToInfo,
  }),
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit,
  validationSchema,
})(RemitToInfoForm);
