import React from 'react';
import { Grid, Card } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@forager/client-utils';
// import {
//   CarrierOperatingAreas,
//   CarrierModes,
//   CarrierProductExclusions,
//   CarrierEquipment,
//   CarrierTerminals,
// } from '@forager/carrier-profile-components';
import CarrierStatus from './CarrierStatus';
import CompanyInformation from '../../../../components/CompanyInformation';
// import CapacityTable from './CapacityTable';
// import RateTemplateDisplay from '../../../../components/RateTemplateDisplay';
import RateTemplateValidationSchema from '../../../../utils/RateTemplateValidationSchema';

const CompanyInfo = () => {
  const { accessToken } = useAuth0();
  const { id: carrierCompanyId } = useParams();
  // These entities have been migrated to Arrive
  // and are no longer editable in Scout
  const hasScopes = false;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card data-testid="container">
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <CompanyInformation
                  accessToken={accessToken}
                  companyId={carrierCompanyId}
                  editable={hasScopes}
                  companyTypeId={1}
                  // additionalFields={additionalFieldsProps => (
                  //   <RateTemplateDisplay {...additionalFieldsProps} />
                  // )}
                  additionalSchema={RateTemplateValidationSchema}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CarrierStatus
                  accessToken={accessToken}
                  companyId={carrierCompanyId}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* <Grid item md={12}>
          <CapacityTable />
        </Grid>

        <Grid item xs={12}>
          <CarrierOperatingAreas />
        </Grid> */}
      </Grid>

      {/* <Grid container item md={12} spacing={2}>
        <Grid item xs={12} lg={6}>
          <CarrierEquipment />
        </Grid>

        <Grid item xs={12} lg={6}>
          <CarrierTerminals />
        </Grid>
      </Grid>

      <Grid container item md={12} spacing={2}>
        <Grid item xs={12} lg={6}>
          <CarrierModes />
        </Grid>

        <Grid item xs={12} lg={6}>
          <CarrierProductExclusions />
        </Grid>
      </Grid> */}
    </>
  );
};

export default CompanyInfo;
