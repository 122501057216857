import React, { useState, useEffect, useCallback } from 'react';
import { Container } from '@material-ui/core';
import { Table } from '@forager/bits';
import { useAuth0 } from '@forager/client-utils';
import axios from 'axios';
import { notifications } from '@forager/constants';
import Page from '../../../components/Page';
import tableCols from './tableCols';
import ComplianceDetails from './ComplianceDetails';

const CarrierCompliance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [queueRecords, setQueueRecords] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const { accessToken } = useAuth0();
  const removeCarrierById = id => {
    const newQueue = queueRecords.filter(
      ({ applicationId }) => applicationId !== id
    );
    setQueueRecords(newQueue);
  };

  const getComplianceQueue = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/carrierApplications`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      setQueueRecords(data);
    } catch (err) {
      setFetchError('Error fetching compliance queue.');
    } finally {
      setIsLoading(false);
    }
  }, [accessToken]);

  useEffect(() => {
    getComplianceQueue();
  }, [getComplianceQueue]);

  const errorText = () => {
    if (isLoading) return '';
    if (fetchError) return notifications.GENERIC_ERROR.EN;
    if (queueRecords.length === 0) return 'No Applications In Queue';

    return notifications.GENERIC_ERROR.EN;
  };
  return (
    <Page title="Carrier Compliance">
      <Container maxWidth={false}>
        <Table
          data-testid="carrier-compliance-table"
          isLoading={isLoading}
          title="Mexico Carrier Compliance"
          titleVariant="h5"
          columns={tableCols}
          errorText={errorText()}
          data={queueRecords}
          options={{
            search: false,
            dragable: false,
            filtering: true,
            filterCellStyle: {
              textAlign: 'center',
              padding: '5px',
            },
            // Max height - top bar, page padding, top bars on table
            maxBodyHeight:
              'calc(100vh - 64px - 48px - 70px - 24px - 41px - 41px)',
          }}
          onRowClick={(_evt, _rowData, togglePanel) => togglePanel()}
          detailPanel={[
            {
              tooltip: 'Show Compliance Details',
              render: carrierData => (
                <ComplianceDetails
                  carrierData={carrierData}
                  accessToken={accessToken}
                  removeCarrierById={removeCarrierById}
                />
              ),
            },
          ]}
        />
      </Container>
    </Page>
  );
};

export default CarrierCompliance;
