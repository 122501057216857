import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@forager/client-utils';
import { useScopes } from '../utils/ValidateScopes';

const PrivateComponent = ({
  path,
  reqScope,
  replacementComponent: ReplacementComponent = null,
  children,
}) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { hasScopes } = useScopes(reqScope);

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  if (reqScope) {
    if (hasScopes) {
      return children;
    }
    return ReplacementComponent;
  }
  return children;
};

PrivateComponent.propTypes = {
  path: PropTypes.string,
  reqScope: PropTypes.string,
  replacementComponent: PropTypes.element,
};

export default PrivateComponent;
