import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Checkbox,
  Box,
  Select,
  makeStyles,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import {
  Url,
  Phone,
  Email,
  FormTextField,
  DateTimePicker,
} from '@forager/bits';
import { carrierReps } from '@forager/constants';
import { useAuth0, useForagerNotification } from '@forager/client-utils';
import OverflowInput from '../../../components/OverflowInput';
import References from './References';
import RejectCarrier from './RejectCarrier';
import ApproveCarrier from './ApproveCarrier';
import ComplianceFileLink from './ComplianceFileLink';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const ComplianceDetails = ({
  values,
  setFieldValue,
  carrierData,
  removeCarrierById,
}) => {
  const { accessToken } = useAuth0();
  const { error } = useForagerNotification();
  const {
    legalName,
    dbaName,
    address1,
    address2,
    cityName,
    stateShortName,
    postalCode,
    website,
    contacts,
    files,
    references,
    applicationId,
  } = carrierData;
  const classes = useStyles();
  const hasFileById = id => files?.find(file => file?.carrierFileTypeId === id);

  const { firstName, lastName, phone, phoneExt, email } = contacts.find(
    // 3 types of contacts (1- Applicant, 2- Main, 3- Accounting)
    contact => contact.contactTypeId === 1 || contact.contactTypeId === 2
  );
  return (
    <Box padding="15px">
      <Grid
        container
        spacing={2}
        justify="space-between"
        data-testid="compliance-details"
      >
        <Grid lg={2} item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Company Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <OverflowInput title="Legal Name" value={legalName} />
          </Grid>
          <Grid item xs={12}>
            <OverflowInput title="DBA Name" value={dbaName || '-'} />
          </Grid>
          <Grid item xs={12} wrap="nowrap">
            <OverflowInput title="Address" value={address1} />
          </Grid>
          <Grid item xs={12}>
            <OverflowInput
              title="Suite/Ind. Park/Col."
              value={address2 || '-'}
            />
          </Grid>
          <Grid item xs={12} wrap="nowrap">
            <OverflowInput title="City" value={cityName} />
          </Grid>
          <Grid item xs={6} wrap="nowrap">
            <OverflowInput title="State/Province" value={stateShortName} />
          </Grid>
          <Grid item xs={6} wrap="nowrap">
            <OverflowInput title="Postal Code" value={postalCode} />
          </Grid>
          <Grid item xs={12} wrap="nowrap">
            <Url withCopy={!!website} label="Website" value={website || '-'} />
          </Grid>
        </Grid>
        <Grid lg={2} item container spacing={2} alignContent="flex-start">
          <Grid item xs={12}>
            <Typography variant="h5">Contact</Typography>
          </Grid>
          <Grid item xs={12}>
            <OverflowInput title="First Name" value={firstName} />
          </Grid>
          <Grid item xs={12}>
            <OverflowInput title="Last Name" value={lastName} />
          </Grid>
          <Grid item xs={12}>
            <Phone
              data-testid="compliance-office-phone"
              shrink
              extLabel="Ext."
              label="Office Phone"
              value={phone}
              extValue={phoneExt}
            />
          </Grid>
          <Grid item xs={12}>
            <Email
              data-testid="company-email"
              label="Email"
              value={email}
              className={classes.email}
            />
          </Grid>
        </Grid>
        <Grid lg={2} item container alignContent="flex-start">
          <Grid item xs={12}>
            <Typography variant="h5">Uploaded Documents</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="SCT*"
              className={classes.label}
              control={
                <Checkbox
                  checked={hasFileById(5)}
                  disabled
                  className={classes.check}
                />
              }
            />
            {hasFileById(5) && (
              <ComplianceFileLink
                {...hasFileById(5)}
                applicationId={applicationId}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="RFC*"
              className={classes.label}
              control={
                <Checkbox
                  checked={hasFileById(6)}
                  disabled
                  className={classes.check}
                />
              }
            />
            {hasFileById(6) && (
              <ComplianceFileLink
                {...hasFileById(6)}
                applicationId={applicationId}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Poliza*"
              className={classes.label}
              control={
                <Checkbox
                  checked={hasFileById(7)}
                  disabled
                  className={classes.check}
                />
              }
            />
            {hasFileById(7) && (
              <ComplianceFileLink
                {...hasFileById(7)}
                className={classes.label}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Proof Of Address*"
              className={classes.label}
              applicationId={applicationId}
              control={
                <Checkbox
                  checked={hasFileById(11)}
                  disabled
                  className={classes.check}
                />
              }
            />
            {hasFileById(11) && (
              <ComplianceFileLink
                {...hasFileById(11)}
                applicationId={applicationId}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="C-TPAT"
              className={classes.label}
              applicationId={applicationId}
              control={
                <Checkbox
                  checked={hasFileById(4)}
                  disabled
                  className={classes.check}
                />
              }
            />
            {hasFileById(4) && (
              <ComplianceFileLink
                {...hasFileById(4)}
                applicationId={applicationId}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="OEA"
              className={classes.label}
              applicationId={applicationId}
              control={
                <Checkbox
                  checked={hasFileById(8)}
                  disabled
                  className={classes.check}
                />
              }
            />
            {hasFileById(8) && (
              <ComplianceFileLink
                {...hasFileById(8)}
                applicationId={applicationId}
              />
            )}
          </Grid>
        </Grid>
        <Grid lg={2} item container alignContent="flex-start">
          <Grid item xs={12}>
            <Typography style={{ marginBottom: '10px' }} variant="h5">
              References
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <References references={references} />
          </Grid>
        </Grid>
        <Grid lg={2} item container alignContent="flex-start">
          <Grid
            item
            xs={12}
            container
            direction="column"
            justify="flex-start"
            spacing={2}
          >
            {/* <Grid item>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Forager Rep</InputLabel>
                <Select
                  value={values.repId}
                  onChange={e => setFieldValue('repId', e.target.value)}
                  label="Forager Rep"
                >
                  {carrierReps.map(({ USER_ID, NAME }) => (
                    <MenuItem key={USER_ID} value={USER_ID}>
                      {NAME}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item>
              <FormTextField fullWidth name="rfcNumber" label="RFC #" />
            </Grid>
            <Grid item>
              <FormTextField fullWidth name="sctNumber" label="SCT #" />
            </Grid>
            <Grid item>
              <DateTimePicker
                fullWidth
                required
                type="date"
                name="polizaExpireDate"
                data-testid="polizaExpireDate"
                label="Poliza Expire Date"
                disablePast
                isStaticTimestamp
                value={values.polizaExpireDate || null}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <RejectCarrier
          carrierData={carrierData}
          applicationId={applicationId}
          accessToken={accessToken}
          removeCarrierById={removeCarrierById}
          error={error}
        />
        <ApproveCarrier />
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  email: {
    justifyContent: 'flex-start',
  },
  check: {
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
  label: {
    '& span.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
}));

ComplianceDetails.propTypes = {
  values: PropTypes.shape({}),
  setFieldValue: PropTypes.func,
  applicationId: PropTypes.number,
  legalName: PropTypes.string,
  dbaName: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  cityName: PropTypes.string,
  stateShortName: PropTypes.string,
  postalCode: PropTypes.string,
  website: PropTypes.string,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string,
      phoneExt: PropTypes.string,
      email: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  files: PropTypes.arrayOf(
    PropTypes.shape({
      documentTypeId: PropTypes.number,
      url: PropTypes.string,
    })
  ),
  references: PropTypes.arrayOf(
    PropTypes.shape({
      companyName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      title: PropTypes.string,
      phone: PropTypes.string,
      phoneExt: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};

const schema = Yup.object().shape({
  rfcNumber: Yup.string()
    .min(12, 'Should be at least 12 characters long')
    .max(13, 'Should be no more than 13 characters long')
    .required('Carriers must have valid RFC Number')
    .trim(),
  sctNumber: Yup.string()
    .max(40, 'Should be no more than 80 characters')
    .required('Carriers must have valid SCT Number')
    .trim(),
  polizaExpireDate: Yup.string().required('Poliza exp. date is required'),
});

const handleSubmit = async (values, { props }) => {
  const { removeCarrierById, accessToken } = props;
  const {
    carrierData: { tableData, ...carrierData },
    ...restValues
  } = values;
  const { applicationId } = carrierData;
  await axios.put(
    `${process.env.REACT_APP_API_URL}/v1/carrierApplications/${applicationId}`,
    {
      ...carrierData,
      ...restValues,
      repId: values.repId || null,
      approved: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  removeCarrierById(applicationId);
};

export default withFormik({
  mapPropsToValues: ({ removeCarrierById, accessToken, tableData, ...rest }) =>
    rest,
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: schema,
  handleSubmit,
})(ComplianceDetails);
