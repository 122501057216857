import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const ContactCheckbox = ({ values, handleClick, label, name, testId }) => (
  <FormControlLabel
    control={
      <Checkbox
        data-testid={testId}
        checked={values[name]}
        onChange={e => handleClick(e, name)}
        color="primary"
        name={name}
      />
    }
    label={label}
  />
);

export default ContactCheckbox;
