import { createAction } from '@reduxjs/toolkit';

export const addRequirement = createAction('loadOverview/addRequirement');
export const editRequirement = createAction('loadOverview/editRequirement');
export const deleteRequirement = createAction('loadOverview/deleteRequirement');

export const addRequirementReducer = (state, action) => {
  state.requirements.data.push(action.payload);
};

export const deleteRequirementReducer = (state, action) => {
  state.requirements.data = state.requirements.data.filter(
    ({ loadRequirementId }) =>
      loadRequirementId !== action.payload.loadRequirementId
  );
};

export const editRequirementReducer = (state, action) => {
  const requirementIndex = state.requirements.data.findIndex(
    ({ loadRequirementId }) =>
      loadRequirementId === action.payload.loadRequirementId
  );

  state.requirements.data[requirementIndex] = action.payload;
};
