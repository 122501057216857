import React from 'react';
import { Grid } from '@material-ui/core';

import GeneralInfo from './GeneralInfo';
import InsuranceCoverage from './InsuranceCoverage';

const Compliance = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GeneralInfo />
      </Grid>
      <Grid item xs={12}>
        <InsuranceCoverage />
      </Grid>
    </Grid>
  );
};

export default Compliance;
