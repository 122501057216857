import React from 'react';
import PropTypes from 'prop-types';

const AddressOption = ({
  address1,
  address2,
  city,
  state,
  country,
  postalCode,
}) => {
  return (
    <div data-testid="address-option">
      <div>
        {address1} {address2 ? `, ${address2}` : ''}
      </div>
      <div>
        {city}, {state}
        {postalCode ? ` , ${postalCode}` : ''} | {country}
      </div>
    </div>
  );
};

AddressOption.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string,
};

AddressOption.defaultProps = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: '',
  postalCode: '',
};

export default AddressOption;
