import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetch = async ({ accessToken, loadId }, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_LOAD_API}/v1/loads/${loadId}/incidents`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
};

export const shouldFetch = (_args, { getState }) => {
  const { loadOverview } = getState();
  const { data: incidentData, isLoading } = loadOverview.incidents;
  return !isLoading && incidentData === null;
};

const fetchLoadIncidents = createAsyncThunk(
  'loadOverview/fetchLoadIncidents',
  fetch,
  {
    condition: shouldFetch,
  }
);

export const extraReducers = {
  [fetchLoadIncidents.fulfilled]: (state, action) => {
    state.incidents.data = action?.payload;
    state.incidents.isLoading = false;
  },
  [fetchLoadIncidents.pending]: state => {
    state.incidents.isLoading = true;
  },
  [fetchLoadIncidents.rejected]: state => {
    state.incidents.fetchError = true;
    state.incidents.isLoading = false;
  },
};

export default fetchLoadIncidents;
