import React, { useState, useEffect } from 'react';
import { AppBar, Box, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getSlugOrSlugId } from '@forager/client-utils';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import CarrierFiles from './CarrierFiles';
import CompanyInfo from './CompanyInfo';
import TabPanel from '../../../components/TabPanel';
import CarrierContacts from './Contacts';
// import CarrierOperations from './Operations';
import CarrierCompliance from './Compliance';
import CarrierBackOffice from './BackOffice';
// import CarrierLoads from './Loads';
import Page from '../../../components/Page';

const carrierSlugs = {
  0: 'company-info',
  1: 'contacts',
  // 2: 'loads',
  2: 'compliance',
  // 4: 'operations',
  3: 'back-office',
  4: 'files',
};

const CarrierProfile = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const history = useHistory();
  const { id: carrierId } = useParams();
  const { hash } = useLocation();

  const handleChange = (_event, newValue) => {
    setValue(newValue);
    history.push(
      `/carriers/${carrierId}#${getSlugOrSlugId(newValue, 0, carrierSlugs)}`
    );
  };

  useEffect(() => {
    const slug = hash.substr(1);
    const tabId = getSlugOrSlugId(slug, 0, carrierSlugs);
    setValue(tabId);
  }, [hash]);

  return (
    <Page className={classes.screen}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
          scrollButtons="on"
        >
          <Tab
            label="Company Info"
            data-testid="company-info-tab"
            className={classes.tab}
          />
          <Tab
            label="Contacts"
            data-testid="contacts-tab"
            className={classes.tab}
          />
          {/* <Tab label="Loads" data-testid="loads-tab" className={classes.tab} /> */}
          <Tab
            label="Compliance"
            data-testid="compliance-tab"
            className={classes.tab}
          />
          {/* <Tab
            label="Operations"
            data-testid="operations-tab"
            className={classes.tab}
          /> */}
          <Tab
            label="Back Office"
            data-testid="back-office-tab"
            className={classes.tab}
          />
          <Tab label="Files" data-testid="files-tab" className={classes.tab} />
        </Tabs>
      </AppBar>
      {/* tab content */}
      <Box className={classes.tabPanel}>
        <TabPanel value={value} index={0}>
          <CompanyInfo />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CarrierContacts />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <CarrierLoads />
        </TabPanel> */}
        <TabPanel value={value} index={2}>
          <CarrierCompliance />
        </TabPanel>
        {/* <TabPanel value={value} index={4}>
          <CarrierOperations />
        </TabPanel> */}
        <TabPanel value={value} index={3}>
          <CarrierBackOffice />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <CarrierFiles />
        </TabPanel>
      </Box>
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  tabPanel: {
    height: 'calc(100vh - 155px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  screen: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    textTransform: 'none',
    minWidth: 120,
    flex: 1,
  },
  tabs: {
    '& .MuiTabs-scrollButtons': {
      color: theme.palette.primary.main,
    },
  },
}));

export default CarrierProfile;
