import { createAction } from '@reduxjs/toolkit';

export const updateBorderCrossingDetails = createAction(
  'loadOverview/updateBorderCrossingDetails'
);

export const updateBorderCrossingDetailsReducer = (state, action) => {
  state.borderCrossings.data = {
    mex:
      state.borderCrossings.data.mex?.[0] || action.payload.mex?.[0]
        ? [
            {
              ...state.borderCrossings.data.mex?.[0],
              ...action.payload.mex?.[0],
              transloadFacility: (state.borderCrossings.data.mex[0]
                .transloadFacility ||
                action.payload.mex[0].transloadFacility) && {
                ...state.borderCrossings.data.mex?.[0]?.transloadFacility,
                ...action.payload.mex?.[0]?.transloadFacility,
              },
            },
          ]
        : [],
    can:
      state.borderCrossings.data.can?.[0] || action.payload.can?.[0]
        ? [
            {
              ...state.borderCrossings.data.can?.[0],
              ...action.payload.can?.[0],
            },
          ]
        : [],
  };
};
