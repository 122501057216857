import React from 'react';
import {
  CardContent,
  Divider,
  CardHeader,
  Card,
  Container,
} from '@material-ui/core';
import {
  history,
  useAuth0,
  useForagerNotification,
} from '@forager/client-utils';

import Page from '../../../components/Page';
import AddCarrierForm from './AddMexCarrierForm.js';

const AddMexCarrier = () => {
  const { accessToken } = useAuth0();
  const { error } = useForagerNotification();

  return (
    <Page title="Add Mexico Carrier">
      <Container maxWidth="md">
        <Card>
          <CardHeader title="Add Mexico Carrier" />
          <Divider />
          <CardContent>
            <AddCarrierForm
              accessToken={accessToken}
              error={error}
              history={history}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AddMexCarrier;
