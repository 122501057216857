import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { Box, CardContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { notifications } from '@forager/constants';
import { useForagerNotification } from '@forager/client-utils';
import CompanyInformationForm from './components/CompanyInformationForm';

const CompanyInformation = ({
  accessToken,
  companyId,
  editable,
  additionalFields,
  companyTypeId,
  additionalSchema,
}) => {
  const [companyMeta, setCompanyMeta] = useState({});
  const [fetchError, setFetchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const { error } = useForagerNotification();
  const history = useHistory();

  const fetchCompanyMeta = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/companies/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setCompanyMeta(data);
    } catch (err) {
      if (err?.response?.status === 404) {
        history.push('/errors/error-404');
      } else {
        setFetchError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, companyId]);

  useEffect(() => {
    fetchCompanyMeta();
  }, [fetchCompanyMeta]);
  return (
    <>
      {fetchError ? (
        <Box m={4} textAlign="center">
          {notifications.GENERIC_ERROR.EN}
        </Box>
      ) : (
        <CardContent style={{ width: '100%' }}>
          <CompanyInformationForm
            isEditingProfile={isEditingProfile}
            setIsEditingProfile={setIsEditingProfile}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            companyMeta={companyMeta}
            companyId={companyId}
            accessToken={accessToken}
            error={error}
            editable={editable}
            additionalFields={additionalFields}
            additionalSchema={additionalSchema}
            companyTypeId={companyTypeId}
          />
        </CardContent>
      )}
    </>
  );
};

CompanyInformation.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accessToken: PropTypes.string,
  editable: PropTypes.bool,
  children: PropTypes.node,
  additionalFields: PropTypes.func,
  additionalSchema: PropTypes.func,
  companyTypeId: PropTypes.number,
};

export default CompanyInformation;
