/* eslint-disable no-async-promise-executor */
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { makeStyles, Button, Checkbox, Grid } from '@material-ui/core';
import { Email, Phone, Table, OverflowTooltip } from '@forager/bits';
import PropTypes from 'prop-types';
import { notifications } from '@forager/constants';
import { useAuth0, useForagerNotification } from '@forager/client-utils';
import ContactModal from '../ContactModal';
import CreateAccountModal from './CreateAccountModal';

const ContactList = ({ companyTypeId, companyId, editable }) => {
  const { accessToken } = useAuth0();
  const { error } = useForagerNotification();
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateAccountModalLoading, setIsCreateAccountModalLoading] =
    useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isCreateAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [contact, setContact] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [newUserInfo, setNewUserInfo] = useState('');
  const [contactModalContent, setContactModalContent] =
    useState('addEditContent');

  const { ERROR, GENERIC_ERROR } = notifications;

  const handleCreateUserClick = email => {
    setSelectedEmail(email);
    setCreateAccountModalOpen(true);
  };

  const handleClose = () => {
    setCreateAccountModalOpen(false);
    setNewUserInfo('');
  };

  const getContacts = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setContacts(data);
    } catch (err) {
      setFetchError(true);
    } finally {
      setIsLoading(false);
    }
  }, [companyId, accessToken]);

  const createUserFromContact = async rowData => {
    const { isDeleted, userId, tableData, ...putValues } = rowData;
    try {
      setIsCreateAccountModalLoading(true);
      const payload = {
        // TODO: make sure companyId actually is a number everywhere... it should never be a string
        // this is a bandaid to get us launched
        companyId:
          typeof companyId === 'string' ? parseInt(companyId, 10) : companyId,
        ...putValues,
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/users/${userId}/account`,
        payload,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      getContacts();
      setNewUserInfo(data);
    } catch (err) {
      error(ERROR.EN);
    } finally {
      setIsCreateAccountModalLoading(false);
    }
  };

  const handleAddContactClick = () => {
    setContactModalContent('addEditContent');
    setContact(null);
    setIsContactModalOpen(!isContactModalOpen);
  };

  const handleEditContactClick = contactToBeEdited => {
    setContactModalContent('addEditContent');
    setContact(contactToBeEdited);
    setIsContactModalOpen(!isContactModalOpen);
  };

  const handleDeleteContactClick = contactToBeDeleted => {
    setContactModalContent('deleteContent');
    setContact(contactToBeDeleted);
    setIsContactModalOpen(!isContactModalOpen);
  };

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const columns = [
    {
      hidden: !editable,
      title: '',
      filtering: false,
      width: 160,
      render: rowData => (
        <Grid container spacing={1} data-testid="action-buttons">
          <Grid item>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => handleEditContactClick(rowData)}
            >
              EDIT
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={contacts.length === 1}
              onClick={() => handleDeleteContactClick(rowData)}
            >
              DELETE
            </Button>
          </Grid>
        </Grid>
      ),
    },
    {
      title: 'First Name',
      field: 'firstName',
      // Don't prop check inline table components, applies to all columns here -ET
      // eslint-disable-next-line react/prop-types
      render: ({ firstName }) => (
        <OverflowTooltip
          data-testid="first-name-tooltip"
          tooltipValue={firstName}
          displayValue={firstName}
        />
      ),
    },
    {
      title: 'Last Name',
      field: 'lastName',
      // eslint-disable-next-line react/prop-types
      render: ({ lastName }) => (
        <OverflowTooltip
          data-testid="last-name-tooltip"
          tooltipValue={lastName}
          displayValue={lastName}
        />
      ),
      emptyValue: '-',
    },
    {
      title: 'Title',
      field: 'title',
      // eslint-disable-next-line react/prop-types
      render: ({ title }) => (
        <OverflowTooltip
          data-testid="title-tooltip"
          tooltipValue={title}
          displayValue={title}
        />
      ),
      emptyValue: '-',
    },
    {
      title: 'Office Phone',
      field: 'phone',
      // eslint-disable-next-line react/prop-types
      render: ({ phone }) => (
        <Phone
          data-testid="office-phone"
          className={classes.center}
          name="phone"
          value={phone}
        />
      ),
      emptyValue: '-',
    },
    {
      title: 'Ext.',
      field: 'phoneExt',
      emptyValue: '-',
    },
    {
      title: 'Cell Phone',
      field: 'mobile',
      // eslint-disable-next-line react/prop-types
      render: ({ mobile }) => (
        <Phone
          data-testid="mobile-phone"
          className={classes.center}
          name="mobile"
          value={mobile}
        />
      ),
      emptyValue: '-',
    },
    {
      title: 'Email',
      field: 'email',
      // eslint-disable-next-line react/prop-types
      render: ({ email }) =>
        // eslint-disable-next-line react/prop-types
        email?.toUpperCase() === 'N/A' ? (
          '-'
        ) : (
          <Email
            data-testid="contact-email"
            withCopy
            name="email"
            value={email}
          />
        ),
    },
    {
      // Hide if not a carrier or a customer
      hidden: companyTypeId !== 2 && companyTypeId !== 1,
      title: 'Main',
      field: 'isMain',
      filtering: false,
      defaultSort: 'desc',
      // eslint-disable-next-line react/prop-types
      render: ({ isMain }) => <Checkbox disabled checked={isMain} />,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      // Hide if not a customer
      hidden: companyTypeId !== 2,
      title: 'Billing',
      field: 'isBilling',
      defaultSort: 'desc',
      // eslint-disable-next-line react/prop-types
      render: ({ isBilling }) => <Checkbox disabled checked={isBilling} />,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      // Hide if not on customer or carrier profile or user does not have scope - RDP
      hidden: !(companyTypeId === 2 || companyTypeId === 1) || !editable,
      title: 'User Account',
      field: 'auth0Id',
      filtering: false,
      render: rowData =>
        !rowData.auth0Id ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleCreateUserClick(rowData)}
            data-testid="create-account-button"
          >
            Create Account
          </Button>
        ) : (
          'Account Exists'
        ),
    },
  ];

  return (
    <>
      <Table
        data-testid="contacts-table"
        data={contacts}
        title="Contacts"
        titleVariant="h5"
        columns={columns}
        isLoading={isLoading}
        errorText={fetchError ? GENERIC_ERROR.EN : ''}
        components={
          editable && {
            Actions: () => (
              <Button
                color="primary"
                size="small"
                style={{ marginRight: 5 }}
                variant="contained"
                onClick={handleAddContactClick}
                data-testid="create-facility-contact-button"
              >
                ADD CONTACT
              </Button>
            ),
          }
        }
        options={{
          pageSize: 5,
          filtering: false,
          search: false,
          draggable: false,
          // Max height - top bar, page padding, top bars on table divided by 2 to share screen
          maxBodyHeight:
            'calc((100vh - 64px - 48px - 70px - 24px - 41px - 41px) / 2)',
          actionsCellStyle: {
            width: '70px',
          },
          rowStyle: {
            height: '48px',
          },
        }}
      />

      <CreateAccountModal
        {...{
          isCreateAccountModalLoading,
          isCreateAccountModalOpen,
          createUserFromContact,
          newUserInfo,
          handleClose,
          selectedEmail,
        }}
      />

      <ContactModal
        companyTypeId={companyTypeId}
        hasMain={companyTypeId === 2 || companyTypeId === 1}
        isBillingShown={companyTypeId === 2}
        companyId={companyId}
        contact={contact}
        contacts={contacts}
        setContacts={setContacts}
        getContacts={getContacts}
        isContactModalOpen={isContactModalOpen}
        setIsContactModalOpen={setIsContactModalOpen}
        contactModalContent={contactModalContent}
        setContactTableIsLoading={setIsLoading}
      />
    </>
  );
};

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  customActionButton: {
    minWidth: '10%',
    fontSize: '12px',
  },
  button: {
    position: 'absolute',
    top: 15,
    right: 24,
  },
  editable: {
    width: '100%',
  },
  center: {
    justifyContent: 'center',
  },
});

ContactList.propTypes = {
  companyTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  editable: PropTypes.bool,
};

ContactList.defaultProps = { editable: true };

export default ContactList;
