import React, { useState } from 'react';
import {
  CardHeader,
  Divider,
  CardContent,
  Card,
  Container,
} from '@material-ui/core';
import {
  history,
  useAuth0,
  useForagerNotification,
} from '@forager/client-utils';
import Page from '../../../components/Page';
import AddNewCustomsBrokerForm from './AddNewCustomsBrokerForm'; // eslint-disable-line import/no-named-as-default

const AddNewCustomsBroker = () => {
  const { accessToken } = useAuth0();
  const { error, success } = useForagerNotification();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Page title="New Customs Broker" data-testid="add-new-cb-screen">
      <Container maxWidth="md">
        <Card>
          <CardHeader title="New Customs Broker" />
          <Divider />
          <CardContent>
            <AddNewCustomsBrokerForm
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              accessToken={accessToken}
              error={error}
              success={success}
              history={history}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AddNewCustomsBroker;
