import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { states, mexicoCrossingCities } from '@forager/constants';

const LocationFilters = ({ applyFilter, isLoading }) => {
  return (
    <Grid container spacing={1}>
      <Grid item md={2} xs={12} data-testid="state-filter">
        <Autocomplete
          onChange={(_event, values) => {
            const stateId = values.map(({ ID }) => ID);
            applyFilter({ stateId });
          }}
          disabled={isLoading}
          multiple
          id="tags-standard"
          options={states}
          size="small"
          getOptionLabel={state => state.LONG_NAME}
          data-testid="state-filter-autocomplete"
          renderInput={params => (
            <TextField
              {...params}
              data-testid="state-filter-input"
              variant="outlined"
              label="Filter by State"
              placeholder=""
            />
          )}
        />
      </Grid>

      <Grid item md={2} xs={12} data-testid="border-crossing-filter">
        <Autocomplete
          onChange={(_event, values) => {
            const mexicoBorderCrossingId = values.map(
              ({ CROSSING_ID }) => CROSSING_ID
            );
            applyFilter({ mexicoBorderCrossingId });
          }}
          disabled={isLoading}
          multiple
          id="tags-standard"
          options={mexicoCrossingCities}
          size="small"
          getOptionLabel={mexicoCrossingCity =>
            mexicoCrossingCity.DISPLAY_LABEL
          }
          data-testid="crossing-filter-autocomplete"
          renderInput={params => (
            <TextField
              {...params}
              data-testid="crossing-filter-input"
              variant="outlined"
              label="Filter by Crossing"
              placeholder=""
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default LocationFilters;
