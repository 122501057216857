import { createAction } from '@reduxjs/toolkit';
import { dayjs } from '@forager/date-utils';
import { incidentTypes } from '@forager/constants';

export const createIncident = createAction('loadOverview/createIncident');

export const createIncidentReducer = (state, action) => {
  state.incidents.data.push({
    ...action.payload,
    type: incidentTypes.find(
      ({ LOAD_INCIDENT_TYPE_ID }) =>
        LOAD_INCIDENT_TYPE_ID === action.payload.loadIncidentTypeId
    ).LOAD_INCIDENT_TYPE_NAME,
    timeIncidentLogged: dayjs().format(),
  });
};
