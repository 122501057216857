import { createAction } from '@reduxjs/toolkit';

export const addVendor = createAction('loadOverview/addVendor');
export const editVendor = createAction('loadOverview/editVendor');
export const deleteVendor = createAction('loadOverview/deleteVendor');

export const addVendorReducer = (state, action) => {
  state.vendors.data.push(action.payload);
};

export const deleteVendorReducer = (state, action) => {
  state.vendors.data = state.vendors.data.filter(
    ({ loadVendorId }) => loadVendorId !== action.payload.loadVendorId
  );
};

export const editVendorReducer = (state, action) => {
  const vendorIndex = state.vendors.data.findIndex(
    ({ loadVendorId }) => loadVendorId === action.payload.loadVendorId
  );

  state.vendors.data[vendorIndex] = action.payload;
};
