import { extraReducers as fetchLoadInfoReducers } from '../reducers/fetchLoadInfo';
import { extraReducers as fetchLoadStopsReducers } from '../reducers/fetchLoadStops';
import { extraReducers as fetchProducts } from '../reducers/fetchProducts';
import { extraReducers as fetchLoadLocationUpdatesReducers } from '../reducers/fetchLoadLocationUpdates';
import { extraReducers as fetchLoadBorderCrossingsReducers } from '../reducers/fetchLoadBorderCrossings';
import { extraReducers as fetchRequirements } from '../reducers/fetchRequirements';
import { extraReducers as fetchIncidentsReducers } from './fetchLoadIncidents';
import { extraReducers as fetchVendors } from '../reducers/fetchVendors';
import { extraReducers as fetchFilesReducers } from '../reducers/fetchFiles';
import { extraReducers as fetchLoadCharges } from '../reducers/fetchLoadCharges';
import { extraReducers as fetchLoadCarriers } from '../reducers/fetchLoadCarriers';

export const extraReducers = {
  ...fetchLoadInfoReducers,
  ...fetchLoadStopsReducers,
  ...fetchProducts,
  ...fetchLoadLocationUpdatesReducers,
  ...fetchLoadBorderCrossingsReducers,
  ...fetchRequirements,
  ...fetchIncidentsReducers,
  ...fetchVendors,
  ...fetchFilesReducers,
  ...fetchLoadCharges,
  ...fetchLoadCarriers,
};

export default {};
