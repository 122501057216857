import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// import { MultiLoadsSuccessPageRoute } from '@forager/loads-components';
import PrivateComponent from '../components/PrivateComponent';

import DashboardLayout from '../layouts/Dashboard';
import ErrorLayout from '../layouts/Error';

// import Customers from '../views/Customers';
// import CustomerProfile from '../views/Customers/Profile';
// import AddNewCustomer from '../views/Customers/AddNewCustomer';

import CustomsBrokers from '../views/CustomsBrokers';
import CustomsBrokerProfile from '../views/CustomsBrokers/Profile';
import AddNewCustomsBrokers from '../views/CustomsBrokers/AddNewCustomsBroker';

// import Facilities from '../views/Facilities';
// import FacilitiesProfile from '../views/Facilities/Profile';
// import AddNewFacility from '../views/Facilities/AddNewFacility';

// import LoadOverview from '../views/LoadOverview';
// import BulkRates from '../views/BulkRates';
// import ActiveLoads from '../views/ActiveLoads';
// import PastLoads from '../views/PastLoads';

import Carriers from '../views/Carriers';
import CarrierProfile from '../views/Carriers/Profile';
// import AddUsaCanCarrier from '../views/Carriers/AddUsaCanCarrier';
import AddMexCarrier from '../views/Carriers/AddMexCarrier';

// import FactoringCompanies from '../views/FactoringCompanies';
// import FactoringCompanyProfile from '../views/FactoringCompanies/Profile';
// import AddNewFactoringCompany from '../views/FactoringCompanies/AddNewFactoringCompany';

// import Vendors from '../views/Vendors';
// import { VendorProfile, AddNewVendor } from '../views/Vendors/components';

// import ReportForm from '../views/ReportForm';

import CarrierCompliance from '../views/Carriers/CarrierCompliance';
// import ContractedLaneForm from '../views/Customers/Profile/ContractedLanes/ContractedLaneForm';

// import ContractedLanes from '../views/ContractedLanes';
// import MultiLoads from '../views/ContractedLanes/MultiLoads';
// import LoadRedirect from './LoadRedirect';

// import MarketplaceLoads from '../views/MarketplaceLoads';
// import LaneBids from '../views/LaneBids';
// import Dashboard from '../views/Dashboard';
// import ExportRates from '../views/ExportRates';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/carriers" />,
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('../views/Error401')),
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('../views/Error404')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/',
        exact: true,
        component: () => <Redirect to="/carriers" />,
      },
      // {
      //   path: '/dashboard',
      //   exact: true,
      //   component: () => <Dashboard />,
      // },
      // {
      //   path: '/active-loads',
      //   component: () => <ActiveLoads />,
      // },
      // {
      //   path: '/past-loads',
      //   component: () => <PastLoads />,
      // },
      // {
      //   path: '/marketplace-loads',
      //   component: () => <MarketplaceLoads />,
      // },
      // {
      //   path: '/lane-bids',
      //   component: LaneBids,
      // },
      // {
      //   path: '/loads/:id',
      //   exact: true,
      //   component: props => <LoadRedirect {...props} />,
      // },
      // {
      //   path: '/loads/:id/:slug',
      //   exact: true,
      //   component: props => <LoadOverview {...props} />,
      // },
      // {
      //   path: '/customers',
      //   exact: true,
      //   component: () => <Customers />,
      // },
      // {
      //   path: '/customers/create',
      //   exact: true,
      //   component: () => (
      //     <PrivateComponent
      //       reqScope="customer:create"
      //       replacementComponent={<Redirect to="/" />}
      //     >
      //       <AddNewCustomer />
      //     </PrivateComponent>
      //   ),
      // },
      // {
      //   path: '/customers/:customerCompanyId',
      //   exact: true,
      //   component: props => <CustomerProfile {...props} />,
      // },
      // {
      //   path: '/customers/:customerCompanyId/create-contracted-lane',
      //   exact: true,
      //   component: () => <ContractedLaneForm />,
      // },
      // {
      //   path: '/facilities',
      //   exact: true,
      //   component: () => <Facilities />,
      // },
      // {
      //   path: '/facilities/create',
      //   exact: true,
      //   component: () => (
      //     <PrivateComponent
      //       reqScope="facility:create"
      //       replacementComponent={<Redirect to="/" />}
      //     >
      //       <AddNewFacility />
      //     </PrivateComponent>
      //   ),
      // },
      // {
      //   path: '/facilities/:id',
      //   exact: true,
      //   component: props => <FacilitiesProfile {...props} />,
      // },
      {
        path: '/customs-brokers',
        exact: true,
        component: () => <CustomsBrokers />,
      },
      {
        path: '/customs-brokers/create',
        exact: true,
        component: () => (
          <PrivateComponent
            reqScope="chb:create"
            replacementComponent={<Redirect to="/" />}
          >
            <AddNewCustomsBrokers />
          </PrivateComponent>
        ),
      },
      {
        path: '/customs-brokers/:id',
        exact: true,
        component: props => <CustomsBrokerProfile {...props} />,
      },
      // {
      //   path: '/bulk-uploader',
      //   component: () => (
      //     <PrivateComponent
      //       reqScope="bulk-rates:read"
      //       replacementComponent={<Redirect to="/" />}
      //     >
      //       <BulkRates />
      //     </PrivateComponent>
      //   ),
      //   exact: true,
      // },
      // {
      //   path: '/export-rates',
      //   exact: true,
      //   component: () => <ExportRates />,
      // },
      {
        path: '/carriers',
        exact: true,
        component: () => <Carriers />,
      },
      // {
      //   path: '/carriers/create/usa-can',
      //   exact: true,
      //   component: () => <AddUsaCanCarrier />,
      // },
      {
        path: '/carriers/compliance',
        exact: true,
        component: () => (
          <PrivateComponent
            reqScope="carrier-compliance:update"
            replacementComponent={<Redirect to="/" />}
          >
            <CarrierCompliance />
          </PrivateComponent>
        ),
      },
      {
        path: '/carriers/create/mex',
        exact: true,
        component: () => <AddMexCarrier />,
      },
      {
        path: '/carriers/:id',
        component: props => <CarrierProfile {...props} />,
      },
      // {
      //   path: '/factoring-companies',
      //   exact: true,
      //   component: () => <FactoringCompanies />,
      // },
      // {
      //   path: '/factoring-companies/create',
      //   exact: true,
      //   component: () => <AddNewFactoringCompany />,
      // },
      // {
      //   path: '/factoring-companies/:factoringCompanyId',
      //   exact: true,
      //   component: props => <FactoringCompanyProfile {...props} />,
      // },
      // {
      //   path: '/vendors',
      //   exact: true,
      //   component: () => <Vendors />,
      // },
      // {
      //   path: '/vendors/create',
      //   exact: true,
      //   component: () => <AddNewVendor />,
      // },
      // {
      //   path: '/vendors/:companyId',
      //   exact: true,
      //   component: props => <VendorProfile {...props} />,
      // },
      // {
      //   path: '/contracted-lanes',
      //   exact: true,
      //   component: () => <ContractedLanes />,
      // },
      // {
      //   path: '/contracted-lanes/:laneId/build/success',
      //   exact: true,
      //   component: () => <MultiLoadsSuccessPageRoute />,
      // },
      // {
      //   path: '/report/:type',
      //   exact: true,
      //   component: ReportForm,
      // },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];
