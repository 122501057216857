import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import ErrorPage from '../components/ErrorPage';

class GlobalErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
    this.handleSetErrorState = this.handleSetErrorState.bind(this);
  }

  componentDidCatch(error, info) {
    if (
      process.env.REACT_APP_ENVIRONMENT === 'development' ||
      process.env.REACT_APP_ENVIRONMENT === 'production'
    ) {
      const { user } = this.props;

      Sentry.withScope(scope => {
        scope.setExtras(info);
        scope.setUser({ email: user.email });
        Sentry.captureException(error);
      });
    }

    return this.setState({ hasError: true });
  }

  handleSetErrorState() {
    this.setState({ hasError: false });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError)
      return <ErrorPage handleSetErrorState={this.handleSetErrorState} />;
    return children;
  }
}

GlobalErrorCatcher.propTypes = {
  children: PropTypes.node,
  user: PropTypes.shape({
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    nickname: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    updated_at: PropTypes.string,
    email: PropTypes.string,
    email_verified: PropTypes.bool,
    sub: PropTypes.string,
    companyId: PropTypes.number,
    companyType: PropTypes.number,
  }),
};

export default GlobalErrorCatcher;
